import React, {Component} from 'react';
import {Button, Snackbar, Slide} from '@material-ui/core';
import {Alert} from '@material-ui/lab'
import {
    SimpleForm,
    Toolbar,
    TextInput
} from 'react-admin';
import dataProvider from '../../app/dataProvider';
import { RESOURCE_NAME as USERS_RESOURCE } from '../../users/user.dataProvider';

export default class UserNotify extends Component {

    POST_NOTIF = "POST_NOTIF";

    constructor(props) {
        super(props);
        this.state = {
            notificationMessage: "",
            snackbar: {
                open: false,
                severity: "error",
                message: ""
            }
        }
    }

    render() {
        return (
            <SimpleForm submitOnEnter={false} toolbar={
                <Toolbar {...this.props} >
                    <Button variant="contained" color="primary" onClick={this.sendManualPushNotification} disabled={this.state.notificationMessage === ""}>
                        Send Push Notification
                    </Button>
                </Toolbar>
            }>
                <Snackbar open={this.state.snackbar.open} autoHideDuration={3000} onClose={this.handleClose} TransitionComponent={Slide}>
                    <Alert onClose={this.handleClose} severity={this.state.snackbar.severity}>
                        {this.state.snackbar.message}
                    </Alert>
                </Snackbar>
                <TextInput multiline fullWidth source="messageInput" label='Push Notification Content' onChange={this.handleMessageChange} />
            </SimpleForm>
        );
    }

    sendManualPushNotification = () => {
        const requestParams = {
            data: {
                userId: this.props.match.params.id, //maps to the id defined in the url
                message: this.state.notificationMessage
            }
        }
        dataProvider(this.POST_NOTIF, USERS_RESOURCE, requestParams)
        .then( response => {
            if(response.data.message === "Success") {
                this.setState({
                    ...this.state,
                    snackbar: {
                        open: true,
                        severity: "success",
                        message: "Push notification sent!"
                    }
                });
            } else {
                this.setState({
                    ...this.state,
                    snackbar: {
                        open: true,
                        severity: "error",
                        message: "Push notification failed."
                    }
                });
            }
        })
        .catch( error => {
            this.setState({
                ...this.state,
                snackbar: {
                    open: true,
                    severity: "error",
                    message: "Push notification failed."
                }
            });
        });
    };
    
    handleMessageChange = (data) => {
        this.setState({
            ...this.state,
            notificationMessage: data.target.value
        });
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            ...this.state,
            snackbar: {
                ...this.state.snackbar,
                open: false,
            }
        });
    };
}