import React from 'react';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../../roles/provider.roles.dataProvider';
import {
    ReferenceArrayField,
    SingleFieldList,
    ChipField
} from 'react-admin';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';
import { rolesFields } from '../../../roles/roles.model';
import { codesFields } from '../../codes.model';

export default (props) => {
  if (props.record[codesFields.roleIds] != null && props.record[codesFields.roleIds].length > 0) {
    return(
      <ReferenceArrayField label="Supported Roles" reference={PROVIDER_ROLES_RESOURCE} source={codesFields.roleIds} {...props}>
      <SingleFieldList linkType={false}>
          <ChipField source={rolesFields.roleName} />
      </SingleFieldList>
    </ReferenceArrayField>
    )
  }
  else {
    return (
      <DefaultPlaceholderField />
    )
  }
}