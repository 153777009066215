export let discountCodesFields = {
	id: "id",
	uniqueCouponCode: "uniqueCouponCode",
	bulkCouponPrefix: "bulkCouponPrefix",
	discount: "discount",
	discountPercent: "discountPercent",
//	timeframe: "timeframe",
	redeemByDate: "redeemByDate",
	createdAt: "createdAt",
	deactivatedAt: "deactivatedAt",
	deactivated: "deactivated",
	appliedAt: "appliedAt",
	redeemed: "redeemed",
	expiresAt: "expiresAt",
//	accountCode: "accountCode",
//	subscriptionId: "subscriptionId",
//	totalDiscount: "totalDiscount",
	currency: "currency",
	discountType: "discountType",
//	freeTialCouponAmount: "freeTialCouponAmount",
//	freeTrialCouponUnit: "freeTrialCouponUnit",
	plans: "plans",
	userId: "userId",
	userFirstName: "userFirstName",
	userLastName: "userLastName"
};
