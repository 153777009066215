import React from 'react';
import { Link, TextField } from 'react-admin';
import { RESOURCE_NAME as VERSIONS_RESOURCE } from '../../versions.dataProvider';
import { versionsFields } from '../../versions.model';


const VersionLinkField = props => {
    if (!props.record[versionsFields.id]) {return 'n/a'}
    return (
    <Link to={`${VERSIONS_RESOURCE}/${props.record.id}/show`}>
        <TextField source={versionsFields.id} style={{ color: 'blue' }} {...props}/>
    </Link>)
};

VersionLinkField.defaultProps = {
    addLabel: true
};

export default VersionLinkField;
