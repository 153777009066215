import React from 'react';
import {
    Labeled,
} from 'react-admin';
import CancelSubscriptionButton from './userActionsCancelSubscription.component';
import InvalidateCodeButton from './userActionsInvalidate.component';


const UserActionsField = (props) => {
    let { record } = props;
    console.log("### record ", record);
    if (record.is_active_sdk_user != 1) {
        return null;
    }
    let labeledComponent = (record.recurly_uuid) ?
                            (<CancelSubscriptionButton {...props}/>)  :
                            (<InvalidateCodeButton {...props}/>);

    return (<Labeled label="Actions">
                { labeledComponent }
            </Labeled>)
};

export default UserActionsField;
