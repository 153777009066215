import React from 'react';
import { Link } from 'react-admin';
import { RESOURCE_NAME as PATIENTS_RESOURCE } from '../../../users/patient.dataProvider';
import PatientFullName from './patientFullName.component';
import { callsFields } from '../../calls.model';

const PatientLinkField = props => {
    if (!props.record[callsFields.patientId]) {return 'n/a'}
    return (
    <Link to={`/${PATIENTS_RESOURCE}/${props.record[callsFields.patientId]}/show`}>
        <PatientFullName {...props} />
    </Link>)
};

PatientLinkField.defaultProps = {
    addLabel: true
};

export default PatientLinkField;
