import {
    GET_LIST,
    GET_MANY
} from 'react-admin';
import { providerRoleRequestToHttpRequest } from './roles.dataProvider';

export const RESOURCE_NAME = "provider.roles";

export const providerRequestToHttpRequest = (requestType, requestParams) => {
    return providerRoleRequestToHttpRequest(requestType, requestParams, true);
};


const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {  

    let { headers, json } = httpResponse;
    switch (requestType) {
        case GET_LIST:
            console.log(RESOURCE_NAME, "response: ", json.map(roleItem => roleItem))
            return {
                data: json.map(roleItem => roleItem).filter(roleItem => roleItem.is_provider_subtype === true),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        case GET_MANY:
            let roleIdsToGet = requestParams.ids;
            console.log("roles_provider handling ids => ", roleIdsToGet);
            console.log(RESOURCE_NAME, "response: ", json.filter(roleItem => roleIdsToGet.includes(roleItem.id)))
            return {
                data: json.filter(roleItem => roleIdsToGet.includes(roleItem.id)).filter(roleItem => roleItem.is_provider_subtype === true)
            };
        default: 
            return { data: json };
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};
