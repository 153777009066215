import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput,
    DateField,
    BooleanInput,
    ShowButton
} from 'react-admin';
import { callsFields, callStatus, CallStatusNames, callProviderChartType, CallProviderChartTypeNames, callService, CallServiceNames } from '../calls.model';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import PatientLinkField from './fields/patientLink.component';
import CallStatus from './fields/callStatus.component';
import CallProvChartList from './fields/callProvChartList.component';
import ProviderLinkField from './fields/providerLink.component';
import CallTimeLabel from './fields/callTimeLabel.component';
import ProviderEndReason from './fields/providerEndReason.component';
import PatientEndReason from './fields/patientEndReason.component';
import CallLinkField from './fields/callLink.component';
import CallsListActions from './callsListActions'
import callsExporter from './callsExporter'
import YesNoField from '../../material/yesNoField.component';

import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../roles/provider.roles.dataProvider';
import { DATE_TIME_OPTIONS } from '../../app/app.component';
import { makeStyles } from '@material-ui/core/styles';
import PeriodInput from "../../app/periodInput.component";

const useStyles = makeStyles({
    headers: {
        paddingTop: "47px",
        position: "sticky",
    }
});

const choiceForCallStatus = (status) => {
    return { id: status, name: CallStatusNames[status] }
}

const choiceForProviderChartType = (status) => {
  return { id: status, name: CallProviderChartTypeNames[status] }
}

const choiceForService = (service) => {
    return { id: service, name: CallServiceNames[service]}
}

const CallsFilter = props => (
    <Filter {...props}>
        <TextInput label="Call Id" source={callsFields.id} allowEmpty />
        <TextInput label="Patient Id" source={callsFields.patientId} allowEmpty />
        <TextInput label="Patient First Name Contains" source={callsFields.patientFirstName} allowEmpty />
        <TextInput label="Patient Last Name Contains" source={callsFields.patientLastName} allowEmpty />
        <TextInput label="Provider First Name Contains" source={callsFields.providerFirstName} allowEmpty />
        <TextInput label="Provider Last Name Contains" source={callsFields.providerLastName} allowEmpty />
        <ReferenceInput label="Patient Organization" perPage={10000}
            sort={{ field: "name", order: 'ASC' }} source={callsFields.patientOrgId} reference={ORGANIZATIONS_RESOURCE}>
            <SelectInput optionText= "name" />
        </ReferenceInput>
        <SelectInput
            label="Call Status"
            source={callsFields.status}
            choices={[
                choiceForCallStatus(callStatus.REQUESTED),
                choiceForCallStatus(callStatus.ACCEPTED),
                choiceForCallStatus(callStatus.CANCELED),
                choiceForCallStatus(callStatus.COMPLETED),
                choiceForCallStatus(callStatus.MISSED),
                choiceForCallStatus(callStatus.ERROR)
            ]}
        />
        <SelectInput
            label="Provider Chart"
            source={callsFields.providerChartType}
            choices={[
              choiceForProviderChartType(callProviderChartType.NO_CHART),
              choiceForProviderChartType(callProviderChartType.DEFAULT_CHART),
              choiceForProviderChartType(callProviderChartType.BIRTH_DOULA_CHART),
              choiceForProviderChartType(callProviderChartType.POST_PARTUM_DOULA_CHART)
            ]}
        />
        <PeriodInput
          source="startedBetween"
          fromLabel="Call Started At From"
          toLabel="Call Started At To"
          label="Call Started At"
        />
        <PeriodInput
          source="endedBetween"
          fromLabel="Call Ended At From"
          toLabel="Call Ended At To"
          label="Call Ended At"
        />
        <ReferenceInput label="Call Type" source={callsFields.groupId} perPage={10000} sort={{ field: "name", order: 'ASC' }} reference={PROVIDER_ROLES_RESOURCE}>
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <SelectInput
            label="Service"
            source={callsFields.service}
            choices={[
                choiceForService(callService.TWILIO),
                choiceForService(callService.TWILIO_ROOM),
                choiceForService(callService.TWILIO_PHONE),
                choiceForService(callService.TWILIO_CALLBACK),
                choiceForService(callService.OOVOO),
            ]}
        />
        <BooleanInput label="Exclude Twilio Callbacks" source={`${callsFields.service}_neq.${callService.TWILIO_CALLBACK}`} alwaysOn />
    </Filter>
);

const CallsList = props => {
    const classes = useStyles();

    return (
        <List {...props} title="Call Queues" sort={{ field: 'id', order: 'DESC' }} filters={<CallsFilter />} exporter={callsExporter} bulkActionButtons={false}	actions={<CallsListActions />}>
            <Datagrid>
                <CallLinkField label="Id" source={callsFields.id} sortable={true} headerClassName={classes.headers} />
                <DefaultPlaceholderField source={callsFields.groupName} sortBy={callsFields.groupId} sortable={true} wrapField={<TextField/>}  label="Type" headerClassName={classes.headers} />
                <DefaultPlaceholderField source={callsFields.lang} wrapField={<TextField/>}  label="Lang" headerClassName={classes.headers} />
                <CallStatus source={callsFields.status} wrapField={<TextField/>} label="Status" sortable={false} headerClassName={classes.headers} />
                <CallProvChartList label="Provider Chart" headerClassName={classes.headers} />
                <PatientLinkField label="Patient" addLabel headerClassName={classes.headers} />
                <DefaultPlaceholderField source={callsFields.patientRating} wrapField={<TextField/>}  label="Patient Rating" headerClassName={classes.headers}  />
                <DefaultPlaceholderField source={callsFields.patientFeedback} wrapField={<TextField/>}  label="Patient Feedback" headerClassName={classes.headers}  />
                <ProviderLinkField label="Provider" headerClassName={classes.headers}  addLabel />
                <DefaultPlaceholderField source={callsFields.providerRating} wrapField={<TextField/>}  label="Provider Rating" headerClassName={classes.headers} />
                <DefaultPlaceholderField source={callsFields.providerFeedback} wrapField={<TextField/>}  label="Provider Feedback" headerClassName={classes.headers}  />
                <DefaultPlaceholderField source={callsFields.callStartedAt} sortable={true} options={DATE_TIME_OPTIONS} wrapField={<DateField/>}   label="Started At" headerClassName={classes.headers}  />
                <DefaultPlaceholderField source={callsFields.acceptedAt} sortable={true} wrapField={<DateField/>} options={DATE_TIME_OPTIONS}  label="Accepted At" headerClassName={classes.headers} />
                <DefaultPlaceholderField source={callsFields.endedAt} sortable={true} wrapField={<DateField/>} options={DATE_TIME_OPTIONS}  label="Ended At" headerClassName={classes.headers} />
                <CallTimeLabel source={callsFields.timeToAnswer} sortable={false} label="Time to Answer" headerClassName={classes.headers} />
                <CallTimeLabel source={callsFields.timeToCancel} sortable={false} label="Time to Cancel" headerClassName={classes.headers} />
                <DefaultPlaceholderField source={callsFields.service} sortable={true} wrapField={<TextField/>} label="Service" headerClassName={classes.headers} />
                <ProviderEndReason label="Provider End Reason" headerClassName={classes.headers}  />
                <PatientEndReason label="Patient End Reason" headerClassName={classes.headers}  />
                <YesNoField source={callsFields.introCall } wrapField={<TextField/>}  label="Intro Call" />
                <ShowButton />
            </Datagrid>
        </List>
    )
};

export default CallsList;