import {
    GET_LIST,
    GET_MANY,
    GET_ONE,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE
} from 'react-admin';
import { versionsFields } from "./versions.model";
import { stringify } from 'query-string';

const API_URL = process.env.REACT_APP_API_URL;

export const RESOURCE_NAME = "versions";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    switch (requestType) {
        case GET_LIST: 
            console.log("Versions ", "Get_List");
            return composeGetVersionsListRequest(requestParams);
        case GET_ONE:
        console.log("Versions ", "Get_One");
            return { url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}` };
        case GET_MANY: {
            console.log("versions_PROVIDER get many with requestParams => ", requestParams);
            const query = {
                filter: JSON.stringify({ id: requestParams.ids }),
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}` };
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = requestParams.pagination;
            const { field, order } = requestParams.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                filter: JSON.stringify({ ...requestParams.filter, [requestParams.target]: requestParams.id }),
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}` };
        }
        case UPDATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: { method: 'PUT', body: JSON.stringify(requestParams.data) },
            };
        case CREATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}`,
                options: { method: 'POST', body: JSON.stringify(requestParams.data) },
            };
        case DELETE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: { method: 'DELETE', body: JSON.stringify(requestParams.data) }
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    let { headers, json } = httpResponse;
    switch (requestType) {
        case GET_LIST:
            return {
                data: json.map(versionItem => versionItem),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        case GET_MANY:
            let versionIdsToGet = requestParams.ids;
            console.log("versions_provider handling ids => ", versionIdsToGet);
            return {
                data: json.filter(versionItem => versionIdsToGet.includes(versionItem.id))
            };
        case CREATE:
            return { data: { ...requestParams.data, id: json.id } };
        default:
            return { data: json };
    }
};

const composeGetVersionsListRequest = (requestParams) => {
    const { page, perPage } = requestParams.pagination;
    const { field, order } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }
        console.log("composeGetVersionsListRequest requestFilters => ", requestFilters);

        let createContainsFilter = (name, value) => ({ name, comparison: "contains", value });

        let resultFilters = [];
        // append "version" filter
        if (requestFilters.hasOwnProperty(versionsFields.version)) {
            resultFilters.push(createContainsFilter('version', requestFilters[versionsFields.version]));
        }
       
        return resultFilters;
    };

    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filters: JSON.stringify(prepareFilters()),
    };
    return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(queryParams)}` };
}

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};
