import {useGetList, useGetOne} from "react-admin";
import {RESOURCE_NAME as NON_PROVIDER_ROLES_RESOURCE} from "../../../roles/non.provider.roles.dataProvider";
import {useEffect, useState} from "react";
import {USER_ROLES} from "../../../roles/constants";

export const useRole = ({ userId }) => {
  const { data: roles, loading: isLoadingRoles  } = useGetList(NON_PROVIDER_ROLES_RESOURCE, {
    pagination: { page: 1, perPage: 50 },
    sort: { field: 'name', order: 'ASC' },
    filter: {},
  });
  const [roleId, setRoleId] = useState(null);
  const {data: user, loading: isLoadingUser} = useGetOne(
    'users',
    userId,
    {
      enabled: !!userId
    }
  )

  const [roleName, setRoleName] = useState(null);

  const updateRoleName = () => {
    let role;
    if (user && user.role_id) {
      role = roles[user.role_id];
    } else {
      role = roles[roleId];
    }
    const roleName = role?.name?.toLowerCase();

    setRoleName(roleName);
  };

  useEffect(() => {
    if (!isLoadingUser && !isLoadingRoles) {
      updateRoleName();
    }
  }, [isLoadingUser, isLoadingRoles, roleId]);

  const isProvider = roleName === USER_ROLES.PROVIDER;
  const isPatient = roleName === USER_ROLES.PATIENT;
  const isAdmin = roleName === USER_ROLES.ADMIN;

  return {
    roleName,
    isProvider,
    isPatient,
    isLoadingRoles,
    isAdmin,
    handlers: {
      setRoleId
    }
  }
}