import React from 'react';
import { TextField, Link } from 'react-admin';
import { codesFields } from '../../codes.model';
import { RESOURCE_NAME as USERS_RESOURCE } from '../../../users/user.dataProvider';

export default (props) => {
    let { record = {} } = props;

    let usersCount = record[codesFields.usersCount] || 0
    if (usersCount === 0) {
        return "n/a";
    }
    if (usersCount > 1) {
        return `Redeemed ${usersCount} times`
    }
    

    let firstName = record[codesFields.userFirstName] || '';
    let lastName = record[codesFields.userLastName] || '';
    let fullName = `${firstName} ${lastName}`;

    let userInfoKey = 'userInfo';
    let recordCopy = {
        ...record,
        userInfo: fullName,
    };
    console.log("full: " + JSON.stringify(recordCopy));
    return (<Link to={`/${USERS_RESOURCE}/${props.record.user_id}/show`}>            
        <TextField source={userInfoKey} record={recordCopy} style={{ color: 'blue' }} />
    </Link>)    
};