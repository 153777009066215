import React from 'react';
import { Link, TextField } from 'react-admin';
import { RESOURCE_NAME as USERS_RESOURCE } from '../../../users/user.dataProvider';
import { userFields } from '../../../users/user.model';

const UserLinkField = props => {
    if (!props.record || !props.record[userFields.id]) {
        return 'n/a';
    }
    
    return (
        <Link to={`/${USERS_RESOURCE}/${props.record[userFields.id]}/show`}>
            <TextField source="user_email" style={{ color: 'blue' }} {...props} />
        </Link>
    );
};

UserLinkField.defaultProps = {
    addLabel: true
};

export default UserLinkField; 