import React, { Component } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  BooleanInput
} from 'react-admin';
import { orgsFields } from '../orgs.model';
import { validatePhone } from '../../utils/validation.js'
import ProviderRoleOrderedList from '../../app/providerRoleOrderedList.component';
import SaveButtonValidated from "../../material/saveButtonValidated.component";
import ContentLinksList from '../../material/ContentLinksList.component.js';

export const styles = {
  roleName: { display: 'inline-block' },
  lineType: { display: 'inline-block', marginLeft: 32 }
};

const PostEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButtonValidated />
  </Toolbar>
);

export default class OrgCreate extends Component { //= ({ classes, ...props }) => (
  render() {
    return (
      <Create {...this.props}>
        <SimpleForm redirect="show" toolbar={<PostEditToolbar/>} >
          <TextInput source={orgsFields.orgName} label='Name' validate={required()} />
          <TextInput source={orgsFields.orgNursePhone} label='Nurse Phone' validate={validatePhone()} />
          <TextInput source={orgsFields.payerId} label="Payer ID" />
          <TextInput source={orgsFields.orgSdkApiKey} label='Sdk api key' />
          <TextInput multiline fullWidth source={orgsFields.orgSdkAuthenticationEndpoint} label='Sdk authentication endpoint' />
          <TextInput source={orgsFields.orgSecretKey} label='Secret key' />
          <BooleanInput source={orgsFields.contentLibraryEnabled} label="Content Library Enabled" />
          {/* <ContentLinksList editable/> */}
          <BooleanInput source={orgsFields.medicaidIdRegistrationQuestionEnabled} label="Medicaid ID Registration Question Enabled" />
          <BooleanInput source={orgsFields.chatEnabled} label="Chat Enabled" />
          <BooleanInput source={orgsFields.enrollmentCodeRequired} label="Enrollment Code Required" />
          <BooleanInput source={orgsFields.requiresWicId} label=" WIC ID Required" />
          <ProviderRoleOrderedList rolesFieldName={orgsFields.roleIds}/>
        </SimpleForm>
      </Create>
    );
  }
}