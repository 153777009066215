import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput
} from 'react-admin';
import { codesFields } from '../codes.model';
import CodeType from './fields/codeType.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import OrganizationLinkField from './fields/codeOrganizationLink.component';
import CodeLinkField from './fields/codeLink.component';
import CodeRedeemed from './fields/codeRedeemed.component';


const CodesFilter = props => (
    <Filter {...props}>
        <TextInput label="Name" source={codesFields.codeName} allowEmpty />
        <ReferenceInput label="Organization" 
            source={codesFields.orgId} perPage={10000} sort={{ field: "name", order: 'ASC' }} reference={ORGANIZATIONS_RESOURCE}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput
            label="Redeemed"
            source={codesFields.redeemed}
            choices={[
                { id: true, name: 'Yes' },
                { id: false, name: 'No' },
                { id: "n/a", name: "n/a" }
            ]}
        />
    </Filter>
);

const CodesList = props => {
    return (
        <List {...props} title="Membership Codes" sort={{ field: 'id', order: 'DESC' }} filters={<CodesFilter />} bulkActionButtons={false}	exporter={false} >
            <Datagrid>
                <CodeLinkField source={codesFields.id} label="Id" addLabel/>
                <OrganizationLinkField label="Organization" addLabel/>
                <DefaultPlaceholderField source={codesFields.codeName} wrapField={<TextField/>}  label="Code Name" />
                <DefaultPlaceholderField source={codesFields.codeType} wrapField={<CodeType/>}  label="Code Type" />
                {/* <CodeType sortable={true} label="Code Type"  addLabel/> */}
                <CodeRedeemed source={codesFields.redeemed} sortable={false} label="Redeemed" addLabel />                
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    )
};

export default CodesList;
