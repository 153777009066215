import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    DeleteButton
} from 'react-admin';
import { versionsFields } from '../versions.model';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import YesNoField from '../../material/yesNoField.component';
import VersionPlatform from './fields/versionPlatform.component';
import VersionAppType from './fields/versionAppType.component';
import VersionLinkField from './fields/versionLink.component';

const VersionsList = props => {
    return (
        <List {...props} 
        title="App Versions" 
        sort={{ field: 'id', order: 'DESC' }} 
        bulkActionButtons={false}
        exporter={false}>
            <Datagrid>
                <VersionLinkField label="Id" source={versionsFields.id}/>
                <VersionPlatform label="Platform" />
                <VersionAppType label="App Type" />
                <DefaultPlaceholderField source={versionsFields.version} wrapField={<TextField/>}  label="Version" />
                <YesNoField source={versionsFields.forceUpdate} wrapField={<TextField/>}  label="Force Update" />
                <DefaultPlaceholderField source={versionsFields.build} wrapField={<TextField/>}  label="Build" />   
                <DefaultPlaceholderField source={versionsFields.releaseNotes} wrapField={<TextField/>}  label="Release Notes" />
                <ShowButton />
                <EditButton />
                <DeleteButton  undoable={false}/>
            </Datagrid>
        </List>
    )
};

export default VersionsList;
