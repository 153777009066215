import React from 'react';
import { Link, TextField } from 'react-admin';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../../organizations/organizations.dataProvider';
import { codesFields } from '../../codes.model';


const OrganizationLinkField = props => {
    if (!props.record[codesFields.orgName]) {return 'n/a'}
    return (
    <Link to={`/${ORGANIZATIONS_RESOURCE}/${props.record.organization_id}/show`}>
        <TextField source={codesFields.orgName} style={{ color: 'blue' }} {...props}/>
    </Link>)
};

OrganizationLinkField.defaultProps = {
    addLabel: true
};

export default OrganizationLinkField;
