import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE
} from 'react-admin';
import { stringify } from 'query-string';
import { hotlineFields } from "./hotline.model";

const API_URL = process.env.REACT_APP_API_URL;

export const RESOURCE_NAME = "hotline";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    switch (requestType) {
        case GET_LIST: 
        console.log("requestParams: ", requestParams);
            return composeGetCallsListRequest(requestParams);
        case GET_ONE:
            return { url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}` };
        case GET_MANY: {
            const query = {
                filter: JSON.stringify({ id: requestParams.ids }),
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}` };
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = requestParams.pagination;
            const { field, order } = requestParams.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                filter: JSON.stringify({ ...requestParams.filter, [requestParams.target]: requestParams.id }),
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}` };
        }
        case UPDATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: { method: 'PUT', body: JSON.stringify(requestParams.data) },
            };
        case CREATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}`,
                options: { method: 'POST', body: JSON.stringify(requestParams.data) },
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const composeGetCallsListRequest = (requestParams) => {
    const { page, perPage } = requestParams.pagination;
    const { field, order } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }
        let createEqFilter = (name, value) => ({ name, comparison: "eq", value });
        let createContainsFilter = (name, value) => ({ name, comparison: "contains", value });
        let createDateBetweenFromFilter = (name, value) => ({ name, comparison: "betweenFrom", value });
        let createDateBetweenToFilter = (name, value) => ({ name, comparison: "betweenTo", value });

        console.log("requestParams: " + JSON.stringify(requestParams));

        let resultFilters = [];
        // append "call id" filter
        if (requestFilters.hasOwnProperty(hotlineFields.id)) {
            resultFilters.push(createContainsFilter('id', requestFilters[hotlineFields.id]));
        }
        // append "patient id" filter
        if (requestFilters.hasOwnProperty(hotlineFields.patientId)) {
            resultFilters.push(createContainsFilter('patientId', requestFilters[hotlineFields.patientId]));
        }
        // append "patient first name" contains
        if (requestFilters.hasOwnProperty(hotlineFields.patientFirstName)) {
            resultFilters.push(createContainsFilter('patientFirstName', requestFilters[hotlineFields.patientFirstName]));
        }
        // append "patient last name" contains
        if (requestFilters.hasOwnProperty(hotlineFields.patientLastName)) {
            resultFilters.push(createContainsFilter('patientLastName', requestFilters[hotlineFields.patientLastName]));
        }
        // append "provider first name" contains
        if (requestFilters.hasOwnProperty(hotlineFields.providerFirstName)) {
            resultFilters.push(createContainsFilter('providerFirstName', requestFilters[hotlineFields.providerFirstName]));
        }
        // append "provider last name" contains
        if (requestFilters.hasOwnProperty(hotlineFields.providerLastName)) {
            resultFilters.push(createContainsFilter('providerLastName', requestFilters[hotlineFields.providerLastName]));
        }
        // append "orgId" filter
        if (requestFilters.hasOwnProperty(hotlineFields.patientOrgId)) {
            resultFilters.push(createEqFilter('patientOrgId', requestFilters[hotlineFields.patientOrgId]));
        }
        // append "groupId" filter
        if (requestFilters.hasOwnProperty(hotlineFields.groupId)) {
            resultFilters.push(createEqFilter('groupId', requestFilters[hotlineFields.groupId]));
        }
        // append "call status" filter
        if (requestFilters.hasOwnProperty(hotlineFields.status)) {
            resultFilters.push(createEqFilter('status', requestFilters[hotlineFields.status]));
        }
        // append "non clinical" filter
        if (requestFilters.hasOwnProperty(hotlineFields.nonClinical)) {
            resultFilters.push(createEqFilter('nonClinical', requestFilters[hotlineFields.nonClinical]));
        }
        // append "created at" filter
        if (requestFilters.hasOwnProperty('startedBetween')) {
            let between = requestFilters['startedBetween'];
            console.log("started at from startedBetween: ", between)
            if (between.gte != null) {
                console.log("started at from startedBetween.gte: ", between.gte)
                resultFilters.push(createDateBetweenFromFilter('startedAtFrom', between.gte));
            }
            if (between.lte != null) {
                console.log("started at to startedBetween.lte: ",  between.lte)
                resultFilters.push(createDateBetweenToFilter('startedAtTo', between.lte));
            }
        }

        // append "ended at" filter
        if (requestFilters.hasOwnProperty('endedBetween')) {
            let between = requestFilters['endedBetween'];
            console.log("ended at from endedBetween: ", between)
            if (between.gte != null) {
                console.log("ended at from endedBetween.gte: ", between.gte)
                resultFilters.push(createDateBetweenFromFilter('endedAtFrom', between.gte));
            }
            if (between.lte != null) {
                console.log("ended at to endedBetween.lte: ", between.lte)
                resultFilters.push(createDateBetweenToFilter('endedAtTo', between.lte));
            }
        }
        return resultFilters;
    };

    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage]),
        filters: JSON.stringify(prepareFilters()),
        export: perPage === 999999999 ? true : false
    };
    return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(queryParams)}` };
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    var { headers, json } = httpResponse;
    switch (requestType) {
        case GET_LIST:
            console.log(json);
            if (json['url'] != null) { // Large Export Hack -- a url is returned to a presigned s3 file for download, handled downstream.
              return { data: [{ id: 9999999, url: json['url']}], total: 9999999 }; // Have to use this format as part of the hack to slip through react-admin validation
            }
            return {
                data: json.map(x => x),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        case CREATE:
            return { data: { ...requestParams.data, id: json.id } };
        default:
            return { data: json };
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};
