import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    BooleanInput,
    required,
} from 'react-admin';

const notificationTypeChoices = [
    { id: 'DOULA', name: 'Doula' },
    { id: 'LC', name: 'LC' },
];

const milestoneTypeChoices = [
    { id: 'prenatal', name: 'Prenatal' },
    { id: 'postnatal', name: 'Postnatal' },
];

const HiddenInput = ({ source, defaultValue }) => (
    <input type="hidden" name={source} defaultValue={defaultValue} />
);

export const PregnancyMilestoneNotificationCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title" validate={required()} />
            <TextInput source="body" validate={required()} multiline rows={4} />
            <TextInput source="liquid_body" multiline rows={4} />
            <NumberInput source="days_from_due_date" validate={required()} />
            <TextInput source="scheduled_time" validate={required()} />
            <SelectInput 
                source="notification_type" 
                choices={notificationTypeChoices}
                validate={required()} 
            />
            <SelectInput 
                source="milestone_type" 
                choices={milestoneTypeChoices}
                validate={required()} 
            />
            <TextInput source="deep_link" />
            <HiddenInput source="push_message_type" defaultValue="milestone" />
            <HiddenInput source="notification_channel" defaultValue="" />
            <HiddenInput source="time_to_live" defaultValue={86400} />
            <HiddenInput source="android_channel_id" defaultValue="" />
            <HiddenInput source="android_accent_color" defaultValue="" />
            <HiddenInput source="ios_sound" defaultValue="default" />
            <BooleanInput source="is_active" defaultValue={true} />
        </SimpleForm>
    </Create>
); 