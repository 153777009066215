import React, {Component} from 'react';
import {Button, Snackbar, Slide} from '@material-ui/core';
import {Alert} from '@material-ui/lab'
import {
    SimpleForm,
    Toolbar,
    PasswordInput,
    required
} from 'react-admin';
import dataProvider from '../../app/dataProvider';
import { RESOURCE_NAME as USERS_RESOURCE } from '../../users/user.dataProvider';

const validateEdit = (values) => {
    const errors = {};

    if(values.password){
        let passwordErrors = [];
        if(values.password.length < 14){
            passwordErrors.push("be at least 14 symbols");
        }
        if(!/[A-Z]+/.test(values.password)){
            passwordErrors.push("contain at least 1 uppercase letter");
        }
        if(!/[a-z]+/.test(values.password)){
            passwordErrors.push("contain at least 1 lowercase letter");
        }
        if(!/[0-9]+/.test(values.password)){
            passwordErrors.push("contain at least 1 number");
        }
        if(!/[!@#$%^&*]+/.test(values.password)){
            passwordErrors.push("contain at least 1 special character");
        }
        if(passwordErrors.length > 0){
            errors.password = `Must ${passwordErrors.join(", ")}.`;
        }
    }

    if (values.password !== values.passwordConfirmation) {
        errors.passwordConfirmation = 'Passwords should be the same';
    }
    return errors
};

const PostButton = ({ invalid, ...rest }) => (
    <Button disabled={invalid} {...rest} />
);

export default class UserNotify extends Component {

    POST_CHANGE_PASSWORD = "POST_CHANGE_PASSWORD";

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            passwordConfirmation: "",
            snackbar: {
                open: false,
                severity: "error",
                message: ""
            }
        }
    }

    render() {
        return (               
            <SimpleForm submitOnEnter={false} validate={validateEdit} toolbar={
                       
                <Toolbar {...this.props} >                    
                    <PostButton variant="contained" color="primary" onClick={this.sendChangePassword}>
                        Change Password
                    </PostButton>  
                    
                </Toolbar>}>
                <Snackbar open={this.state.snackbar.open} autoHideDuration={3000} onClose={this.handleClose} TransitionComponent={Slide}>
                    <Alert onClose={this.handleClose} severity={this.state.snackbar.severity}>
                        {this.state.snackbar.message}
                    </Alert>
                </Snackbar>
                <PasswordInput 
                    source="password" 
                    label='New password' 
                    onChange={this.handlePasswordChange} 
                    validate={[required()]}/>
                <PasswordInput 
                    source="passwordConfirmation" 
                    label='Re-enter new password'  
                    validate={[required()]}
                    onChange={this.handlePasswordConfirmationChange} />
                
            </SimpleForm>           
        );
    }

    sendChangePassword = () => {
        const requestParams = {
            data: {
                userId: this.props.match.params.id, //maps to the id defined in the url
                password: this.password
        }}
        dataProvider(this.POST_CHANGE_PASSWORD, USERS_RESOURCE, requestParams)
        .then( response => {
            if(response.data.message === "Success") {
                this.setState({
                    ...this.state,
                    snackbar: {
                        open: true,
                        severity: "success",
                        message: "Password changed!"
                    }
                });
                window.location.pathname = USERS_RESOURCE + "/" + this.props.match.params.id + "/show";
            } else {
                this.setState({
                    ...this.state,
                    snackbar: {
                        open: true,
                        severity: "error",
                        message: "Password change failed."
                    }
                });
            }
        })
        .catch( error => {
            this.setState({
                ...this.state,
                snackbar: {
                    open: true,
                    severity: "error",
                    message: "Password change failed."
                }
            });
        });
    };
    
    handlePasswordChange = (data) => {
        this.password = data.target.value;
    }

    handlePasswordConfirmationChange = (data) => {
        this.passwordConfirmation = data.target.value;
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            ...this.state,
            snackbar: {
                ...this.state.snackbar,
                open: false,
            }
        });
    };
}