import React from 'react';
import RoleIcon from '@material-ui/icons/TagFaces';
import VersionIcon from '@material-ui/icons/Book';
import CallsIcon from '@material-ui/icons/Call';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import CodeIcon from '@material-ui/icons/Code';
import OrgIcon from '@material-ui/icons/Domain';
import PinpointIcon from '@material-ui/icons/FileCopyRounded';
import { Admin, Resource, defaultTheme } from 'react-admin';
import { createTheme } from "@material-ui/core/styles";
import merge from 'lodash/merge';
import CustomLayout from './customLayout';

import Dashboard from '../dashboard/dashboard.component';
import UserList from '../users/component/userList.component';
import UserEdit from '../users/component/userEdit.component';
import UserCreate from '../users/component/userCreate.component';
import UserShow from '../users/component/userShow.component';
import VersionsList from '../versions/component/versionsList.component';
import VersionShow from '../versions/component/versionShow.component';
import VersionEdit from '../versions/component/versionEdit.component';
import VersionCreate from '../versions/component/versionCreate.component';
import CodesList from '../codes/component/codesList.component';
import CodeShow from '../codes/component/codeShow.component';
import CodeEdit from '../codes/component/codeEdit.component';
import CodeCreate from '../codes/component/codeCreate.component';
import OrgsList from '../organizations/component/orgsList.component';
import OrgShow from '../organizations/component/orgShow.component';
import OrgEdit from '../organizations/component/orgEdit.component';
import OrgCreate from '../organizations/component/orgCreate.component';
import RolesList from '../roles/component/rolesList.component';
import RoleShow from '../roles/component/roleShow.component';
import RolesEdit from '../roles/component/rolesEdit.component';
import RolesCreate from '../roles/component/rolesCreate.component';
import CallsList from '../calls/component/callsList.component';
import CallsShow from '../calls/component/callsShow.component';
import PinpointEdit from '../pinpoint/component/pinpointEdit.component'
import HotlineList from '../hotline/component/hotlineList.component'


import authProvider from '../auth/authProvider';
import dataProvider from './dataProvider';
import LoginComponent from '../auth/login.component';
import customRoutes from './customRoutes';

import { RESOURCE_NAME as USERS_RESOURCE } from '../users/user.dataProvider';
import { RESOURCE_NAME as ROLES_RESOURCE } from '../roles/roles.dataProvider';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../roles/provider.roles.dataProvider';
import { RESOURCE_NAME as NON_PROVIDER_ROLES_RESOURCE } from '../roles/non.provider.roles.dataProvider';
import { RESOURCE_NAME as VERSIONS_RESOURCE } from '../versions/versions.dataProvider';
import { RESOURCE_NAME as CALLS_RESOURCE } from '../calls/calls.dataProvider';
import { RESOURCE_NAME as CODES_RESOURCE } from '../codes/codes.dataProvider';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../organizations/organizations.dataProvider';
import { ALL_RESOURCE_NAME as ALL_ORGANIZATIONS_RESOURCE } from '../organizations/organizations.dataProvider';
import { RESOURCE_NAME as US_STATES_RESOURCE } from '../usStates/usStates.dataProvider';
import { RESOURCE_NAME as LANGUAGES_RESOURCE } from '../languages/languages.dataProvider';
import { RESOURCE_NAME as FEEDING_PLANS_RESOURCE } from '../prenatalInfo/feedingPlans.dataProvider';
import { RESOURCE_NAME as PINPOINT_RESOURCE } from '../pinpoint/pinpoint.dataProvider';
import { RESOURCE_NAME as HOTLINE_RESOURCE } from '../hotline/hotline.dataProvider';
import { RESOURCE_NAME as CHARTS_RESOURCE } from '../charts/charts.dataProvider';
import { RESOURCE_NAME as PERMISSION_TEMPLATE_RESOURCE } from "../permissionTemplates/permission.template.dataProvider";
import { RESOURCE_NAME as PATIENT_RESOURCE } from "../users/patient.dataProvider";
import { RESOURCE_NAME as PROVIDER_RESOURCE } from "../users/provider.dataProvider";
import { RESOURCE_NAME as ADMIN_RESOURCE } from "../users/admin.dataProvider";

import { rootHistory } from '../index';
import { PregnancyMilestoneNotificationList } from '../notifications/components/PregnancyMilestoneNotificationList';
import { PregnancyMilestoneNotificationEdit } from '../notifications/components/PregnancyMilestoneNotificationEdit';
import { PregnancyMilestoneNotificationCreate } from '../notifications/components/PregnancyMilestoneNotificationCreate';
import { PregnancyMilestoneNotificationDeliveryList } from '../notifications/components/PregnancyMilestoneNotificationDeliveryList';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LocalPostOfficeIcon from '@material-ui/icons/LocalPostOffice';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import { NOTIFICATION_TEMPLATES_RESOURCE, NOTIFICATION_DELIVERIES_RESOURCE, NOTIFICATION_ANALYTICS_RESOURCE } from '../notifications/pregnancyMilestoneNotifications.dataProvider';
import BarChartIcon from '@material-ui/icons/BarChart';
import { PregnancyMilestoneNotificationAnalyticsList } from '../notifications/components/PregnancyMilestoneNotificationAnalyticsList';
import { PregnancyMilestoneNotificationAnalyticsShow } from '../notifications/components/PregnancyMilestoneNotificationAnalyticsShow';

//formats dates *with* times
export const DATE_TIME_OPTIONS = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  timeZone: "America/Toronto"
};

//formats dates *without* times (ex: birthday, due date)
export const DATE_OPTIONS = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
};

let theme = createTheme(merge(defaultTheme, {
  overrides: {
    // slightly darker table header
    RaDatagrid: {
      headerCell: {
        backgroundColor: "#F2F3F4",
      },
    },
  },
}),
);

const chatOptions = { label: 'Chat Interactions' };

export default () => (
  <Admin
    history={rootHistory}
    customRoutes={customRoutes}
    loginPage={LoginComponent}
    dataProvider={dataProvider}
    authProvider={authProvider}
    dashboard={Dashboard}
    layout={CustomLayout}
    theme={theme}
  >
    <Resource
      name={PATIENT_RESOURCE}
      options={{
        label: 'Patients',
        menuLabel: 'Patients'
      }}
      icon={PersonIcon}
      list={UserList}
      show={UserShow}
      edit={UserEdit}
      create={UserCreate}
    />
    <Resource
      name={PROVIDER_RESOURCE}
      options={{
        label: 'Providers',
        menuLabel: 'Providers'
      }}
      icon={LocalHospitalIcon}
      list={UserList}
      show={UserShow}
      edit={UserEdit}
      create={UserCreate}
    />
    <Resource
      name={ADMIN_RESOURCE}
      options={{
        label: 'Admins',
        menuLabel: 'Admins'
      }}
      icon={SettingsIcon}
      list={UserList}
      show={UserShow}
      edit={UserEdit}
      create={UserCreate}
    />
    <Resource name={ROLES_RESOURCE} icon={RoleIcon} list={RolesList} show={RoleShow} edit={RolesEdit} create={RolesCreate} />
    <Resource name={VERSIONS_RESOURCE} icon={VersionIcon} list={VersionsList} show={VersionShow} edit={VersionEdit} create={VersionCreate} />
    <Resource name={CALLS_RESOURCE} icon={CallsIcon} list={CallsList} show={CallsShow} />
    <Resource name={HOTLINE_RESOURCE} icon={HeadsetMicIcon} list={HotlineList} />
    <Resource name={CODES_RESOURCE} icon={CodeIcon} list={CodesList} show={CodeShow} edit={CodeEdit} create={CodeCreate} />
    <Resource name={ORGANIZATIONS_RESOURCE} icon={OrgIcon} list={OrgsList} show={OrgShow} edit={OrgEdit} create={OrgCreate} />
    <Resource name={PINPOINT_RESOURCE} icon={PinpointIcon} edit={PinpointEdit} />

    <Resource 
        name={NOTIFICATION_TEMPLATES_RESOURCE}
        options={{
            label: 'Milestones',
            menuLabel: 'Milestones',
            pageLabel: 'Pregnancy Milestones Notifications',
            subMenu: true
        }}
        icon={NotificationsIcon}
        list={PregnancyMilestoneNotificationList}
        edit={PregnancyMilestoneNotificationEdit}
        create={PregnancyMilestoneNotificationCreate}
    />
    <Resource 
        name={NOTIFICATION_DELIVERIES_RESOURCE}
        options={{
            label: 'Deliveries',
            parent: 'Milestones',
            menuLabel: 'Deliveries'
        }}
        icon={LocalPostOfficeIcon}
        list={PregnancyMilestoneNotificationDeliveryList}
    />
    <Resource 
        name={NOTIFICATION_ANALYTICS_RESOURCE}
        options={{
            label: 'Analytics',
            parent: 'Milestones',
            menuLabel: 'Analytics'
        }}
        icon={BarChartIcon}
        list={PregnancyMilestoneNotificationAnalyticsList}
        show={PregnancyMilestoneNotificationAnalyticsShow}
    />

    {/* Non visible resources required for API calls for necessary data */}
    <Resource name={USERS_RESOURCE} />
    <Resource name={US_STATES_RESOURCE} />
    <Resource name={PERMISSION_TEMPLATE_RESOURCE} />
    <Resource name={PROVIDER_ROLES_RESOURCE} />
    <Resource name={NON_PROVIDER_ROLES_RESOURCE} />
    <Resource name={ALL_ORGANIZATIONS_RESOURCE} />
    <Resource name={LANGUAGES_RESOURCE} />
    <Resource name={FEEDING_PLANS_RESOURCE} />
    <Resource name={CHARTS_RESOURCE} />
  </Admin>
);
