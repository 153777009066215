import React from 'react';
import { Link, TextField } from 'react-admin';
import { RESOURCE_NAME as CALLS_RESOURCE } from '../../calls.dataProvider';
import { callsFields } from '../../calls.model';


const CallLinkField = props => {
    if (!props.record[callsFields.id]) {return 'n/a'}
    return (
    <Link to={`${CALLS_RESOURCE}/${props.record.id}/show`}>
        <TextField source={callsFields.id} style={{ color: 'blue' }} {...props}/>
    </Link>)
};

CallLinkField.defaultProps = {
    addLabel: true
};

export default CallLinkField;
