import React from 'react';
import { Link, ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { RESOURCE_NAME as GIFTS_RESOURCE } from '../../../gifts/gifts.dataProvider';
import { userGiftFields, userFields } from '../../user.model';


const GiftLinkField = props => {
    if (!props.record[userGiftFields.code]) {return 'n/a'}
    return (
    <Link to={`/${GIFTS_RESOURCE}/${props.record[userGiftFields.id]}/show`} style={{ padding: 5 }}>
        <ChipField source={userGiftFields.code} style={{ color: 'blue' }} {...props}/>
    </Link>
    )
  }
  
  
export default (props) => {
    //var giftsValue = props.record[userFields.gifts];
    console.log("props: " + JSON.stringify(props));
    return (
        <ArrayField source={userFields.gifts} {...props}>
            <SingleFieldList linkType={true}>
                <GiftLinkField />
            </SingleFieldList>
        </ArrayField>

    ); 
}
/*
const GiftLinkField = props => {
    console.log("Gift " + JSON.stringify(props));
    let gifts = props.record[userFields.gifts];
    console.log(gift);
    if (!gift) { return null }
    
    console.log("gift2");
    console.log("gift " + JSON.stringify(gift));
    return (    
    <Link to={`/${GIFTS_RESOURCE}/${gift[userGiftFields.id]}/show`}>
        <TextField source={userGiftFields.code} record={gift} style={{ color: 'blue' }}/>
    </Link>
    )
    
};

GiftLinkField.defaultProps = {
    addLabel: true
};


export const GiftLinkTableRow = ({ label, record = {} }) => {
    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            <TableCell>
                <GiftLinkField record={record}/>
            </TableCell>
        </TableRow>
    );
};
*/
// export default GiftLinkField;