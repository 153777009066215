import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE
} from 'react-admin';
import { chatsFields } from './chats.model';

const API_URL = process.env.REACT_APP_API_URL;

export const RESOURCE_NAME = "chats";
export const POST_UPDATE_CHART = "POST_UPDATE_CHART";

const providerRequestToHttpRequest = (requestType, requestParams) => {
  switch (requestType) {
    case GET_LIST:
      return composeGetChatsListRequest(requestParams);
    case GET_ONE:
      return { url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}` };
    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: requestParams.ids })
      };
      return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}` };
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = requestParams.pagination;
      const { field, order } = requestParams.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
        filter: JSON.stringify({ ...requestParams.filter, [requestParams.target]: requestParams.id }),
      };
      return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}` };
    }
    case UPDATE:
      return {
        url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
        options: { method: 'PUT', body: JSON.stringify(requestParams.data) },
      };
    case CREATE:
      return {
        url: `${API_URL}/${RESOURCE_NAME}`,
        options: { method: 'POST', body: JSON.stringify(requestParams.data) },
      };
    case POST_UPDATE_CHART:
      return {
        url: `${API_URL}/${RESOURCE_NAME}/updatechart`,
        options: {
          method: 'POST',
          body: JSON.stringify(requestParams)
        },
      };
    default:
      throw new Error(`Unsupported fetch action type ${requestType}`);
  }
};

const composeGetChatsListRequest = (requestParams) => {
  const { page, perPage } = requestParams.pagination;
  const { field, order } = requestParams.sort;

  const prepareFilters = () => {
    const requestFilters = requestParams.filter;

    if (requestFilters === undefined) {
      return {};
    }

    const createEqFilter = (name, value) => ({ name, comparison: "eq", value });
    const createContainsFilter = (name, value) => ({ name, comparison: "contains", value });
    const createDateBetweenFromFilter = (name, value) => ({ name, comparison: "betweenFrom", value });
    const createDateBetweenToFilter = (name, value) => ({ name, comparison: "betweenTo", value });

    console.log("requestParams: " + JSON.stringify(requestParams));

    const resultFilters = [];
    // append "chat interaction id" filter
    if (requestFilters.hasOwnProperty(chatsFields.chatInteractionId)) {
      resultFilters.push(createContainsFilter('id', requestFilters[chatsFields.chatInteractionId]));
    }
    // append "chat id" filter
    if (requestFilters.hasOwnProperty(chatsFields.chatId)) {
      resultFilters.push(createContainsFilter('chatId', requestFilters[chatsFields.chatId]));
    }
    // append "provider chart" filter
    if (requestFilters.hasOwnProperty(chatsFields.providerChartType)) {
      resultFilters.push(createEqFilter('chartType', requestFilters[chatsFields.providerChartType]));
    }
    // append "chat status" filter
    if (requestFilters.hasOwnProperty(chatsFields.status)) {
      resultFilters.push(createEqFilter('status', requestFilters[chatsFields.status]));
    }
    // append "patient first name" contains
    if (requestFilters.hasOwnProperty(chatsFields.patientFirstName)) {
      resultFilters.push(createContainsFilter('patientFirstName', requestFilters[chatsFields.patientFirstName]));
    }
    // append "patient last name" contains
    if (requestFilters.hasOwnProperty(chatsFields.patientLastName)) {
      resultFilters.push(createContainsFilter('patientLastName', requestFilters[chatsFields.patientLastName]));
    }
    // append "provider first name" contains
    if (requestFilters.hasOwnProperty(chatsFields.providerFirstName)) {
      resultFilters.push(createContainsFilter('providerFirstName', requestFilters[chatsFields.providerFirstName]));
    }
    // append "provider last name" contains
    if (requestFilters.hasOwnProperty(chatsFields.providerLastName)) {
      resultFilters.push(createContainsFilter('providerLastName', requestFilters[chatsFields.providerLastName]));
    }
    // append "created at" filter
    if (requestFilters.hasOwnProperty('startedBetween')) {
      let between = requestFilters['startedBetween'];
      console.log("started at from startedBetween: ", between)
      if (between.gte != null) {
        console.log("started at from startedBetween.gte: ", between.gte)
        resultFilters.push(createDateBetweenFromFilter('startedAtFrom', between.gte));
      }
      if (between.lte != null) {
        console.log("started at to startedBetween.lte: ", between.lte)
        resultFilters.push(createDateBetweenToFilter('startedAtTo', between.lte));
      }
    }
    // append "accepted at" filter
    if (requestFilters.hasOwnProperty('acceptedBetween')) {
      let between = requestFilters['acceptedBetween'];
      console.log("accepted at from acceptedBetween: ", between)
      if (between.gte != null) {
        console.log("accepted at from acceptedBetween.gte: ", between.gte)
        resultFilters.push(createDateBetweenFromFilter('acceptedAtFrom', between.gte));
      }
      if (between.lte != null) {
        console.log("accepted at to acceptedBetween.lte: ", between.lte)
        resultFilters.push(createDateBetweenToFilter('acceptedAtTo', between.lte));
      }
    }
    // append "ended at" filter
    if (requestFilters.hasOwnProperty('endedBetween')) {
      let between = requestFilters['endedBetween'];
      console.log("ended at from endedBetween: ", between)
      if (between.gte != null) {
        console.log("ended at from between.gte: ", between.gte)
        resultFilters.push(createDateBetweenFromFilter('endedAtFrom', between.gte));
      }
      if (between.lte != null) {
        console.log("ended at to between.lte: ", between.lte)
        resultFilters.push(createDateBetweenToFilter('endedAtTo', between.lte));
      }
    }
    // append "chat type" filter
    if (requestFilters.hasOwnProperty(chatsFields.type)) {
      resultFilters.push(createEqFilter('chatType', requestFilters[chatsFields.type]));
    }
    return resultFilters;
  };

  const queryParams = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([(page - 1) * perPage, page * perPage]),
    filters: JSON.stringify(prepareFilters()),
    export: requestParams.export
  };

  return {
    url: `${API_URL}/${RESOURCE_NAME}-get`,
    options: { method: 'POST', body: JSON.stringify(queryParams) }
  };
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
  console.log("chats response: ", httpResponse.json)
  const { headers, json } = httpResponse;

  switch (requestType) {
    case GET_LIST:
      if (json['url'] != null) { // Large Export Hack -- a url is returned to a presigned s3 file for download, handled downstream.
        return { data: [{ id: 9999999, url: json['url'] }], total: 9999999 }; // Have to use this format as part of the hack to slip through react-admin validation
      }
      return {
        data: json,
        total: parseInt(headers.get('content-range').split('/').pop()),
      };
    case CREATE:
      return { data: { ...requestParams.data, id: json.id } };
    default:
      return { data: json };
  }
};

export default {
  resource: RESOURCE_NAME,
  providerInterface: {
    providerRequestToHttpRequest,
    httpResponseToProviderData
  },
};