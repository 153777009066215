import React, { Component } from 'react';
import {
    showNotification,
    UPDATE,
    refreshView
} from 'react-admin';
import Button from '@material-ui/core/Button';
import {
    connect
} from 'react-redux';
import dataProvider from '../../../../sdk-app/dataProvider';
import {
    RESOURCE_NAME as SDK_CODE_RESOURCE
} from '../../../sdk-codes/codes.dataProvider';

class InvalidateCodeButton extends Component {
    handleClick = () => {
        const {
            refreshView,
            record,
            showNotification
        } = this.props;
        const codeForUpdate = {
            id: record.active_coupon_id,
            userId: record.id
        };
        dataProvider(UPDATE, SDK_CODE_RESOURCE, codeForUpdate)
            .then(() => {
                refreshView();
                showNotification('Code was invalidated.');
            })
            .catch((e) => {
                showNotification('Error: sdk code was not invalidated.', 'warning');
            });
    }

    render = () => {
        console.log("user actions props ", this.props)
        const {
            record
        } = this.props;
        return <Button variant = "contained"
        color = "primary"
        label = "Invalidate"
        disabled = {
            !record.coupon_active === 1
        }
        onClick = {
            this.handleClick
        }
        >Invalidate</Button>;
    }
}

export default connect(null, {
    showNotification,
    refreshView,
})(InvalidateCodeButton);
