
import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import { userFields } from '../../user.model';

export default ({ record = {} }) => {
    //let divStyle = {
        //display: 'flex',
        //verticalAlign: 'bottom',
    //};
    let firstName = record[userFields.firstName] || '';
    let lastName = record[userFields.lastName] || '';
    let fullName = `${firstName} ${lastName}`;
    return (<div>
        <PersonIcon style={{verticalAlign: 'middle'}}/>
        <span style={{verticalAlign: 'middle'}}>&nbsp;{`${fullName}`}</span>
    </div>);
};
