import React from 'react';
import {
    Edit, 
    SimpleForm,
    TextInput,
    Toolbar,
    required,
    BooleanInput
} from 'react-admin';
import OrgTitle from './fields/orgTitle.component';
import { orgsFields } from '../orgs.model';
import { validatePhone } from '../../utils/validation.js';
import ProviderRoleOrderedList from '../../app/providerRoleOrderedList.component';
import ContentLinksList from '../../material/ContentLinksList.component.js';

import SaveButtonValidated from "../../material/saveButtonValidated.component";



const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButtonValidated />
    </Toolbar>
);

const OrgEdit = props => (
    <Edit title={<OrgTitle style={{verticalAlign: 'middle'}}/>} {...props}>
        <SimpleForm redirect = "show" submitOnEnter={false} toolbar={<PostEditToolbar/>}>
            <TextInput source={orgsFields.orgName} label='Name' validate={required()}/>
            <TextInput source={orgsFields.orgNursePhone} label='Nurse Phone' validate={validatePhone()}/>
            <TextInput source={orgsFields.clientGroupId} label="Healthie Client Group ID" />
            <TextInput source={orgsFields.payerId} label="Payer ID" />
            <TextInput source={orgsFields.orgSdkApiKey} label='Sdk api key' />
            <TextInput multiline fullWidth source={orgsFields.orgSdkAuthenticationEndpoint} label='Sdk authentication endpoint' />
            <TextInput source={orgsFields.orgSecretKey} label='Secret key' />
            <BooleanInput source={orgsFields.contentLibraryEnabled} label="Content Library Enabled" />
            {/* <ContentLinksList {...props} editable/> */}
            <BooleanInput source={orgsFields.medicaidIdRegistrationQuestionEnabled} label="Medicaid ID Registration Question Enabled" />
            <BooleanInput source={orgsFields.chatEnabled} label="Chat Enabled" />
            <BooleanInput source={orgsFields.enrollmentCodeRequired} label="Enrollment Code Required" />
            <BooleanInput source={orgsFields.requiresWicId} label=" WIC ID Required" />
            <ProviderRoleOrderedList {...props} rolesFieldName={orgsFields.roleIds} />
        </SimpleForm>
    </Edit>
);

export default OrgEdit;
