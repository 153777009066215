import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE
} from 'react-admin';
import { stringify } from 'query-string';
import { codesFields } from "./codes.model";

const API_URL = process.env.REACT_APP_API_URL;

export const RESOURCE_NAME = "codes";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    console.log("provderRequestToHttpRequest");
    console.log("requestType: " + requestType);
    console.log("requestParams: " + JSON.stringify(requestParams));
    switch (requestType) {
        case GET_LIST: 
            return composeGetCodesListRequest(requestParams);
        case GET_ONE:
            return { url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}` };
        case GET_MANY: {
            const query = {
                filter: JSON.stringify({ id: requestParams.ids }),
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}` };
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = requestParams.pagination;
            const { field, order } = requestParams.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                filter: JSON.stringify({ ...requestParams.filter, [requestParams.target]: requestParams.id }),
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}` };
        }
        case UPDATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: { method: 'PUT', body: JSON.stringify(requestParams.data) },
            };
        case CREATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}`,
                options: { method: 'POST', body: JSON.stringify(requestParams.data) },
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const composeGetCodesListRequest = (requestParams) => {
    const { page, perPage } = requestParams.pagination;
    const { field, order } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }
        let createEqFilter = (name, value) => ({ name, comparison: "eq", value });
        let createContainsFilter = (name, value) => ({ name, comparison: "contains", value });

        let resultFilters = [];
        // append "name" filter
        if (requestFilters.hasOwnProperty(codesFields.codeName)) {
            resultFilters.push(createContainsFilter('codeName', requestFilters[codesFields.codeName]));
        }
        // append "orgId" filter
        if (requestFilters.hasOwnProperty(codesFields.orgId)) {
            resultFilters.push(createEqFilter('orgId', requestFilters[codesFields.orgId]));
        }
        // append "redeemed" filter
        if (requestFilters.hasOwnProperty(codesFields.redeemed)) {
            resultFilters.push(createEqFilter('redeemed', requestFilters[codesFields.redeemed]));
        }

        return resultFilters;
    };
    
    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage]),
        filters: JSON.stringify(prepareFilters()),
        orgId: 8
    };
    console.log(JSON.stringify(queryParams));
    return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(queryParams)}` };
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    var { headers, json } = httpResponse;
    console.log(json);
    switch (requestType) {
        case GET_LIST:
            return {
                data: json.map(x => x),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        case CREATE:
            return { data: { ...requestParams.data, id: json.id } };
        default:
            return { data: json };
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};
