import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    Filter,
    ReferenceInput,
    SelectInput,
    TextInput,
    DateField,
    downloadCSV
} from 'react-admin';
import { codesFields } from '../codes.model';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';
import { RESOURCE_NAME as SDK_CODE_PLAN_RESOURCE } from '../../plans/plans.dataProvider';
import CodeLinkField from './fields/codeLink.component';
import CodeRedeemed from './fields/codeRedeemed.component';
import { DATE_OPTIONS } from '../../../app/app.component';
import UserLink from './fields/userLink.component';
import InvalidatedField from './fields/invalidatedField.component';
import PeriodInput from "../../../app/periodInput.component";
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';

const exporter = codes => {
    const codesForExport = codes.map(code => {
        console.log("exporter code ::: ", code);
        let createdTime = code.created_at ? moment(code.created_at).zone(60).format('YYYY-MM-DD HH:mm:ss') : "";
        let updatedTime = code.updated_at ? moment(code.updated_at).zone(60).format('YYYY-MM-DD HH:mm:ss') : "";
        let activatedTime = code.activated_at ? moment(code.activated_at).zone(60).format('YYYY-MM-DD HH:mm:ss') : "";
        let disabledTime = code.disabled_at ? moment(code.disabled_at).zone(60).format('YYYY-MM-DD HH:mm:ss') : "";
        code['Id'] = code.id;
        code['Code name'] = code.code_name;
        code['Code type'] = code.code_type;
        code['Created at'] = createdTime;
        code['Updated at'] = updatedTime;
        code['Calls restriction'] = code.calls_restriction;
        code['Activated at'] = activatedTime;
        code['Enabled'] = code.enabled === 1 ? true : false;
        code['Disabled at'] = disabledTime;
        code['Info'] = code.info;
        return code;
    });

    const csv = convertToCSV({
        data: codesForExport,
        fields: ['Id', 'Code name', 'Code type', 'Created at', 'Updated at', 'Calls restriction', 'Activated at', 'Enabled','Disabled at', 'Info']
    });
    downloadCSV(csv, 'membership-codes-' + moment(new Date()).format('YYYY-MM-DD').toString());
};

const CodesFilter = props => (
    <Filter {...props}>
        <ReferenceInput label="Subscription Plan" 
            source={codesFields.planId} reference={SDK_CODE_PLAN_RESOURCE}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput label="Code" source={codesFields.codeName} allowEmpty />
        <TextInput label="Email" source={codesFields.userEmail} allowEmpty />
        <SelectInput
            label="Invalidated" source={codesFields.enabled}
            choices={[
                { id: 0, name: 'Yes' },
                { id: 1, name: 'No' }
            ]}
        />
        <SelectInput
            label="Redeemeed" source={codesFields.activatedAt}
            choices={[
                { id: "not null", name: 'Yes' },
                { id: "null", name: 'No' }
            ]}
        />
        <PeriodInput
          source="redeemed"
          fromLabel="Redeemed At From"
          toLabel="Redeemed At To"
          label="Date Redeemed"
        />
        <PeriodInput
          source="created"
          fromLabel="Created At From"
          toLabel="Created At To"
          label="Date Created"
        />
    </Filter>
);

const CodesList = props => {
    return (
        <List {...props} title="Membership Codes" sort={{ field: 'id', order: 'DESC' }} filters={<CodesFilter/>} exporter={exporter} bulkActionButtons={false}	>
            <Datagrid>
                <DefaultPlaceholderField source={codesFields.id} wrapField={<TextField/>}  label="Id" />
                <CodeLinkField source={codesFields.codeName} label="Name" addLabel/>
                <DefaultPlaceholderField source={codesFields.planName} wrapField={<TextField/>}  label="Plan Code" />
                <DefaultPlaceholderField source={codesFields.createdAt} wrapField={<DateField/>} options={DATE_OPTIONS} label="Date Created" />
                <InvalidatedField source={codesFields.enabled} label="Invalidated" addLabel sortable={false}/>
                <CodeRedeemed source={codesFields.activatedAt} label="Redeemed" addLabel sortable={false}/>
                <DefaultPlaceholderField source={codesFields.activatedAt} wrapField={<DateField/>} options={DATE_OPTIONS} label="Date Redeemed" />
                <UserLink label="User" addLabel/>
                <ShowButton />
            </Datagrid>
        </List>
    )
};

export default CodesList;
