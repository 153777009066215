export let giftsFields = {
    giftId: "id",
    giftCode: "code",
    giftCreatedAt: "created_at",
    giftType: "gift_type",
    giftUserId: "user_id",
    giftUpdatedAt: "updated_at",
    giftRecipientFirstName: "recipient_first_name",
    giftRecipientLastName: "recipient_last_name",
    giftRecipientAddressOne: "recipient_address_one",
    giftRecipientAddressTwo: "recipient_address_two",
    giftRecipientCity: "recipient_city",
    giftRecipientState: "recipient_state",
    giftRecipientZip: "recipient_zip",
    giftSenderFirstName: "sender_first_name",
    giftSenderLastName: "sender_last_name",
    giftSenderEmail: "sender_email",
    giftFrom: "from",
    giftTo: "to",
    giftMessage: "message",
    giftDeliveryType: "delivery_type",
    userFirstName: "user_first_name",
    userLastName: "user_last_name"
};
