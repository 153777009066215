import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
} from 'react-admin';
import {
    stringify
} from 'query-string';
import {
    userFields,
    userCombinedFields
} from "./sdk.user.model";

const API_URL = process.env.REACT_APP_SDK_API_URL;

export const RESOURCE_NAME = "users";
const SDK_USERS_ROUTE_NAME = "sdk-users";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    console.log("sdk params " + stringify(requestParams));
    console.log("sdk requestType " + requestType);
    switch (requestType) {
        case GET_LIST:
            return composeGetUsersListRequest(requestParams);
        case GET_ONE:
            return {
                url: `${API_URL}/${SDK_USERS_ROUTE_NAME}/${requestParams.id}`
            };
        case GET_MANY:
            {
                const query = {
                    filter: JSON.stringify({
                        id: requestParams.ids
                    }),
                };
                return {
                    url: `${API_URL}/${SDK_USERS_ROUTE_NAME}?${stringify(query)}`
                };
            }
        case GET_MANY_REFERENCE:
            {
                const {
                    page,
                    perPage
                } = requestParams.pagination;
                const {
                    field,
                    order
                } = requestParams.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                    filter: JSON.stringify({
                        ...requestParams.filter,
                        [requestParams.target]: requestParams.id
                    }),
                };
                return {
                    url: `${API_URL}/${SDK_USERS_ROUTE_NAME}?${stringify(query)}`
                };
            };
        case UPDATE: 
            console.log("SDK USERS UPDATE method ::: ", requestParams);
            return {
                url: `${API_URL}/${SDK_USERS_ROUTE_NAME}/${requestParams.id}`,
                options: { method: 'PUT', body: JSON.stringify(requestParams) },
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const composeGetUsersListRequest = (requestParams) => {
    const {
        page,
        perPage
    } = requestParams.pagination;
    const {
        field,
        order
    } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }
        let createEqFilter = (name, value) => ({
            name,
            comparison: "eq",
            value
        });
        let createInFilter = (name, value) => ({
            name,
            comparison: "in",
            value
        });
        let createContainsFilter = (name, value) => ({
            name,
            comparison: "contains",
            value
        });

        let resultFilters = [];
        // append "email" filter
        if (requestFilters.hasOwnProperty(userFields.email)) {
            resultFilters.push(createContainsFilter('email', requestFilters[userFields.email]));
        }
        // append "firstName" filter
        if (requestFilters.hasOwnProperty(userFields.firstName)) {
            resultFilters.push(createContainsFilter('firstName', requestFilters[userFields.firstName]));
        }
        // append "lastName" filter
        if (requestFilters.hasOwnProperty(userFields.lastName)) {
            resultFilters.push(createContainsFilter('lastName', requestFilters[userFields.lastName]));
        }
        // append "roleId" filter
        if (requestFilters.hasOwnProperty(userFields.roleId)) {
            resultFilters.push(createInFilter('roleId', requestFilters[userFields.roleId]));
        }
        // append "orgId" filter
        if (requestFilters.hasOwnProperty(userFields.orgId)) {
            resultFilters.push(createEqFilter('orgId', requestFilters[userFields.orgId]));
        }
        // append "isActive" filter
        if (requestFilters.hasOwnProperty(userFields.is_active_sdk_user)) {
            resultFilters.push(createEqFilter('is_active_sdk_user', requestFilters[userFields.is_active_sdk_user]));
        }
        // append "recurlyActive" filter
        if (requestFilters.hasOwnProperty(userFields.recurlyActive)) {
            resultFilters.push(createEqFilter('recurlyActive', requestFilters[userFields.recurlyActive]));
        }
        // append "code_name" filter
        
        if (requestFilters.hasOwnProperty(userFields.orgCodes)) {
            resultFilters.push(createContainsFilter(userFields.orgCodes, requestFilters[userFields.orgCodes]));
        }

        // if (requestFilters.hasOwnProperty('codeNames')) {
        //     resultFilters.push(createContainsFilter('codeNames', requestFilters['codeNames']));
        // }
        return resultFilters;
    };

    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage]),
        filters: JSON.stringify(prepareFilters()),
    };
    console.log("#### queryParams ", queryParams);
    return {
        url: `${API_URL}/${SDK_USERS_ROUTE_NAME}?${stringify(queryParams)}`
    };
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    var {
        headers,
        json
    } = httpResponse;
    console.log("users httpResponse : ", httpResponse.body);
    switch (requestType) {
        case GET_LIST:
            return {
                data: json.map(x => x),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        case CREATE:
            return {
                data: {
                    ...requestParams.data,
                    id: json.id
                }
            };
        default:
            return {
                data: json
            };
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};