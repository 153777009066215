import React from 'react';

import {
    SaveButton
} from 'react-admin';

const SaveButtonValidated = ({ invalid, ...rest }) => (
    <SaveButton disabled={invalid} {...rest} />
);

export default SaveButtonValidated;
  