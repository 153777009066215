import React from 'react';
import {
    Create,
    FormTab,
    TabbedForm,
    TextInput,
    ReferenceInput,
    SelectInput, 
    SelectArrayInput,
    ReferenceArrayInput,    
    required,
    AutocompleteInput,
    Toolbar
} from 'react-admin';
import { codesFields, CodeTypeChoices } from '../codes.model';
import { orgsFields } from '../../organizations/orgs.model';
import { ALL_RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import { rolesFields } from '../../roles/roles.model';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../roles/provider.roles.dataProvider';
import SaveButtonValidated from "../../material/saveButtonValidated.component"
import ProviderRoleOrderedList from '../../app/providerRoleOrderedList.component';

const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButtonValidated />
    </Toolbar>
);

const CodeCreate = ({ classes, ...props }) => (
    <Create {...props}>
        <TabbedForm redirect = "show" toolbar={<PostEditToolbar/>}>
            <FormTab label="Code Details">
                <TextInput source={codesFields.codeName} label='Code Name' validate={required()} />
                <ReferenceInput source={codesFields.orgId} 
                    validate={required()}  
                    reference={ORGANIZATIONS_RESOURCE} label="Organization" allowEmpty >
                    <AutocompleteInput optionText={orgsFields.orgName} />
                </ReferenceInput>            
                <SelectInput 
                        label="Code Type" 
                        source={codesFields.codeType}                    
                        validate={required()}  
                        choices={CodeTypeChoices}/>
                <ProviderRoleOrderedList {...props} rolesFieldName={codesFields.roleIds} />
            </FormTab>
        </TabbedForm>
    </Create>
);

export default CodeCreate;
