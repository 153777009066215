import React, {useEffect} from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
  email,
  BooleanInput,
  DeleteWithConfirmButton,
  AutocompleteInput,
} from 'react-admin';
import UserTitle from './fields/userTitle.component';
import { userFields } from '../user.model';
import { rolesFields } from '../../roles/roles.model';
import { RESOURCE_NAME as NON_PROVIDER_ROLES_RESOURCE } from '../../roles/non.provider.roles.dataProvider';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../roles/provider.roles.dataProvider';
import { userRelativeFields } from '../userRelative.model';
import { ALL_RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';

import { RESOURCE_NAME as US_STATES_RESOURCE } from '../../usStates/usStates.dataProvider';
import { RESOURCE_NAME as LANGUAGES } from '../../languages/languages.dataProvider'
import { RESOURCE_NAME as FEEDING_PLANS } from '../../prenatalInfo/feedingPlans.dataProvider';
import { useUpdate, useNotify } from 'react-admin';
import { useFormState } from 'react-final-form';
import { validatePhone } from '../../utils/validation.js'
import SaveButtonValidated from "../../material/saveButtonValidated.component"
import CustomDatePicker from "../../app/customDatePicker.js"
import { prenatalInfoFields, PrenatalInfoTypeFourChoices, PrenatalInfoTypeThreeChoices } from '../../prenatalInfo/prenatalInfo.model';

import { RESOURCE_NAME } from '../../codes/codes.dataProvider';
import { codesFields } from '../../codes/codes.model';
import {
  RESOURCE_NAME as PERMISSION_TEMPLATE_RESOURCE
} from "../../permissionTemplates/permission.template.dataProvider";
import { ConditionalField } from "../../utils/conditionalField";
import {optionalField} from "../../utils/constants";
import {useRole} from "./hooks/useRole";

const validateEmailField = [required(), email()];

const PostEditToolbar = (props) => {
  const notify = useNotify();
  const { values } = useFormState();

  const [updateUse, {data, error }] = useUpdate(
    'users', // TODO use resource name import
    props.record.id,
    { ...values }
  );

  var errorMessage;
  const deleteConfirmContent = 'Are you sure you want to delete this user? This action is permanent and cannot be undone.';
  // Handle errors
  if (error) {
    if (error.message != null && error.reason != null) {
      errorMessage = 'Error updating user: ' + error.message + " :: " + error.reason;
    }
    else {
      errorMessage = 'Error updating user: ' + JSON.stringify(error);
    }
  }
  else if (data) {
    notify('User updated', 'info', {}, false);
    console.log("edit: " + data.id);
  }

  return (
    <Toolbar {...props} >
      <SaveButtonValidated onClick={updateUse} />
      <DeleteWithConfirmButton confirmContent={deleteConfirmContent}/>
      <br />
      <div style={{color: 'red', margin: '20px'}}>
        {errorMessage}
      </div>
    </Toolbar>
  );
};

const OrgCodeField = () => {
  const { values, initialValues } = useFormState();

  const isOrgForm = typeof values.org_id !== 'undefined'
    && typeof initialValues.org_id !== 'undefined';

  useEffect(() => {
    if (isOrgForm) {
      if (values.org_id === initialValues.org_id) {
        values.code_id = initialValues.org_codes?.length > 0
          ? initialValues.org_codes[0].id
          : null;
      }
      else values.code_id = null;
    }
  }, [values.org_id]);

  return (
    <ReferenceInput
        source={userFields.codeId}
        reference={RESOURCE_NAME}
        perPage={1000}
        sort={{ field: "code_name", order: 'ASC' }}
        filter={{ organization_id: typeof values.org_id !=='undefined' ? values.org_id : null }}
        label="Code"
        defaultValue={values.org_codes?.length > 0 ? values.org_codes[0].id : null }
        allowEmpty>
      <AutocompleteInput optionText={codesFields.codeName} />
    </ReferenceInput>
  )
}

const identity = value => (value);

const UserEdit = props => {
  const { isProvider, isPatient, isAdmin } = useRole({userId: props.id});

  return (
    <Edit title={<UserTitle style={{verticalAlign: 'middle'}}/>} {...props}>
      <TabbedForm redirect="show" submitOnEnter={false} toolbar={<PostEditToolbar/>}>
        <FormTab label="User Details">
          <ReferenceInput
            source={userFields.roleId}
            perPage={10000}
            sort={{ field: "name", order: 'ASC' }}
            reference={NON_PROVIDER_ROLES_RESOURCE}
            validate={required()}
            label="Role"
            // Disabled since role change is not supported
            disabled
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source={userFields.firstName} label='First Name' validate={required()} />
          <TextInput source={userFields.lastName} parse={identity} label='Last Name' />
          <TextInput source={userFields.email} validate={validateEmailField} label='Email' />
          <TextInput
            source={userFields.phoneNumber}
            parse={identity}
            label='Phone'
            validate={[isProvider ? required() : optionalField, validatePhone()]}
          />
          {!(isProvider || isAdmin) && (
            <>
              <CustomDatePicker source={userFields.dob} label='DOB' />
              <CustomDatePicker source={userFields.dueDate} label='Due date' />
            </>
          )}
          <ConditionalField condition={() => isProvider}>
            <ReferenceInput
              source={userFields.permissionTemplateId}
              reference={PERMISSION_TEMPLATE_RESOURCE}
              validate={required()}
              label="Permissions template"
              fullWidth
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput
              source={userFields.providerRoleIds}
              perPage={10000}
              sort={{ field: "name", order: 'ASC' }}
              reference={PROVIDER_ROLES_RESOURCE}
              label="Provider Roles"
              allowEmpty
              fullWidth
            >
              <SelectArrayInput optionText={rolesFields.roleName} />
            </ReferenceArrayInput>
            <TextInput
              rows={5}
              multiline
              resettable
              label="Provider Bio"
              source={userFields.providerBio}
              fullWidth
            />
            <TextInput
              rows={5}
              multiline
              resettable
              label="Provider Bio (Spanish)"
              source={userFields.providerBioEs}
              fullWidth
            />
          </ConditionalField>
          {!(isProvider || isAdmin) && (
            <div style={{display: 'inline-block'}}>
              <ReferenceInput
                source={userFields.orgId}
                reference={ORGANIZATIONS_RESOURCE}
                perPage={10000}
                sort={{ field: "name", order: 'ASC' }}
                label="Organization"
                allowEmpty
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <TextInput source={userFields.wicId} label='WIC ID' />
            </div>
          )}

          <OrgCodeField />
          {!isAdmin && (
            <ReferenceInput
              source={userFields.usState}
              reference={US_STATES_RESOURCE}
              label="State"
              validate={[isPatient ? optionalField : required()]}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}
          {isProvider && (
            <TextInput
              type="text"
              label="ZIP code"
              source={userFields.zipCode}
            />
          )}
          <ReferenceInput source={userFields.lang}
                          reference={LANGUAGES} label="Language" allowEmpty>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <BooleanInput
            label="Is active"
            source={userFields.isActive} />
          {!isPatient && (
            <BooleanInput source={userFields.isMfaRequired} label='MFA Required' />
          )}
          <SelectInput source={userFields.birthWish} label={prenatalInfoFields.birthWish} choices={PrenatalInfoTypeFourChoices} />
          <SelectInput source={userFields.birthPartner} label={prenatalInfoFields.birthPartner} choices={PrenatalInfoTypeThreeChoices} />
          <SelectInput source={userFields.prenatalVisit} label={prenatalInfoFields.prenatalVisit} choices={PrenatalInfoTypeFourChoices} />
          <ReferenceInput source={userFields.feedingPlan} reference={FEEDING_PLANS} label={prenatalInfoFields.feedingPlan}>
            <SelectInput optionText="name" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Relatives">
          <ArrayInput source={userFields.relatives}>
            <SimpleFormIterator>
              <TextInput
                label="Name"
                source={userRelativeFields.name} />
              <CustomDatePicker source={userRelativeFields.dob} label='DoB' validate={required()} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};

export default UserEdit;
