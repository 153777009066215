import React from 'react';
import { Link, TextField } from 'react-admin';
import { RESOURCE_NAME as CODES_RESOURCE } from '../../../sdk-codes/codes.dataProvider';
const wrapperCodeName = "n/a"

const SdkCodeLinkField = (props) => {
    let { source, record, ...rest } = props;
    
    let wrapperKey = 'wrapperKey';
    let wrapperCopy = {
        ...props.record,
        [wrapperKey]: wrapperCodeName,
    };

    let couponDescription = (record.active_coupon_id && record.active_coupon_name) ?  record.active_coupon_name : "loading ..."
    let couponDescriptionKey = 'couponDescriptionKey';
    let recordCopyWithDescription = {
        ...props.record,
        [couponDescriptionKey]: couponDescription,
    };
    const ExpiredCodesField = ({ ...props }) => {
        let lastCode = props.record.org_codes[0];
        let expiredCodeDescription = lastCode ? lastCode.name : wrapperCodeName;
        let expiredCodesComponent = expiredCodeDescription;
        return expiredCodesComponent;
    }

    let linkToCouponWithId = record.active_coupon_id? 
                                (<Link to={`/${CODES_RESOURCE}/${record.active_coupon_id}/show`}>
                                    <TextField source={couponDescriptionKey} record={recordCopyWithDescription} style={{ color: 'blue' }} {...rest}/>
                                </Link>) 
                             : 
                                (<TextField source={wrapperKey} record={wrapperCopy} {...rest} />);

    let linkedComponent = (props.showName) ? 
                                (record.active_coupon_id)? 
                                    linkToCouponWithId 
                                :
                                props.record.org_codes[0] ? 
                                (<div>
                                    <Link to={`/${CODES_RESOURCE}/${props.record.org_codes[0].id}/show`}>
                                        <ExpiredCodesField {...props}/>                       
                                    </Link> (last used code that was invalidated or expired)</div>) : 
                                    (<TextField source={wrapperKey} record={wrapperCopy} {...rest} />)
                            : 
                                (!record.coupon_id? null : linkToCouponWithId);
              
    return (linkedComponent);   
};

SdkCodeLinkField.defaultProps = {
    addLabel: true
};

export default SdkCodeLinkField;
