import React from 'react';
import dateUtils from '../../../utils/dateUtils'

export default ({ record = {}, source, addPlaceholder }) => {
    let divStyle = {
        display: 'flex',
        verticalAlign: 'bottom'
    };

    let timeToAnswerString = dateUtils.stringFromTimeDuration(record[source]); 
    
    if(!timeToAnswerString) {
        if(addPlaceholder) {
            timeToAnswerString = "00:00:00";
        } else {
            timeToAnswerString = "n/a"
        }
    }

    return (<div style={divStyle}>
        <span>{timeToAnswerString}</span>
    </div>);
};