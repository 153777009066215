export let codesFields = {
    id: "id",
    organizationId: "organization_id",
    codeName: "code_name",
    planId: "plan_id",
    planName: "plan_name",
    enabled: "enabled",
    createdAt: "created_at",
    activatedAt: "activated_at",
    userId: "user_id",
    userFirstName: "user_first_name",
    userLastName: "user_last_name",
    userEmail: "user_email"
};
