import React from 'react';
import CodeIcon from '@material-ui/icons/Code';
import { codesFields } from '../../codes.model';

export default ({ record = {} }) => {
    //let divStyle = {
        //display: 'flex',
        //verticalAlign: 'bottom',
    //};
    let codeId = record[codesFields.id ] || 'n/a';
    return (<div>
        <CodeIcon style={{verticalAlign: 'middle'}}/>
        <span style={{verticalAlign: 'middle'}}>&nbsp;{`Organization code # ${codeId}`}</span>
    </div>);
};
