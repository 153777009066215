import React from 'react';
import { callsFields, EndCallReasonNames } from '../../calls.model';

export default ({ record = {} }) => {
    let divStyle = {
        display: 'flex',
        verticalAlign: 'bottom'
    };
    // console.log("providerEndReason: ", record[callsFields.providerEndReason]);
    let endReason = EndCallReasonNames[record[callsFields.providerEndReason]];

    if (!endReason) {return 'n/a'}
    return (<div style={divStyle}>
        <span>{endReason}</span>
    </div>);
};
