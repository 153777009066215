import React from 'react';
import {
    Show, 
    SimpleShowLayout,
    TextField,
    DateField,
    CardActions
} from 'react-admin';
import CallTitle from './fields/callTitle.component';
import CallStatus from './fields/callStatus.component';
import CallProvChartShow from './fields/callProvChartShow.component';
import PatientLinkField from './fields/patientLink.component';
import ProviderLinkField from './fields/providerLink.component';
import YesNoField from '../../material/yesNoField.component';
import CallTimeLabel from './fields/callTimeLabel.component';
import ProviderEndReason from './fields/providerEndReason.component';
import PatientEndReason from './fields/patientEndReason.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import { callsFields } from '../calls.model';
import Button from '@material-ui/core/Button';
import { DATE_TIME_OPTIONS } from '../../app/app.component';

const CallShowActions = (props) => {
  const actionsStyle = {
      zIndex: 2,
      display: 'inline-block',
      float: 'right',
  };
  return (
      <CardActions style={actionsStyle}>
        <Button color="primary" onClick={editChartAction}>Edit Chart</Button>
      </CardActions>
  );
};

function editChartAction() {
  window.location='editchart';
}

const CallShow = ({ ...props }) => {
    window.scrollTo(0, 0);
    return (
        <Show title={<CallTitle style={{verticalAlign: 'middle'}}/>} actions={<CallShowActions />} {...props}>
            <SimpleShowLayout>
                <DefaultPlaceholderField source={callsFields.id} wrapField={<TextField/>} label="Id" addLabel  />
                <DefaultPlaceholderField source={callsFields.groupName} wrapField={<TextField/>}  label="Type" addLabel/>
                <DefaultPlaceholderField source={callsFields.lang} wrapField={<TextField/>}  label="Lang" addLabel/>
                <CallStatus source={callsFields.status} wrapField={<TextField/>}  label="Status" addLabel/>
                <CallProvChartShow label="Provider Chart" addLabel/>
                <YesNoField source={callsFields.nonClinical} label="No Clinical Content" addLabel />
                <PatientLinkField label="Patient" addLabel/>
                <DefaultPlaceholderField source={callsFields.patientRating} wrapField={<TextField/>}  label="Patient Rating" addLabel/>
                <DefaultPlaceholderField source={callsFields.patientFeedback} wrapField={<TextField/>}  label="Patient Feedback" addLabel/>
                <ProviderLinkField label="Provider" addLabel/>
                <DefaultPlaceholderField source={callsFields.providerRating} wrapField={<TextField/>}  label="Provider Rating" addLabel/>
                <DefaultPlaceholderField source={callsFields.providerFeedback} wrapField={<TextField/>}  label="Provider Feedback" addLabel/>
                <DefaultPlaceholderField source={callsFields.callStartedAt}  options={DATE_TIME_OPTIONS} wrapField={<DateField/>}   label="Started At" addLabel/>
                <DefaultPlaceholderField source={callsFields.acceptedAt} wrapField={<DateField/>} options={DATE_TIME_OPTIONS}  label="Accepted At" addLabel/>
                <DefaultPlaceholderField source={callsFields.endedAt} wrapField={<DateField/>} options={DATE_TIME_OPTIONS}  label="Ended At" addLabel/>
                <CallTimeLabel source={callsFields.timeToAnswer} label="Time to Answer"  addLabel/>
                <CallTimeLabel source={callsFields.timeToCancel} label="Time to Cancel"  addLabel/>
                <DefaultPlaceholderField source={callsFields.service} wrapField={<TextField/>}  label="Service" addLabel/>
                <ProviderEndReason label="Provider End Reason" addLabel/>
                <PatientEndReason label="Patient End Reason" addLabel/>
                <YesNoField source={callsFields.introCall }   label="Intro Call" addLabel />
            </SimpleShowLayout>
        </Show>
    );
};

export default CallShow;
