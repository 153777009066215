import React from 'react';

const divStyle = {
    width: 400,
    height: 50
};

export default ({ source, record = {} }) => {
    if(!record[source]) {
        return <div style={divStyle}>{""}</div>
    } else {
        return (
            <div style={divStyle}>
                <audio src={record[source]} controls />
            </div>
        );
    }
}