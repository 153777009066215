import React from 'react';
import { Link, TextField } from 'react-admin';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../../organizations/organizations.dataProvider';
import { userFields } from '../../user.model';
import { TableRow, TableCell } from '@material-ui/core';


const OrganizationLinkField = props => {
    if (!props.record[userFields.orgName]) {return 'n/a'}
    return (<Link to={`/${ORGANIZATIONS_RESOURCE}/${props.record[userFields.orgId]}/show`}>
        <TextField source={userFields.orgName} style={{ color: 'blue' }} {...props}/>
    </Link>);
}

OrganizationLinkField.defaultProps = {
    addLabel: true
};

export const OrganizationLinkTableRow = ({ label, record = {} }) => {
    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            <TableCell>
                <OrganizationLinkField record={record}/>
            </TableCell>
        </TableRow>
    );
};


export default OrganizationLinkField;
