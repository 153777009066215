import React from 'react';
import {
    Create,
    SimpleForm,
    SelectInput, 
    BooleanInput,
    TextInput,
    required,
    Toolbar
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { versionsFields } from '../versions.model';
import SaveButtonValidated from "../../material/saveButtonValidated.component"

export const styles = {
    version: { width: 544 },
    build: { maxWidth: 544 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButtonValidated />
    </Toolbar>
);

const VersionCreate = ({ classes, ...props }) => (
    <Create {...props}>
        <SimpleForm redirect = "list" toolbar={<PostEditToolbar/>}>
                <SelectInput 
                    label="Platform" 
                    source={versionsFields.platform}
                    validate={required()}
                    choices={[
                        { id:1, name: "iOS" },
                        { id:2, name: "Android" }
                    ]}/>

                <SelectInput 
                    label="App type" 
                    source={versionsFields.appType}
                    validate={required()}
                    choices={[
                        { id:1, name: "Patient" },
                        { id:2, name: "Provider" }
                    ]}/>                    

                <TextInput
                    autoFocus
                    multiline
                    label="Version"
                    source={versionsFields.version} 
                    formClassName={classes.version}
                    validate={required()} 
                />

                <BooleanInput  
                    label="Force update" 
                    source={versionsFields.forceUpdate} />

                <TextInput
                    autoFocus
                    multiline
                    label="Build"
                    source={versionsFields.build} 
                    formClassName={classes.build}
                    validate={required()} />    

                <TextInput
                    autoFocus
                    multiline
                    label="Release notes"
                    source={versionsFields.releaseNotes} 
                    formClassName={classes.version} />
        </SimpleForm>
    </Create>
);

export default withStyles(styles)(VersionCreate);
