import React from "react";
import { Button, Select, Dialog, MenuItem, Input, DialogTitle, DialogContent, FormControl, InputLabel, DialogActions, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 'inherit',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginRight: 20,
  },
}));

const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const YEARS = [2023, 2024];

const DataDialog = ({open, closeDataDialog, dataMonth, handleChangeDataMonth, dataYear, handleChangeDataYear}) => {
  const classes = useStyles();

  return <Dialog className={classes.dialog} open={open} onClose={closeDataDialog}>
    <DialogTitle>Choose Month and Year of Loading Data</DialogTitle>
    <DialogContent>
      <form className={classes.container}>
        <FormControl className={classes.formControl}>
          <InputLabel id="dialog-select-month-label">Month</InputLabel>
          <Select
            labelId="dialog-select-month-label"
            value={dataMonth}
            onChange={handleChangeDataMonth}
            input={<Input />}
          >
            {MONTHS.map((item) => <MenuItem value={item} key={item}>{item}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="dialog-select-year-label">Year</InputLabel>
          <Select
            labelId="dialog-select-year-label"
            value={dataYear}
            onChange={handleChangeDataYear}
            input={<Input />}
          >
            {YEARS.map((item) => <MenuItem value={item} key={item}>{item}</MenuItem>)}
          </Select>
        </FormControl>
      </form>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDataDialog} color="primary">
        Ok
      </Button>
    </DialogActions>
  </Dialog>;
};

export default DataDialog;