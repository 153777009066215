import React from 'react';
import {
    Edit, 
    SimpleForm,
    TextInput,
    Toolbar,
    ReferenceInput,
    SelectInput,
    required,
    AutocompleteInput
} from 'react-admin';
import CodeTitle from './fields/codeTitle.component';
import { codesFields, CodeTypeChoices } from '../codes.model';
import { orgsFields } from '../../organizations/orgs.model';
import { ALL_RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import SaveButtonValidated from "../../material/saveButtonValidated.component"
import ProviderRoleOrderedList from '../../app/providerRoleOrderedList.component';

const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButtonValidated />
    </Toolbar>
);

const CodeEdit = props => (
    <Edit title={<CodeTitle style={{verticalAlign: 'middle'} }/>} {...props}>
        <SimpleForm redirect = "show"  submitOnEnter={false} toolbar={<PostEditToolbar/>}>            
            <TextInput source={codesFields.codeName} label='Code Name' validate={required()} />
            <ReferenceInput source={codesFields.orgId} 
                reference={ORGANIZATIONS_RESOURCE} validate={required()} label="Organization">
                <AutocompleteInput optionText={orgsFields.orgName} validate={required()}/>
            </ReferenceInput>            
            <SelectInput 
                        label="Code Type" 
                        source={codesFields.codeType}                    
                        validate={required()}  
                        choices={CodeTypeChoices}/>                        
            <ProviderRoleOrderedList {...props} rolesFieldName={codesFields.roleIds} />
        </SimpleForm>
    </Edit>
);

export default CodeEdit;
