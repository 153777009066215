export let callsFields = {
    //export, list, show, filter
    id: "id",
    //export, list, show, filter
    patientId: "patient_id",
    //export, list, show, filter
    patientFirstName: "patient_first_name",
    //export, list, show, filter
    patientLastName: "patient_last_name",
    //export
    patientDob: "patient_dob",
    //export
    patientPhone: "patient_phone",
    //export
    patientZip: "patient_zip", 
    //export
    patientRace: "patient_race",
    //export
    patientCreatedAt: "patient_created_at",
    //export
    daysSinceAccountCreation: "days_since_account_creation",
    //export
    patientOrgName: "patient_org_name",
    //export
    orgCodeNames: "org_code_names",
    //export
    giftCodes: "gift_codes",
    //list, show, filter
    groupId: "groupId",
    //export
    groupName: "group_name",
    //export, list, show, filter
    callStartedAt: "call_started_at",
    //export, list, show
    acceptedAt: "accepted_at",
    //export, list, show, filter
    endedAt: "ended_at",
    //export, list, show
    patientRating: "patient_rating",
    //export, show
    patientFeedback: "patient_feedback",
    //export
    patientCallStats: "patient_call_stats",
    //export, list, show, filter
    providerFirstName: "provider_first_name",
    //export, list, show, filter
    providerLastName: "provider_last_name",
    //export, list, show
    providerRating: "provider_rating",
    //export, list, show
    providerFeedback: "provider_feedback",    
    //export
    providerCallStats: "provider_call_stats",
    //export
//    providerChartFollowUp: "provider_chart_follow_up",
    //export
//    providerChartRecommendations: "provider_chart_recommendations",
    //export
//    providerChartComplaint: "provider_chart_complaint",
    //list
    providerChartType: "provider_chart_type",
    //export
    shareFeedback: "share_feedback",
    //export
    pushEnabled: "push_enabled",
    //export
    overnightCall: "over_night",
    //export, list, show, filter
    status: "status",
    //export, list, show
    providerEndReason: "provider_end_reason",
    //export, list, show
    patientEndReason: "patient_end_reason",
    //export
    callCompleted: "call_completed",
    //export
    callLength: "call_length",
    //export
    billableTime: "billable_time",
    //export, list, show, filter
    nonClinical: "non_clinical",
    //export
    demoCall: "demo_call",
    //export, list, show
    lang: "lang",
    //export, list, show
    service: "service",
    //export
    timeToAnswer: "time_to_answer",
    //export
    timeToCancel: "time_to_cancel",
    //list, show
    providerId: "provider_id",
    //filter
    patientOrgId: "patient_org_id",
    //probably remove later, list
    phi: "phi",
    //callType: "call_type",
    //export, list, show
    introCall: "intro_call",
};

export const endCallReason = {
    // # Call End Reason codes
    // # Local participant pressed Hang up button after both participants joined conversation.
    HANGUP: 1,
    // # Local participant joined conversation, but remote participant never connected before time out
    NO_PARTICIPANT: 2,
    // # Local participant canceled call before remote participant connected
    CANCELED: 3,
    // # Remote participant was connected but left conversation during chat.
    PARTICIPANT_LEFT: 4,
    // # Local participant failed to connect video server before time out elapsed.
    TIME_OUT_BEFORE_JOINED: 5,
    // # Local participant failed due to error before both participants connected
    ERROR_BEFORE_CHAT: 6,
    // # Local participant failed due to poor network before both participants connected
    POOR_NETWORK_BEFORE_CHAT: 7,
    // # Local participant failed due to app termination before both participants connected
    TERMINATION_BEFORE_CHAT: 8,
    // # Local participant unexpectedly left conversation due to error after both participants connected
    ERROR_IN_CHAT: 9,
    // # Local participant unexpectedly left conversation due to poor network after both participants connected
    POOR_NETWORK_IN_CHAT: 10,
    // # Local participant unexpectedly left conversation after both participants connected due to application termination
    TERMINATION_IN_CHAT: 11,
    // # This reason is set by server to requested or accepted calls in case patient requested another call
    CONCURRENT_CALL: 254,
    // # This reason is set by server in case requested status stays (is not changed for) more than 3 min (4 minutes currently)
    NOT_REPORTED: 255
}



export const callStatus = {
    REQUESTED: 1,
    ACCEPTED: 2,
    CANCELED: 3,
    COMPLETED: 4,
    MISSED: 5,
    ERROR: 6
}

export const CallStatusNames = {
    [callStatus.REQUESTED]: "Requested",
    [callStatus.ACCEPTED]: "Accepted",
    [callStatus.CANCELED]: "Canceled",
    [callStatus.COMPLETED]: "Completed",
    [callStatus.MISSED]: "Missed",
    [callStatus.ERROR]: "Error",
}

export const EndCallReasonNames = {
    [endCallReason.HANGUP]: "hangup",
    [endCallReason.NO_PARTICIPANT]: "no_participant",
    [endCallReason.CANCELED]: "canceled",
    [endCallReason.PARTICIPANT_LEFT]: "participant_left",
    [endCallReason.TIME_OUT_BEFORE_JOINED]: "time_out_before_joined",
    [endCallReason.ERROR_BEFORE_CHAT]: "error_before_chat",
    [endCallReason.POOR_NETWORK_BEFORE_CHAT]: "poor_network_before_chat",
    [endCallReason.TERMINATION_BEFORE_CHAT]: "termination_before_chat",
    [endCallReason.ERROR_IN_CHAT]: "error_in_chat",
    [endCallReason.POOR_NETWORK_IN_CHAT]: "poor_network_in_chat",
    [endCallReason.TERMINATION_IN_CHAT]: "poor_network_in_chat",
    [endCallReason.CONCURRENT_CALL]: "concurrent_call",
    [endCallReason.NOT_REPORTED]: "not_reported"
}

export const roles = {
    IBCLC_RD_USER_ROLE: 7,
    IBCLC_RD_ROLES: [3, 4],
    PATIENT_ROLE: 1,
    NURSE_ROLE: 2,
    DIETITIAN_ROLE: 3,
    LC_ROLE: 4,
    PHYSICIAN_ROLE: 5,
    SUPERADMIN_ROLE: 6,
    SDK_ORG_ADMIN: 8,
    /*Cherokee roles*/
    BEHAVIORAL_HEALTH_CRISIS: 9,
    LIFELINE_RECOVERY: 10,
    RELAPSE_PREVENTION: 11,
    SCHOOL_BASED_THERAPY_ROLE: 12,
    HOTLINE_PATIENT_ROLE: 13
}

export const callProviderChartType = {
  NO_CHART: 1,
  DEFAULT_CHART: 2,
  BIRTH_DOULA_CHART: 3,
  POST_PARTUM_DOULA_CHART: 4,
}

export const CallProviderChartTypeNames = {
  [callProviderChartType.NO_CHART]: "No Chart",
  [callProviderChartType.DEFAULT_CHART]: "Default Chart",
  [callProviderChartType.BIRTH_DOULA_CHART]: "BirthDoula Chart",
  [callProviderChartType.POST_PARTUM_DOULA_CHART]: "PostPartumDoula Chart",
}

export const callService = {
  TWILIO: "twilio",
  TWILIO_ROOM: "twilio_room",
  TWILIO_PHONE: "twilio_phone",
  TWILIO_CALLBACK: "twilio_callback",
  OOVOO: "oovoo",
}

export const CallServiceNames = {
  [callService.TWILIO]: "Twilio",
  [callService.TWILIO_ROOM]: "Twilio Room",
  [callService.TWILIO_PHONE]: "Twilio Phone",
  [callService.TWILIO_CALLBACK]: "Twillio Callback",
  [callService.OOVOO]: "oovoo",
}
