import React from 'react';
import {
    Show, 
    SimpleShowLayout,
    TextField,
    DateField,
    Labeled
} from 'react-admin';
import CodeTitle from './fields/codeTitle.component';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';
import { codesFields } from '../codes.model';
import CodeRedeemed from './fields/codeRedeemed.component';
import { DATE_OPTIONS } from '../../../app/app.component';
import UserLinkField from './fields/userLink.component';
import InvalidatedField from './fields/invalidatedField.component';
import InvalidateButton from './invalidateButton.component';


const CodeShow = ({ ...props }) => {
    return (
        <Show title={<CodeTitle />} {...props}>
            <SimpleShowLayout>
                <DefaultPlaceholderField source={codesFields.codeName} wrapField={<TextField/>}  label="Name" addLabel/>
                <DefaultPlaceholderField source={codesFields.planName} wrapField={<TextField/>}  label="Plan Code" addLabel/>
                <DefaultPlaceholderField source={codesFields.createdAt} wrapField={<DateField/>} options={DATE_OPTIONS} label="Date Created" sortable={false} addLabel/>
                <InvalidatedField source={codesFields.enabled} label="Invalidated" addLabel />
                <CodeRedeemed source={codesFields.activatedAt} label="Redeemed" addLabel />
                <DefaultPlaceholderField source={codesFields.activatedAt} wrapField={<DateField/>} options={DATE_OPTIONS} label="Date Redeemed" sortable={false} addLabel/>
                <UserLinkField label="User" addLabel/>
                <Labeled label="Actions">
                    { <InvalidateButton source={codesFields.id} disabled={codesFields.enabled === 0}/> }
                </Labeled>
            </SimpleShowLayout>
        </Show>
    );
};

export default CodeShow;
