export let orgsFields = {
    id: "id",
    orgName: "name",
    orgCreatedAt: "created_at",
    orgUpdatedAt: "updated_at",
    orgNursePhone: "nurse_phone",
    orgSdkApiKey: "sdk_api_key",
    orgSdkAuthenticationEndpoint: "sdk_authentication_endpoint",
    orgSecretKey: "secret_key",
    contentLibraryEnabled: "content_library_enabled",
    medicaidIdRegistrationQuestionEnabled: "medicaid_id_registration_question_enabled",
    roleIds: "role_ids",
    contentLinkIds: "content_link_ids",
    contentLinksEnabled: "content_links_enabled",
    chatEnabled: "chat_enabled",
    clientGroupId: "client_group_id",
    payerId: "payer_id",
    enrollmentCodeRequired: "requires_enrollment_code",
    requiresWicId: "requires_wic_id"
};

