import React, { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error, SimpleForm, Toolbar, useNotify } from 'react-admin';
import CallTitle from './fields/callTitle.component';
import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { RESOURCE_NAME as CALLS_RESOURCE, POST_UPDATE_CHART } from '../../calls/calls.dataProvider';
import { styled, makeStyles } from '@material-ui/core/styles';

const CheckboxCompact = styled(Checkbox)({
  padding: 0,
});

const styles = {
  formStyle: { display: 'inline-block', marginLeft: 32, marginTop: 32 },
  showButtonStyle: { float: 'right' },
  textCellStyle: { width: '100%' },
  tableLabelsStyle: { width: '200px' }
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const validationRules = {
  "doula": {
    required: ['findings', 'recommendation', 'followUp'],
  },
  "postpartum_doula": {
    required: ['findings', 'recommendation', 'followUp'],
  },
  "default": {
    required: ['complaint', 'recommendation', 'followUp'],
  },
};

export default ({ ...props }) => {

  const classes = useStyles();

  const notify = useNotify();

  const dataProvider = useDataProvider();
  const [call, setCall] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [complaint, setComplaint] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [followUp, setFollowUp] = useState("");
  const [birthPlan, setBirthPlan] = useState("n/a");
  const [birthPartner, setBirthPartner] = useState("n/a");
  const [prenatalVisits, setPrenatalVisits] = useState("n/a");
  const [inLabor, setInLabor] = useState("");
  const [postpartumVisits, setPostpartumVisits] = useState("");
  const [pediatricianVisits, setPediatricianVisits] = useState("");
  const [phq2, setPhq2] = useState("");
  const [phq2Referral, setPhq2Referral] = useState("");

  const [chartType, setChartType] = useState("default");
  const [findings, setFindings] = useState();

  useEffect(() => {
    dataProvider.getOne('calls', { id: props.match.params.id })
      .then(({ data }) => {
        setCall(data);
        setLoading(false);
        var call = data;
        if (call.phi != null) {
          var phi = JSON.parse(call.phi);
          if (phi != null) {
            if (phi.feedbackFormData != null) {
              if (phi.feedbackFormData.complaint != null) {
                setComplaint(phi.feedbackFormData.complaint);
              }
              if (phi.feedbackFormData.recommendations != null) {
                setRecommendation(phi.feedbackFormData.recommendations);
              }
              if (phi.feedbackFormData.follow_up != null) {
                setFollowUp(phi.feedbackFormData.follow_up);
              }
              if (phi.feedbackFormData.findings != null) {
                setFindings(phi.feedbackFormData.findings)
              }
              if (phi.feedbackFormData.birth_plan) {
                setBirthPlan(phi.feedbackFormData.birth_plan)
              }
              if (phi.feedbackFormData.birth_partner) {
                setBirthPartner(phi.feedbackFormData.birth_partner)
              }
              if (phi.feedbackFormData.prenatal_visit) {
                setPrenatalVisits(phi.feedbackFormData.prenatal_visit)
              }
              if (phi.feedbackFormData.in_labor != null) {
                setInLabor(phi.feedbackFormData.in_labor)
              }
              if (phi.feedbackFormData.postpartum_visits != null) {
                setPostpartumVisits(phi.feedbackFormData.postpartum_visits)
              }
              if (phi.feedbackFormData.pediatrician_visits != null) {
                setPediatricianVisits(phi.feedbackFormData.pediatrician_visits)
              }
              if (phi.feedbackFormData.phq2 != null) {
                setPhq2(phi.feedbackFormData.phq2)
              }
              if (phi.feedbackFormData.phq2_referral != null) {
                setPhq2Referral(phi.feedbackFormData.phq2_referral)
              }
                  /**        
        "phq2": "no",
        "phq2_referral": "no",
        "postpartum_visits": "n/a",
        "pediatrician_visits": "some"
 */

            }
            if (phi.type != null) {
              setChartType(phi.type)
            } else {
              setChartType("default")
            }
          }
        }
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, [dataProvider, props.match.params.id]);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!call) return null;

  const inLaborHandler = e => {
    setInLabor(e.target.checked ? "yes" : "no")
    if (e.target.checked) {
      setBirthPlan("n/a");
      setBirthPartner("n/a");
      setPrenatalVisits("n/a");
    }
  }
  
  const onSubmit = () => {
    if (validateChart(
      chartType,
      {
        chartType,
        complaint,
        recommendation,
        followUp,
        findings,
        birthPlan,
        birthPartner,
        prenatalVisits,
        inLabor,
        postpartumVisits,
        pediatricianVisits,
        phq2,
        phq2Referral
      }
    )) {
      postUpdateChart(
        chartType,
        dataProvider,
        call.id,
        complaint,
        recommendation,
        followUp,
        findings,
        birthPlan,
        birthPartner,
        prenatalVisits,
        inLabor,
        postpartumVisits,
        pediatricianVisits,
        phq2,
        phq2Referral
      );
    } else {
      notify("The form is not valid. Please fill the required fields.", { type: "error" });
    }
  };
  return (
    <div style={styles.formStyle}>
      <Button variant="contained" style={styles.showButtonStyle} onClick={() => { window.location='show'; }}>
        Show
      </Button>
      <CallTitle record={call} />
      <SimpleForm submitOnEnter={false} toolbar={
        <Toolbar>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Update Chart
          </Button>
        </Toolbar>
      }>
        { chartType === "default" && (
          <Table className={classes.table} >
            <TableBody>
              <TableRow>
                <TableCell style={styles.tableLabelsStyle}>Chief Complaint/History *</TableCell>
                <TableCell>
                  <TextField style={styles.textCellStyle} multiline value={complaint} onChange={e => setComplaint(e.target.value)} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableLabelsStyle}>Recommendations/Plan *</TableCell>
                <TableCell>
                  <TextField style={styles.textCellStyle} multiline value={recommendation} onChange={e => setRecommendation(e.target.value)} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableLabelsStyle}>Immediate Follow-Up Required</TableCell>
                <TableCell><CheckboxCompact size="small" checked={followUp === "yes" ? true : false} onChange={e => setFollowUp(e.target.checked ? "yes" : "no")} /></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        { chartType === "doula" && (
          <Table size="small" className={classes.table} >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Yes</TableCell>
                <TableCell>Some</TableCell>
                <TableCell>No</TableCell>
                <TableCell>N/A</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={styles.tableLabelsStyle} >Birth Wish List</TableCell>
                <TableCell><CheckboxCompact size="small" checked={birthPlan === "yes" ? true : false} onChange={() => setBirthPlan("yes")} disabled={inLabor === "yes"} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={birthPlan === "some" ? true : false} onChange={() => setBirthPlan("some")} disabled={inLabor === "yes"} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={birthPlan === "no" ? true : false} onChange={() => setBirthPlan("no")} disabled={inLabor === "yes"} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={birthPlan === "n/a" ? true : false} onChange={() => setBirthPlan("n/a")} disabled={inLabor === "yes"} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableLabelsStyle} >Birth Partner</TableCell>
                <TableCell><CheckboxCompact size="small" checked={birthPartner === "yes" ? true : false} onChange={() => setBirthPartner("yes")} disabled={inLabor === "yes"} /></TableCell>
                <TableCell></TableCell>
                <TableCell><CheckboxCompact size="small" checked={birthPartner === "no" ? true : false} onChange={() => setBirthPartner("no")} disabled={inLabor === "yes"} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={birthPartner === "n/a" ? true : false} onChange={() => setBirthPartner("n/a")} disabled={inLabor === "yes"} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableLabelsStyle} >Prenatal Visits</TableCell>
                <TableCell><CheckboxCompact size="small" checked={prenatalVisits === "yes" ? true : false} onChange={() => setPrenatalVisits("yes")} disabled={inLabor === "yes"} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={prenatalVisits === "some" ? true : false} onChange={() => setPrenatalVisits("some")} disabled={inLabor === "yes"} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={prenatalVisits === "no" ? true : false} onChange={() => setPrenatalVisits("no")} disabled={inLabor === "yes"} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={prenatalVisits === "n/a" ? true : false} onChange={() => setPrenatalVisits("n/a")} disabled={inLabor === "yes"} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableLabelsStyle} >IN LABOR</TableCell>
                <TableCell><CheckboxCompact size="small" checked={inLabor === "yes" ? true : false} onChange={inLaborHandler} /></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableLabelsStyle} >Findings *</TableCell>
                <TableCell colSpan="4">
                  <TextField style={styles.textCellStyle} multiline value={findings} onChange={e => setFindings(e.target.value)} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableLabelsStyle} >Recommendations/Plan *</TableCell>
                <TableCell colSpan="4">
                  <TextField style={styles.textCellStyle} multiline value={recommendation} onChange={e => setRecommendation(e.target.value)} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableLabelsStyle} >Immediate Follow-Up Required</TableCell>
                <TableCell colSpan="4"><CheckboxCompact size="small" checked={followUp === "yes" ? true : false} onChange={e => setFollowUp(e.target.checked ? "yes" : "no")} /></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        { chartType === "postpartum_doula" && (
          <Table size="small" className={classes.table} >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Yes</TableCell>
                <TableCell>Some</TableCell>
                <TableCell>No</TableCell>
                <TableCell>N/A</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Postpartum Visits</TableCell>
                <TableCell><CheckboxCompact size="small" checked={postpartumVisits === "yes" ? true : false} onChange={() => setPostpartumVisits("yes")} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={postpartumVisits === "some" ? true : false} onChange={() => setPostpartumVisits("some")} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={postpartumVisits === "no" ? true : false} onChange={() => setPostpartumVisits("no")} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={postpartumVisits === "n/a" ? true : false} onChange={() => setPostpartumVisits("n/a")} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pediatrician Visits</TableCell>
                <TableCell><CheckboxCompact size="small" checked={pediatricianVisits === "yes" ? true : false} onChange={() => setPediatricianVisits("yes")} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={pediatricianVisits === "some" ? true : false} onChange={() => setPediatricianVisits("some")} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={pediatricianVisits === "no" ? true : false} onChange={() => setPediatricianVisits("no")} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={pediatricianVisits === "n/a" ? true : false} onChange={() => setPediatricianVisits("n/a")} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PHQ-2</TableCell>
                <TableCell><CheckboxCompact size="small" checked={phq2 === "yes" ? true : false} onChange={() => setPhq2("yes")} /></TableCell>
                <TableCell></TableCell>
                <TableCell><CheckboxCompact size="small" checked={phq2 === "no" ? true : false} onChange={() => setPhq2("no")} /></TableCell>
                <TableCell><CheckboxCompact size="small" checked={phq2 === "n/a" ? true : false} onChange={() => setPhq2("n/a")} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Referred for PHQ-2</TableCell>
                <TableCell><CheckboxCompact size="small" checked={phq2Referral === "yes" ? true : false} onChange={e => setPhq2Referral(e.target.checked ? "yes" : "no")} /></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Findings *</TableCell>
                <TableCell colSpan="4">
                  <TextField style={styles.textCellStyle} multiline value={findings} onChange={e => setFindings(e.target.value)} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Recommendations/Plan *</TableCell>
                <TableCell colSpan="4">
                  <TextField style={styles.textCellStyle} multiline value={recommendation} onChange={e => setRecommendation(e.target.value)} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Immediate Follow-Up Required</TableCell>
                <TableCell colSpan="3"><CheckboxCompact size="small" checked={followUp === "yes" ? true : false} onChange={e => setFollowUp(e.target.checked ? "yes" : "no")} /></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </SimpleForm>
    </div>
  );
}

const validateChart = (chartType, values) => {
  const currentValidationRules = validationRules[chartType];

  for (const field of currentValidationRules.required) {
    if (!values[field] || /^\s*$/.test(values[field])) {
      return false;
    }
  }

  return true;
}

function postUpdateChart(chartType, dataProvider, callId, complaint, recommendation, followUp, findings, birthPlan, birthPartner, prenatalVisits, inLabor, postpartumVisits, pediatricianVisits, phq2, phq2Referral) {
  let requestBody = {
    id: callId,
    chartType: chartType,
    complaint: complaint != null ? complaint : "",
    recommendation: recommendation != null ? recommendation : "",
    followUp: followUp != null ? followUp : ""
  }
  if(chartType === "doula") {
    requestBody = {
      id: callId,
      chartType: chartType,
      birthPlan: birthPlan != null ? birthPlan : "",
      birthPartner: birthPartner != null ? birthPartner : "",
      prenatalVisits: prenatalVisits != null ? prenatalVisits : "",
      inLabor: inLabor != null ? inLabor : "",
      recommendation: recommendation != null ? recommendation : "",
      followUp: followUp != null ? followUp : "",
      findings: findings != null ? findings : ""
    }
  }

  if(chartType === "postpartum_doula") {
    requestBody = {
      id: callId,
      chartType: chartType,
      postpartumVisits: postpartumVisits != null ? postpartumVisits : "",
      pediatricianVisits: pediatricianVisits != null ? pediatricianVisits : "",
      phq2: phq2 != null ? phq2 : "",
      phq2Referral: phq2Referral != null ? phq2Referral : "",
      recommendation: recommendation != null ? recommendation : "",
      followUp: followUp != null ? followUp : "",
      findings: findings != null ? findings : ""
    }
  }
  
  dataProvider(POST_UPDATE_CHART, CALLS_RESOURCE, requestBody)
    .then(response => {
      // On success just go back to 'show' screen for the record.
      window.location='show';
    })
    .catch(error => {
      if (error.message != null) {
        alert(JSON.stringify(error.message));
      }
      else {
        alert("An error occurred: " + JSON.stringify(error));
      }
    });
};