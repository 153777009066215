import {
  GET_LIST,
  GET_MANY,
} from 'react-admin';
import { stringify } from 'query-string';

const API_URL = process.env.REACT_APP_API_URL;

export const RESOURCE_NAME = "charts";

const providerRequestToHttpRequest = (requestType, requestParams) => {
  switch (requestType) {
      case GET_LIST: 
          return { url: `${API_URL}/${RESOURCE_NAME}` };
      case GET_MANY: {
          const query = {
              filter: JSON.stringify({ id: requestParams.ids }),
          };
          return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}` };
      }
      default:
          throw new Error(`Unsupported fetch action type for "charts" resource ${requestType}`);
  }
};

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
  let { headers, json } = httpResponse;
  switch (requestType) {
      case GET_LIST:
          return {
              data: json,
              total: parseInt(headers.get('content-range').split('/').pop()),
          };
      case GET_MANY:
          let chartIdsToGet = requestParams.ids;
          return {
              data: json.filter(chartItem => chartIdsToGet.includes(chartItem.id))
          };
      default:
          throw new Error(`Unsupported fetch action type ${requestType}`);
  }
};

export default {
  resource: RESOURCE_NAME,
  providerInterface: {
      providerRequestToHttpRequest,
      httpResponseToProviderData
  },
};
