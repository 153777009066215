import React from 'react';
import { userFields, userProviderRoleFields } from '../../user.model';
import {ArrayField, SingleFieldList, Link, TextField, ChipField } from 'react-admin';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';
import { RESOURCE_NAME as ROLES_RESOURCE_NAME } from '../../../roles/roles.dataProvider';

const ProviderRoleLinkField = props => {
  if (!props.record[userProviderRoleFields.name]) {return 'n/a'}
  return (
  <Link to={`/${ROLES_RESOURCE_NAME}/${props.record[userProviderRoleFields.id]}/show`} style={{ padding: 5 }}>
      <ChipField source={userProviderRoleFields.name} style={{ color: 'blue' }} {...props}/>
  </Link>
  )
};


export default (props) => {
  var roleValue = props.record[userFields.roleName];   
  if (roleValue === "Provider") {
    return (
      <div>
        <div style={{ marginBottom:5 }} >Provider:</div>  
        <ArrayField source={userFields.providerRoles} {...props}>        
          <SingleFieldList linkType={true}>                            
              <ProviderRoleLinkField/>                                
          </SingleFieldList>
        </ArrayField>     
      </div>
    ); 
  }
  else {
    return (
      <DefaultPlaceholderField source={userFields.roleName}  wrapField={<TextField/>} {...props} addLabel />
    );
  }  
};

