import React, { Component } from 'react';
import CustomDatePicker from "./customDatePicker.js"

export default class PeriodInput extends Component {
  render() {
    const styles = {
      row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
      },
    };

    return (
      <span style={styles.row}>
        <CustomDatePicker source={`${this.props.source}.gte`} label={this.props.fromLabel} style={styles.row} useStartOfDay={true} />
        &nbsp;
        <CustomDatePicker source={`${this.props.source}.lte`} label={this.props.toLabel} style={styles.row} useEndOfDay={true}/>
      </span>
    );
  }
}