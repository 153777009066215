import React from 'react';
import MembershipCodeIcon from '@material-ui/icons/CardMembership';
import { codesFields } from '../../codes.model';

export default ({ record = {} }) => {
    let divStyle = {
        display: 'flex',
        verticalAlign: 'bottom',
    };
    let codeId = record[codesFields.id ] || '';
    return (<div style={divStyle}>
        <MembershipCodeIcon />
        <span>&nbsp;{`Organization code # ${codeId}`}</span>
    </div>);
};
