import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import auth0 from './auth/auth0';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AuthCallbackComponent from './auth/authCallback.component';
import { createBrowserHistory as createHistory } from 'history';

const rootHistory = createHistory();
export { rootHistory };
class RootComponent extends Component {
    state = {};

    updateRole = (role) => {
        this.setState({ role: role });
    };
    render() {
        let routerBody = auth0.getPacifyRole() ? 
             (
                <Router>
                    <div>
                        <Route path="/" render={(props) => {
                            return auth0.handleRoute();
                        }} />                
                    </div>
                </Router>
            ) :
            (
                <Router>
                    <div>
                        <Switch>
                            <Route exact path="/callback" noLayout render={(props) => {
                                setTimeout(auth0.handleAuthentication(this.updateRole), 0);
                                return <AuthCallbackComponent {...props} /> 
                            }}/>  
                            <Route render={(props) => {
                                return <AppLauncher role={auth0.getPacifyRole()}/>
                            }} />    
                        </Switch>       
                    </div>
               </Router>
            )

        return routerBody;
    }
};


const AppLauncher = (props = {}) => {
    return props.role ? auth0.handleRoute() : auth0.login();
};

ReactDOM.render( 
    <RootComponent />, document.getElementById('root'));

serviceWorkerRegistration.register();
