import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput, 
    FormDataConsumer,
    Toolbar,
    required,
    ReferenceInput  
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { rolesFields, CallLineType } from '../roles.model';
import { validatePhone, validateHexColor, validateUrl } from '../../utils/validation.js';
import SaveButtonValidated from "../../material/saveButtonValidated.component";
import { RESOURCE_NAME as CHARTS_RESOURCE } from '../../charts/charts.dataProvider';


export const styles = {
    roleName: { display: 'inline-block' },
    lineType: { display: 'inline-block', marginLeft: 32 }
};

const validateCreation = (values) => {
    const errors = {};
    
    let lineType = values[rolesFields.lineType];
    let isPhoneType = lineType === CallLineType.PhoneToPacifyProvider.id || lineType === CallLineType.PhoneNumber.id;
    let phoneNumber = values[rolesFields.phoneNumber]

    let validators = isPhoneType ? [required(), validatePhone()] : [validatePhone(false)]
    for (let validator of validators) {
        let result = validator(phoneNumber)
        if (result === undefined) {
            continue
        }
        errors[rolesFields.phoneNumber] = result
    }

    return errors
};


const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButtonValidated />
    </Toolbar>
);


const RolesCreate = ({ classes, ...props }) => (
    <Create {...props}>
        <SimpleForm redirect = "show" validate={validateCreation} toolbar={<PostEditToolbar/>}>
            <TextInput                    
                    autoFocus
                    label="Name"
                    source={rolesFields.roleName}    
                    formClassName={classes.roleName}                 
                    validate={required()} 
                />
               <SelectInput 
                    label="Line Type" 
                    source={rolesFields.lineType}
                    formClassName={classes.lineType}  
                    validate={required()}  
                    choices={[ 
                        CallLineType.VideoChat,
                        CallLineType.PhoneNumber,        
                        CallLineType.PhoneToPacifyProvider
                    ]}/>
                {/* phone validation handled by `validatedCreation` */}
                <TextInput
                    type="phone"
                    label="Phone Number"
                    source={rolesFields.phoneNumber} 
                />
                <TextInput
                    label="Button Background Color"
                    source={rolesFields.buttonBackgroundColor} 
                    // formClassName={classes.last_name}
                    validate={[required(), validateHexColor()]} 
                />
                <TextInput
                    label="Button Image URL"
                    fullWidth={true}
                    source={rolesFields.buttonImageURL} 
                    // fullWidth={true}
                    // formClassName={classes.address}
                    validate={[required(), validateUrl()]}/>                
                <TextInput
                    multiline
                    fullWidth={true}
                    label="Info Popup Content -- English"
                    source={rolesFields.infoPopupContentEnglish} 
                    // formClassName={classes.city}
                    validate={required()} 
                />
                <TextInput
                    multiline
                    fullWidth={true}
                    label="Info Popup Content -- Spanish"
                    source={rolesFields.infoPopupContentSpanish} 
                    // formClassName={classes.city}
                    validate={required()} 
                />
                <TextInput
                    multiline
                    fullWidth={true}
                    label="Title -- English"
                    source={rolesFields.titleEnglish} 
                    // formClassName={classes.city}
                    validate={required()} 
                />
                <TextInput
                    multiline
                    fullWidth={true}
                    label="Title -- Spanish"
                    source={rolesFields.titleSpanish} 
                    // formClassName={classes.city}
                    validate={required()} 
                />
                <ReferenceInput source={rolesFields.chartId} 
                    reference={CHARTS_RESOURCE} perPage={10000} sort={{ field: "name", order: 'ASC' }} label="Chart Type">
                    <SelectInput optionText={"name"} />
                </ReferenceInput>
        </SimpleForm>
    </Create>

);

export default withStyles(styles)(RolesCreate);
