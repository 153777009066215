import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Button, Select, InputLabel, MenuItem } from '@material-ui/core';
import { Field } from 'react-final-form'

import { useGetList, useGetMany, Loading, Error } from 'react-admin';

const styles = {
  containerStyle: {
    backgroundColor: "#ddeeff",
    padding: "20px"
  },
  selectStyle: {
    width: "250px"
  },
  draggableStyle: {
    backgroundColor: "#ccddee",
    padding: "5px",
    margin: "5px",
    zIndex: "1000"
  }
}

const ProviderRoleOrderedList = props => {

  const [orderedProviderRoles, setOrderedProviderRoles] = useState([]);

  const getListResult = useGetList( // Grab all of the options for the Select dropdown.
    'provider.roles',
    { page: 1, perPage: 1000 },
    { field: 'id', order: 'ASC' },
    {}
  );

  // props.record[props.rolesFieldName] != null && props.record[props.rolesFieldName].length != 0
  const getManyResult = useGetMany( // Grab all of the already selected and ordered items.
    'roles',
    props.record[props.rolesFieldName] != null && props.record[props.rolesFieldName].length != 0 ? props.record[props.rolesFieldName] : [],
    {
      onSuccess: (getManyResult) => {
        const getManyData = Object.values(getManyResult.data)
        console.log("getManyData: ", getManyData);
        let filteredGetManyData = getManyData.map(function (role, index) {
          if (role == undefined) {
            return {
              id: props.record.role_ids[index],
              name: "Deleted Role",
              isDeleted: true,
            }
          }
          return { ...role, isDeleted: false };
        })
        setOrderedProviderRoles(filteredGetManyData);
      }
    }
  );

  if (!(getListResult.loaded && getManyResult.loaded)) { return <Loading />; }
  if (getListResult.error || getManyResult.error) { return <Error />; }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updated = arrayMove(orderedProviderRoles, oldIndex, newIndex)
    setOrderedRoles(updated)
  };

  const shouldCancelStart = (targetDomElement) => {
    if (['input', 'textarea', 'select', 'option'].indexOf(targetDomElement.target.tagName.toLowerCase()) !== -1) { // default code
      return true;
    }
    else if (targetDomElement.target.parentNode != null && targetDomElement.target.parentNode.name === 'deleteButton') { // dont drag delete button taps
      return true;
    }
  }

  const onAddProviderRole = (event, child) => {
    if (!orderedProviderRoles.includes(event.target.value)) {
      setOrderedRoles(orderedProviderRoles.concat(event.target.value))
    }
  }

  const onRemoveProviderRole = (roleToRemove) => {
    setOrderedRoles(orderedProviderRoles.filter(role => role.id !== roleToRemove.id))
  }

  var fieldOnChange; // set with field onChange function.

  const setOrderedRoles = (roles) => {
    setOrderedProviderRoles(roles)
    fieldOnChange(roles.map(role => role.id))
  }

  const SortableItem = SortableElement(({ value }) => {
    return (
      <div style={styles.draggableStyle}>
        <Button variant="contained" color={value.isDeleted ? "default" : "secondary"} onClick={() => { onRemoveProviderRole(value) }} name="deleteButton">
          Remove
        </Button>
        <span style={{ padding: "20px" }}>{value.name}</span>
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} value={value} />
        ))}
      </ul>
    );
  });

  var getListData = Object.values(getListResult.data)

  return (
    <Field name={props.rolesFieldName}>
      {props => {
        fieldOnChange = props.input.onChange; //we use this to update the field value later.
        return (
          <div style={styles.containerStyle}>
            <InputLabel id="all-provider-roles-label">Add a provider role by picking from this list</InputLabel>
            <Select
              labelId="all-provider-roles-label"
              id="all-provider-roles-select"
              children={
                getListData.map(providerRole => {
                  return (
                    <MenuItem value={providerRole}>{providerRole.name}</MenuItem>
                  )
                })
              }
              value=""
              onChange={onAddProviderRole}
              variant='filled'
              style={styles.selectStyle}
            />
            <SortableList items={orderedProviderRoles} onSortEnd={onSortEnd} shouldCancelStart={shouldCancelStart} onFieldChange={props.input.onChange} />
          </div>
        )
      }}
    </Field>
  )
}

export default ProviderRoleOrderedList;
