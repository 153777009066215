import React from 'react';
import { TextField } from 'react-admin';
import { discountCodesFields } from '../../discountCodes.model';


export default (props) => {
    let { record = {}, ...rest } = props;    

    let discount = record[discountCodesFields.discount];
    let discountPercent = record[discountCodesFields.discountPercent];
    let currency = record[discountCodesFields.currency] || "USD";

    const discountText = discount ? discount + " " + currency : discountPercent ? discountPercent + "%" : "n/a";



    let discountStringKey = 'discountStringKey';
    let recordCopy = {
        ...record,
        [discountStringKey]: discountText,
    };
    return (<TextField source={discountStringKey} record={recordCopy} sortable={true} {...rest} />);
};
