import {
    regex    
} from 'react-admin';

export const validatePhone = (required = true, message = "Must be a valid phone number") => {        
    if (!required) {
        return regex(/^$|^\d{10}$/, message);  
    }
    else {
        return regex(/^\d{10}$/, message);  
    }
    
}

// export const validatePhone = (message = "Must be a valid phone number") => {
    
//     return regex(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/, message)
// }

export const validateHexColor = (message = "Must be a valid hex color") => {
    return regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, message)
}

export const validateUrl = (message = "Must be a valid url") => {
    return regex(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/, message)
}

export const validateZip = (message = "Must be a valid ZIP") => {
    return regex(/^[0-9]{5}(?:-[0-9]{4})?$/, message)
}