import React from 'react';
import YesNoField from '../../../material/yesNoField.component';

let style = {
    'font-size': '14px'
};

const ConditionalYesNoField = ({ record, source, showIfFieldExists, ...rest }) => {
    return record && record[showIfFieldExists] !== null
        ? <YesNoField source={source} record={record} {...rest} />
        : <span style={style}>n/a</span>;
}
    

export default ConditionalYesNoField;