import moment from 'moment-timezone';

export default class DateUtils {

    static DefaultTimeZone = "America/Toronto";
    static DefaultDateFormat = 'YYYY-MM-DD';
    static DefaultTimeFormat = 'HH:mm:ss';

    //timestamp is unix timestamp in ms
    static timeStringFromTimestamp = (
        timestamp,
        timeFormat = DateUtils.DefaultTimeFormat,
        zone = DateUtils.DefaultTimeZone,
        emptyValue = "") => {
        return timestamp ? moment.unix(timestamp / 1000).tz(zone).format(timeFormat) : emptyValue;
    }

    //timestamp is unix timestamp in ms
    static dateStringFromTimestamp = (
        timestamp,
        dateFormat = DateUtils.DefaultDateFormat,
        zone = DateUtils.DefaultTimeZone,
        emptyValue = "") => {
        return timestamp ? moment.unix(timestamp / 1000).tz(zone).format(dateFormat) : emptyValue;
    }

    //dateString is in the format "YYYY-MM-DD HH:mm:ss"
    static timeStringFromDate = (
        dateString,
        timeFormat = DateUtils.DefaultTimeFormat,
        zone = DateUtils.DefaultTimeZone,
        emptyValue = ""
    ) => {
        return dateString
            ? moment.tz(dateString, "YYYY-MM-DD HH:mm:ss", zone).format(timeFormat)
            : emptyValue;
    };

    //dateString is in the format "YYYY-MM-DD HH:mm:ss"
    static dateStringFromDate = (
        dateString,
        dateFormat = DateUtils.DefaultDateFormat,
        zone = DateUtils.DefaultTimeZone,
        emptyValue = ""
    ) => {
        return dateString
            ? moment.tz(dateString, "YYYY-MM-DD HH:mm:ss", zone).format(dateFormat)
            : emptyValue;
    };

    // Validate Unix timestamp
    static isValidTimestamp = (timestamp) => {
        // Check if the timestamp is a number
        if (typeof timestamp !== 'number' || isNaN(timestamp)) {
            return false;
        }

        // Check if the timestamp is a positive integer
        if (!Number.isInteger(timestamp) || timestamp < 0) {
            return false;
        }

        return true;
    }

    // Function to handle the date and time formatting logic
    static formatDateTime = (value) => {
        return {
            time: DateUtils.isValidTimestamp(value)
                ? DateUtils.timeStringFromTimestamp(value)
                : DateUtils.timeStringFromDate(value),
            date: DateUtils.isValidTimestamp(value)
                ? DateUtils.dateStringFromTimestamp(value)
                : DateUtils.dateStringFromDate(value)
        };
    };

    static localTimeStringFromIsoDateString(dateString) {
        // Parse the date string into a Date object
        const date = new Date(dateString);

        // Create an Intl.DateTimeFormat object for the desired time zone and format
        const formatter = new Intl.DateTimeFormat('en-GB', {
            timeZone: DateUtils.DefaultTimeZone,
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', second: '2-digit',
            hour12: false
        });

        // Format the date to the local time string
        const parts = formatter.formatToParts(date);

        // Extract the relevant parts
        const formattedDate = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value}-${parts.find(p => p.type === 'day').value} ${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`;

        return formattedDate;
    }

    //timeduration in ms
    static stringFromTimeDuration = (
        timeDuration,
        emptyValue = "",
        timeFormat = DateUtils.DefaultTimeFormat) => {
        if (timeDuration == null || timeDuration === 0) { return emptyValue }
        return moment(new Date(timeDuration)).utc(0).format(timeFormat);
    }

    static dateStringFromDbDateString = (
        dbDateString,
        format = "LL",
        emptyValue = "") => {
        return dbDateString ? moment(dbDateString, 'YYYY-MM-DD').format(format) : emptyValue;
    }

    static momentFromDbDateString = (
        dbDateString) => {
        return moment(dbDateString, 'YYYY-MM-DD');
    }
}
