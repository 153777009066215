import React from 'react';
import RoleIcon from '@material-ui/icons/TagFaces';
import { rolesFields } from '../../roles.model';

export default ({ record = {} }) => {
    //let divStyle = {
        //display: 'flex',
        //verticalAlign: 'bottom',
    //};
    let roleName = record[rolesFields.roleName ] || 'n/a';
    return (<div>
        <RoleIcon style={{verticalAlign: 'middle'}} />
        <span style={{verticalAlign: 'middle'}}>&nbsp;{roleName}</span>
    </div>);
};
