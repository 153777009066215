import React, { Component } from 'react';
import auth from './auth0.js';

export default class LoginComponent extends Component {
    constructor(props) {
        super(props);
        
        auth.login();
    }

    render() {
        return (
            <div />
        )
    }
};
