import React from 'react';
import { Link, TextField } from 'react-admin';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations.dataProvider';
import { orgsFields } from '../../orgs.model';


const OrganizationLinkField = props => {
    if (!props.record[orgsFields.id]) {return 'n/a'}
    return (
    <Link to={`${ORGANIZATIONS_RESOURCE}/${props.record.id}/show`}>
        <TextField source={orgsFields.id} style={{ color: 'blue' }} {...props}/>
    </Link>)
};

OrganizationLinkField.defaultProps = {
    addLabel: true
};

export default OrganizationLinkField;
