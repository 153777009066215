import React from 'react';
import { Link, TextField } from 'react-admin';
import { RESOURCE_NAME as ROLES_RESOURCE } from '../../roles.dataProvider';
import { rolesFields } from '../../roles.model';


const RoleLinkField = props => {
    if (!props.record[rolesFields.roleId]) {return 'n/a'}
    return (
    <Link to={`${ROLES_RESOURCE}/${props.record.id}/show`}>
        <TextField source={rolesFields.roleId} style={{ color: 'blue' }} {...props}/>
    </Link>)
};

RoleLinkField.defaultProps = {
    addLabel: true
};

export default RoleLinkField;
