import {
    GET_LIST,
    GET_MANY,
    GET_ONE,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE
} from 'react-admin';
import {
    giftsFields
} from "./gifts.model";
import {
    stringify
} from 'query-string';
const API_URL = process.env.REACT_APP_API_URL;

export const RESOURCE_NAME = "gifts";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    switch (requestType) {
        case GET_LIST:
            console.log("Gifts ", "Get_List");
            return composeGetGiftsListRequest(requestParams);
        case GET_ONE:
            console.log("Gifts ", "Get_One");
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`
            };
        case GET_MANY:
            {
                console.log("gifts_PROVIDER get many with requestParams => ", requestParams);
                const query = {
                    filter: JSON.stringify({
                        id: requestParams.ids
                    }),
                };
                return {
                    url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}`
                };
            }
        case GET_MANY_REFERENCE:
            {
                const {
                    page,
                    perPage
                } = requestParams.pagination;
                const {
                    field,
                    order
                } = requestParams.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                    filter: JSON.stringify({
                        ...requestParams.filter,
                        [requestParams.target]: requestParams.id
                    }),
                };
                return {
                    url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}`
                };
            }
        case UPDATE:
            console.log("Gifts ", "UPDATE");
            console.log("Gifts id: ", requestParams.id);
            console.log("Gifts data: ", requestParams.data);

            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: {
                    method: 'PUT',
                    body: JSON.stringify(requestParams.data)
                },
            };
        case CREATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}`,
                options: {
                    method: 'POST',
                    body: JSON.stringify(requestParams.data)
                },
            };
        case DELETE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: {
                    method: 'DELETE'
                },
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    let {
        headers,
        json
    } = httpResponse;
    switch (requestType) {
        case GET_LIST:
            return {
                data: json.map(giftItem => giftItem),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        case GET_MANY:
            let giftIdsToGet = requestParams.ids;
            console.log("gifts_provider handling ids => ", giftIdsToGet);
            return {
                data: json.filter(giftItem => giftIdsToGet.includes(giftItem.id))
            };
        case CREATE:
            return {
                data: {
                    ...requestParams.data,
                    id: json.id
                }
            };
        default:
            return {
                data: json
            };
    }
};

const composeGetGiftsListRequest = (requestParams) => {
    const {
        page,
        perPage
    } = requestParams.pagination;
    const {
        field,
        order
    } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }
        let createContainsFilter = (name, value) => ({
            name,
            comparison: "contains",
            value
        });
        let createDateBetweenFromFilter = (name, value) => ({
            name,
            comparison: "betweenFrom",
            value
        });
        let createDateBetweenToFilter = (name, value) => ({
            name,
            comparison: "betweenTo",
            value
        });

        let resultFilters = [];
        // append "code" filter
        if (requestFilters.hasOwnProperty(giftsFields.giftCode)) {
            resultFilters.push(createContainsFilter('giftCode', requestFilters[giftsFields.giftCode]));
        }
        // append "type" filter
        if (requestFilters.hasOwnProperty(giftsFields.giftType)) {
            resultFilters.push(createContainsFilter('giftType', requestFilters[giftsFields.giftType]));
        }
        // append "created at" filter
        if (requestFilters.hasOwnProperty('createdBetween')) {
            let between = requestFilters['createdBetween'];
            console.log("created at from between: ", between)
            if (between.gte != null) {
                console.log("created at from between.gte: ", between.gte)
                resultFilters.push(createDateBetweenFromFilter('createdAtFrom', between.gte));
            }
            if (between.lte != null) {
                console.log("created at to between.lte: ", between.lte)
                resultFilters.push(createDateBetweenToFilter('createdAtTo', between.lte));
            }
        }
        // append "updated at" filter
        if (requestFilters.hasOwnProperty('updatedBetween')) {
            let between = requestFilters['updatedBetween'];
            console.log("updated at from endedBetween: ", between)
            if (between.gte != null) {
                console.log("updated at from between.gte: ", between.gte)
                resultFilters.push(createDateBetweenFromFilter('updatedAtFrom', between.gte));
            }
            if (between.lte != null) {
                console.log("updated at to between.lte: ", between.lte)
                resultFilters.push(createDateBetweenToFilter('updatedAtTo', between.lte));
            }
        }

        return resultFilters;
    };

    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage]),
        filters: JSON.stringify(prepareFilters()),
    };
    return {
        url: `${API_URL}/${RESOURCE_NAME}?${stringify(queryParams)}`
    };
}

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};