import React from 'react';
import { Link } from 'react-admin';
import { RESOURCE_NAME as PROVIDERS_RESOURCE } from '../../../users/provider.dataProvider';
import ProviderFullName from './providerFullName.component';
import { callsFields } from '../../calls.model';

const ProviderLinkField = props => {
    if(!props.record[callsFields.providerId]) {return 'n/a'}
    return (<Link to={`/${PROVIDERS_RESOURCE}/${props.record[callsFields.providerId]}/show`}>
        <ProviderFullName {...props} />
    </Link>);
}

ProviderLinkField.defaultProps = {
    addLabel: true
};

export default ProviderLinkField;
