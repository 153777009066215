import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import styled from 'styled-components';

const VersionWrapper = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

export default () => (
    <Card>
        <VersionWrapper color="grayDark">
            <CardHeader title="Welcome to the administration" />
            <div>{process.env.REACT_APP_NAME}: {process.env.REACT_APP_VERSION}</div>
        </VersionWrapper>
        
    </Card>
);
