export let hotlineFields = {
    id: "id",
    patientId: "patient_id",
    groupId: "groupId",
    groupName: "group_name",
    status: "status",
    callStartedAt: "call_started_at",
    createdAt: "created_at",
    updatedAt: "updated_at",
    conferenceId: "conference_id",
    endedAt: "ended_at",
    acceptedAt: "accepted_at",
    providerId: "provider_id",
    patientRating: "patient_rating",
    patientFeedback: "patient_feedback",
    providerRating: "provider_rating",
    providerFeedback: "provider_feedback",
    phi: "phi",
    providerTruevaultDocumentId: "provider_truevault_document_id",
    demoCall: "demo_call",
    lang: "lang",
    nonClinical: "non_clinical",
    uuid: "uuid",
    service: "service",
    callCompleted: "call_completed",
    patientEndReason: "patient_end_reason",
    patientCreatedAt: "patient_created_at",
    providerEndReason: "provider_end_reason",
    patientEndDetails: "patient_end_details",
    providerEndDetails: "provider_end_details",
    overnightCall: "over_night",
    voicemail: "voicemail",
    phone: "phone",
    voicemailStartedAt: "voicemail_started_at",
    voicemailEndedAt: "voicemail_ended_at",
    patientFirstName: "patient_first_name",
    patientLastName: "patient_last_name",
    providerCreatedAt: "provider_created_at",
    providerFirstName: "provider_first_name",
    providerLastName: "provider_last_name",
    patientOrgId: "patient_organization_id",
    timeToAnswer: "time_to_answer",
    timeToCancel: "time_to_cancel",
    timeToCallback: "time_to_callback",
    callLength: "call_length",
    callbackLength: "callback_length",
    billableTime: "billable_time",
    referral: "referral",
    isRepeatCaller: "is_repeat_caller",
    isWic: "is_wic",
    breastfeeding: "breast_feeding",
    zipCode: "zip_code",
    relation: "relation",
    motherAge: "mother_age",
    motherRace: "mother_race",
    babyAge: "baby_age",
    babyGestationalAge: "baby_gestational_age"
};

