import React, { Component } from 'react';
import {
    showNotification,
    Button,
    UPDATE,
    refreshView
} from 'react-admin';
import {
    connect
} from 'react-redux';
import dataProvider from '../../../../sdk-app/dataProvider';
import {
    RESOURCE_NAME as SDK_RESOURCE_NAME
} from '../../../users/sdk.user.dataProvider';
import moment from 'moment';

class CancelSubscriptionButton extends Component {
    handleClick = () => {
        const {
            refreshView,
            record,
            showNotification
        } = this.props;
        console.log("### USER RECORD", record);
        const userForUpdate = {
            id: record.id,
            recurlyUuid: record.recurly_uuid
        };
        dataProvider(UPDATE, SDK_RESOURCE_NAME, userForUpdate)
            .then(() => {
                refreshView();
                showNotification('Subscription was cancelled.');
            })
            .catch((e) => {
                showNotification('Error: subscription was not cancelled.', 'warning');
            });
    }

    render() {
        console.log("user actions props ", this.props)
        const {
            record
        } = this.props;
        return <Button variant = "contained"
        color = "primary"
        label = "Cancel and Refund Subscription"
        disabled = {
            moment(new Date()) > moment(record.subscription_period_ends)
        }
        onClick = {
            this.handleClick
        }
        />;
    }
}

export default connect(null, {
    showNotification,
    refreshView,
})(CancelSubscriptionButton);
