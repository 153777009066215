import React from 'react';
import { TextField, Link } from 'react-admin';
import { RESOURCE_NAME as USERS_RESOURCE } from '../../../../users/user.dataProvider';
import UserFullName from './userFullName.component';

export default (props) => {
    let { record = {}, ...rest } = props;
    if (props.record.user_id) {
        return (<Link to={`/${USERS_RESOURCE}/${props.record.user_id}/show`}>
                    <UserFullName {...props} />
                </Link>);
    } else {
        let name = "n/a";
        let fullNameKey = 'fullName';
        let recordCopy = {
        ...record,
        [fullNameKey]: name,
        };
        return (<TextField source={fullNameKey} record={recordCopy} {...rest} />);
    }
};


