export let prenatalInfoFields = {
    birthWish: "Birth Wish List",
    birthPartner: "Birth Partner",
    prenatalVisit: "Prenatal Visit",
    feedingPlan: "Infant Feeding Plan",
};

export const PrenatalInfoFourOptions = {
    yes: "Yes",
    no: "No",
    some: "Some",
    notApplicable: "N/A",
}

export const PrenatalInfoThreeOptions = {
    yes: "Yes",
    no: "No",
    notApplicable: "N/A",
}

export const PrenatalInfoTypeFourChoices = Object.entries(PrenatalInfoFourOptions).map(property => {
    let [, value] = property;
    return {id: value, name: value};
});

export const PrenatalInfoTypeThreeChoices = Object.entries(PrenatalInfoThreeOptions).map(property => {
    let [, value] = property;
    return {id: value, name: value};
});

