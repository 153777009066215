import React, {Component}  from 'react';
import {Button, Snackbar, Slide} from '@material-ui/core';
import {Alert} from '@material-ui/lab'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import {
    SimpleForm,
    Toolbar,
} from 'react-admin';
import dataProvider from '../../app/dataProvider';
import { RESOURCE_NAME as PINPOINT_RESOURCE } from '../pinpoint.dataProvider';

export default class PinpointEdit extends Component {

    PUT_PINPOINT = "PUT_PINPOINT";
    csvFileWithMeta = null;

    constructor(props) {
        super(props);
        this.state = {
            notificationMessage: "",
            snackbar: {
                open: false,
                severity: "error",
                message: ""
            }
        }
    }

    render() {
        return (
            <SimpleForm submitOnEnter={false} toolbar={
                <Toolbar {...this.props} >
                    <Button variant="contained" color="primary" onClick={this.uploadPinpointCSV}>
                        Upload
                    </Button>
                </Toolbar>
            }>
                <Snackbar open={this.state.snackbar.open} autoHideDuration={3000} onClose={this.handleClose} TransitionComponent={Slide}>
                    <Alert onClose={this.handleClose} severity={this.state.snackbar.severity}>
                        {this.state.snackbar.message}
                    </Alert>
                </Snackbar>
                <Dropzone
                    onChangeStatus={this.handleChangeStatus}
                    inputContent={"Upload Pinpoint campaigns csv file. Drag or click to choose."}
                    accept=".csv"
                    maxFiles={1}
                    LayoutComponent={this.fileInputLayout}
                    submitButtonDisabled={true}
                />
            </SimpleForm>
        );
    }

    uploadPinpointCSV = () => {
        if(!this.csvFileWithMeta || !this.csvFileWithMeta.file) {
            this.setState({
                ...this.state,
                snackbar: {
                    open: true,
                    severity: "error",
                    message: "Please attach a csv file"
                }
            });
            return;
        }

        this.csvFileWithMeta.file.text()
        .then((csvText) => {
            const requestParams = {
                csv: csvText
            }
            return dataProvider(this.PUT_PINPOINT, PINPOINT_RESOURCE, requestParams);
        })
        .then( response => {
            if(!response.data.reason) { //we have that field in case of error
                this.setState({
                    ...this.state,
                    snackbar: {
                        open: true,
                        severity: "success",
                        message: "Successfully uploaded!"
                    }
                });
            } else if(response.data.reason) { //invalid parameters
                this.setState({
                    ...this.state,
                    snackbar: {
                        open: true,
                        severity: "error",
                        message: response.data.reason
                    }
                });
            } else {
                this.setState({
                    ...this.state,
                    snackbar: {
                        open: true,
                        severity: "error",
                        message: "Upload failed."
                    }
                });
            }
            this.csvFileWithMeta.remove();
        })
        .catch( error => {
            this.setState({
                ...this.state,
                snackbar: {
                    open: true,
                    severity: "error",
                    message: "Upload failed."
                }
            });
        });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            ...this.state,
            snackbar: {
                ...this.state.snackbar,
                open: false,
            }
        });
    };
    
    // called every time a file's `status` changes
    handleChangeStatus = ( fileWithMeta, status) => { 
      console.log("drop zone change status:" + status);
      if(status === "removed") {
          this.csvFileWithMeta = null;
      }
      else if (status === "preparing") {
        this.csvFileWithMeta = fileWithMeta
      }
      else if (status === "rejected_file_type") {
        this.setState({
          ...this.state,
          snackbar: {
            open: true,
            severity: "error",
            message: "Invalid file type."
          }
        });
      }
      else {
        console.log("unhandled drop zone status change; not necessarily a bad thing.")
      }
    }

    fileInputLayout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
        const dropzone = files.length < maxFiles ? (
            <div {...dropzoneProps}>
                {files.length < maxFiles && input}
            </div>
        ) : (
            <div></div>
        );
        return (
            <div>
                {previews}
                {dropzone}
            </div>
        )
    };
}
