import React from 'react';
import { Link, TextField } from 'react-admin';
import { RESOURCE_NAME as CODES_RESOURCE } from '../../codes.dataProvider';
import { codesFields } from '../../codes.model';


const CodeLinkField = props => {
    if (!props.record[codesFields.id]) {return 'n/a'}
    return (
    <Link to={`${CODES_RESOURCE}/${props.record.id}/show`}>
        <TextField source={codesFields.id} style={{ color: 'blue' }} {...props}/>
    </Link>)
};

CodeLinkField.defaultProps = {
    addLabel: true
};

export default CodeLinkField;
