import React from 'react';
import { Link, TextField } from 'react-admin';
import { userFields } from '../../user.model';


const UserLinkField = props => {
    if (!props.record[userFields.id]) {return 'n/a'}
    return (
    <Link to={`${props.resource}/${props.record[userFields.id]}/show`}>
        <TextField source={userFields.id} style={{ color: 'blue' }} {...props}/>
    </Link> )
};

UserLinkField.defaultProps = {
    addLabel: true
};

export default UserLinkField;
