import React from 'react';
import {
    Edit, 
    SimpleForm,
    Toolbar,
    TextInput,
    SelectInput,
    FormDataConsumer,
    ReferenceInput,
    required
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import RoleTitle from './fields/roleTitle.component';
import { rolesFields, CallLineType } from '../roles.model';
import { validatePhone, validateHexColor, validateUrl } from '../../utils/validation.js'
import SaveButtonValidated from "../../material/saveButtonValidated.component"
import { RESOURCE_NAME as CHARTS_RESOURCE } from '../../charts/charts.dataProvider';

const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButtonValidated />
    </Toolbar>
);

export const styles = {
    roleName: { display: 'inline-block' },
    lineType: { display: 'inline-block', marginLeft: 32 },
};

const validateEdit = (values) => {
    const errors = {};
    
    // handle phone validation manually, which relies on the line type.
    // I would have used `FormDataConsumer` for this and dynamically changed the validators, but the validators weren't 
    // updating in real time when line type was changed. Not sure why, maybe something I was doing wrong.
    let lineType = values[rolesFields.lineType];
    let isPhoneType = lineType === CallLineType.PhoneToPacifyProvider.id || lineType === CallLineType.PhoneNumber.id;
    let phoneNumber = values[rolesFields.phoneNumber]

    let validators = isPhoneType ? [required(), validatePhone()] : [validatePhone(false)]
    for (let validator of validators) {
        let result = validator(phoneNumber)
        if (result === undefined) {
            continue
        }
        errors[rolesFields.phoneNumber] = result
    }

    return errors
};

const RoleEdit = ({ classes, ...props }) => (
    <Edit title={<RoleTitle style={{verticalAlign: 'middle'}}/>} {...props}>
        <SimpleForm redirect = "show" validate={validateEdit} submitOnEnter={false} toolbar={<PostEditToolbar/>}>
            <TextInput source={rolesFields.roleName} formClassName={classes.roleName}   label='Name' validate={required()} />
            <SelectInput 
                label="Line Type" 
                source={rolesFields.lineType}
                formClassName={classes.lineType}  
                validate={required()}  
                choices={[ 
                    CallLineType.VideoChat,
                    CallLineType.PhoneNumber,        
                    CallLineType.PhoneToPacifyProvider
                ]}/>
            {/* phone validation handled by `validatedEdit` */}
            <TextInput 
                type="phone" 
                label="Phone Number" 
                source={rolesFields.phoneNumber} 
            />
            <TextInput
                label="Button Background Color"
                source={rolesFields.buttonBackgroundColor} 
                // formClassName={classes.last_name}
                validate={[required(), validateHexColor()]} 
            />
            <TextInput
                label="Button Image URL"
                fullWidth={true}
                source={rolesFields.buttonImageURL} 
                // fullWidth={true}
                // formClassName={classes.address}
                validate={[required(), validateUrl()]} 
            />
            <TextInput
                multiline
                fullWidth={true}
                label="Info Popup Content -- English"
                source={rolesFields.infoPopupContentEnglish} 
                // formClassName={classes.city}
                validate={required()} 
            />
            <TextInput
                multiline
                fullWidth={true}
                label="Info Popup Content -- Spanish"
                source={rolesFields.infoPopupContentSpanish} 
                // formClassName={classes.city}
                validate={required()} 
            />
            <TextInput
                multiline
                fullWidth={true}
                label="Title -- English"
                source={rolesFields.titleEnglish} 
                // formClassName={classes.city}
                validate={required()} 
            />
            <TextInput
                multiline
                fullWidth={true}
                label="Title -- Spanish"
                source={rolesFields.titleSpanish} 
                // formClassName={classes.city}
                validate={required()} 
            />
            <ReferenceInput source={rolesFields.chartId} 
                reference={CHARTS_RESOURCE} perPage={10000} sort={{ field: "name", order: 'ASC' }} label="Chart Type">
                <SelectInput optionText={"name"} />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default withStyles(styles)(RoleEdit);
