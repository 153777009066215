import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    DeleteButton,
    Filter,
    TextInput
} from 'react-admin';
import { rolesFields } from '../roles.model';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import RoleLinkField from './fields/roleLink.component';

const RolesFilter = props => (
    <Filter {...props}>
        <TextInput label="Name" source={rolesFields.roleName} allowEmpty />
    </Filter>
);

const ProtectedDeleteButton = props => {
  
    return (
        <DeleteButton undoable={false} disabled={props.record.is_protected}
        {...props} 
        />
    )
  };

const ProtectedEditButton = props => {

return (
    <EditButton undoable={false} disabled={props.record.is_protected}
    {...props} 
    />
)
};

const RolesList = props => {
    return (
        <List {...props} 
        title="Roles" 
        sort={{ field: 'id', order: 'DESC' }} 
        filters={<RolesFilter />} 
        bulkActionButtons={false}
        exporter={false}>
            <Datagrid>
                <RoleLinkField label="Id" source={rolesFields.roleId} />
                <DefaultPlaceholderField source={rolesFields.roleName} wrapField={<TextField/>}  label="Name" />
                <DefaultPlaceholderField source={rolesFields.lineType} wrapField={<TextField/>} label="Line Type" sortable={false} addLabel/>
                <DefaultPlaceholderField source={rolesFields.phoneNumber} wrapField={<TextField/>} label="Phone Number" sortable={false} addLabel/>                
                <DefaultPlaceholderField source={rolesFields.chartName} wrapField={<TextField/>} label="Chart Type" sortable={false} addLabel/>                
                <ShowButton />
                <ProtectedEditButton />
                <ProtectedDeleteButton />
            </Datagrid>
        </List>
    )
};

export default RolesList;
