import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import auth from './auth0';

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    throw Object.assign(
      new Error("this should never be called"), { code: 402 });
  }

  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    auth.logout()
    return Promise.resolve();
  }

  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      auth.logout()
      return Promise.reject();
    }
    return Promise.resolve();
  }
  
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    if (params.location === "/callback") {
      return Promise.resolve() // Currently trying to validate authenticity.  Function will be called again after redirect after authentication succeeds or fails.
    }
    else if (auth.isAuthenticated()) {
      auth.renewSessionIfExpiringSoon();
      return Promise.resolve()
    }
    else {
      // When we reject the promise here, react-admin redirects the user to the login page, and we lose 
      // the info of where they were trying to go.  By clearing auth state and reloading instead, the user
      //  gets to log in and continue on to their location.
      //return Promise.reject()
      auth.logout()
      window.location.reload();
      return;                              
    }
  }

  if (type === AUTH_GET_PERMISSIONS) {
    return Promise.resolve("super-admin"); // TODO
  }

  return Promise.reject('Unknown method');
};

