import React, { useState } from "react";
import {
  Show,
  SimpleShowLayout,
  SimpleForm,
  Toolbar,
  TextField,
  DateField,
  ReferenceArrayField,
  ArrayField,
  ReferenceField,
  SingleFieldList,
  ChipField,
  Datagrid,
} from "react-admin";
import { Alert } from "@material-ui/lab";
import { Button, Snackbar, Slide} from "@material-ui/core";
import OrgTitle from "./fields/orgTitle.component";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { DATE_TIME_OPTIONS } from "../../app/app.component";
import DefaultPlaceholderField from "../../material/defPlaceholderField.component";
import { orgsFields } from "../orgs.model";
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from "../../roles/provider.roles.dataProvider";
import { RESOURCE_NAME as CONTENT_LINKS_RESOURCE } from "../../contentLinks/contentLinks.dataProvider";
import { rolesFields } from "../../roles/roles.model";
import { contentLinksFields } from "../../contentLinks/contentLinks.model";
import YesNoField from "../../material/yesNoField.component";
import Papa from "papaparse";
import dataProvider from "../../app/dataProvider";
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE, PUT_MEDICAID } from "../organizations.dataProvider";
import YesNoBadgeComponent from "../../material/yesNoBadge.component";
import ContentLinksList from "../../material/ContentLinksList.component";
import DataDialog from "./dataDialog.component";
import DisableOrganizationDialog from "./disableOrganizationDialog.component";

const getCurrentMonthAndYear = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  return [currentMonth, currentYear];
}

const MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

const OrgShow = (props) => {
  // medicaid resource list
  // TODO refactor some code
  const [csvFileWithMeta, setCsvFileWithMeta] = useState(null);

  const [state, setState] = useState({
    notificationMessage: "",
    snackbar: {
      open: false,
      severity: "error",
      message: "",
    },
  });

  const [isDisplayedDataDialog, setDisplayDataDialog] = useState(false);
  const [isDisplayedDisableOrganizationDialog, setOpenDisableOrganizationDialog] = useState(false);

  const openDataDialog = () => {
    setDisplayDataDialog(true);
  };

  const closeDataDialog = () => {
    setDisplayDataDialog(false);
  };

  const openDisableOrganizationDialog = () => {
    setOpenDisableOrganizationDialog(true);
  };

  const closeDisableOrganizationDialog = () => {
    setOpenDisableOrganizationDialog(false);
  };

  const [currentMonth, currentYear] = getCurrentMonthAndYear();
  const [dataMonth, setDataMonth] = useState(currentMonth);
  const [dataYear, setDataYear] = useState(currentYear);

  const handleChangeDataMonth = (event) => {
    setDataMonth(event.target.value);
  };

  const handleChangeDataYear = (event) => {
    setDataYear(event.target.value);
  };

  async function uploadMedicaidCSV() {
    console.log("upload start", csvFileWithMeta);

    if (!csvFileWithMeta || !csvFileWithMeta.file) {
      setState({
        ...state,
        snackbar: {
          open: true,
          severity: "error",
          message: "Please attach a csv file",
        },
      });
      return;
    }

    try {
      // Request a presigned URL from the Lambda function
      const presignedUrlResponse = await dataProvider(
        PUT_MEDICAID,
        ORGANIZATIONS_RESOURCE,
        {
          action: "getPresignedUrl",
          id: props.id,
        }
      );

      const presignedUrl = presignedUrlResponse.data.url;
      const s3Key = presignedUrlResponse.data.key;
      console.log("presignedUrl", presignedUrl);
      const csvFile = csvFileWithMeta.file;

      // Upload the CSV file to S3 using the presigned URL
      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        body: csvFile,
        headers: { "Content-Type": "text/csv" },
      });
      console.log("upload response", uploadResponse);

      if (uploadResponse.ok) {
        setState({
          ...state,
          snackbar: {
            open: true,
            severity: "success",
            message:
              "CSV uploaded successfully, You will be notified by email when it is complete.",
          },
        });
        // Call the processUploadedCsv action after successful upload
        Promise.resolve().then(() => {
          dataProvider(PUT_MEDICAID, ORGANIZATIONS_RESOURCE, {
            action: "processUploadedCsv",
            s3FileKey: s3Key,
            id: props.id,
            dateSent: `${dataYear}-${MONTHS[dataMonth - 1]}-01`,
          });
        });
      } else {
        setState({
          ...state,
          snackbar: {
            open: true,
            severity: "error",
            message: "Upload to S3 failed.",
          },
        });
      }
    } catch (error) {
      console.error("Upload to S3 or processing CSV failed:", error);
      setState({
        ...state,
        snackbar: {
          open: true,
          severity: "error",
          message: "Upload to S3 or processing CSV failed.",
        },
      });
    } finally {
      csvFileWithMeta.remove();
    }
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setState({
      ...state,
      snackbar: {
        ...state.snackbar,
        open: false,
      },
    });
  }

  function validateCSV(csvFileWithMeta) {
    // Required column headers
    const requiredHeaders = [
      "Plan",
      "Medicaid ID",
      "Firstname",
      "Lastname",
      "Email",
      "Phone",
      "Dateofbirth",
      "Race",
      "Language",
      "Address",
      "Address 2",
      "City",
      "State",
      "Zipcode",
      "Nicu",
      "Highrisk",
      "Due date/Delivery date",
    ];

    function checkForDuplicates(rows, key) {
      const values = rows
        .map((row) => row[key].trim())
        .filter((value) => value !== "");
      const duplicateValues = values.filter(
        (value) => values.indexOf(value) !== values.lastIndexOf(value)
      );
      return duplicateValues;
    }

    function validateHeaders(headers) {
      const missedHeaders = requiredHeaders.filter(header => !headers.includes(header));
      return missedHeaders;
    }

    function isValidDate(date) {
      const dateRegex = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/;
      return dateRegex.test(date);
    }

    function isValidEmailOrPhone(value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^\d{10}$/;
      return emailRegex.test(value) || phoneRegex.test(value);
    }

    function validateRow(row, rowIndex) {
      const hasMedicaidID =
        row["Medicaid ID"] && row["Medicaid ID"].trim() !== "";

      const hasValidEmailOrPhone =
        (row.Email && isValidEmailOrPhone(row.Email)) ||
        (row.Phone && isValidEmailOrPhone(row.Phone));

      if (!hasMedicaidID && !hasValidEmailOrPhone) {
        return `Row ${rowIndex + 1
          }: At least one of Medicaid ID, Email or Phone must be present and valid`;
      }

      if (row.Email && !isValidEmailOrPhone(row.Email)) {
        return `Row ${rowIndex + 1}: Invalid Email format`;
      }

      if (row.Phone && !isValidEmailOrPhone(row.Phone)) {
        return `Row ${rowIndex + 1}: Invalid Phone format`;
      }

      if (!isValidDate(row.Dateofbirth)) {
        return `Row ${rowIndex + 1}: Invalid Dateofbirth format`;
      }

      if (!isValidDate(row["Due date/Delivery date"])) {
        return `Row ${rowIndex + 1}: Invalid Due date/Delivery date format`;
      }

      return null;
    }

    csvFileWithMeta.file.text().then((csvText) => {
      const parsedCSV = Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
      });

      const missingOrIncorrectHeaders = validateHeaders(parsedCSV.meta.fields);

      if (missingOrIncorrectHeaders.length > 0) {
        setState({
          ...state,
          snackbar: {
            open: true,
            severity: "error",
            message: `Incorrect or missing headers: ${missingOrIncorrectHeaders.join(
              ", "
            )}.`,
          },
        });
        csvFileWithMeta.remove();
        return;
      }

      for (let i = 0; i < parsedCSV.data.length; i++) {
        const rowError = validateRow(parsedCSV.data[i], i);
        if (rowError) {
          setState({
            ...state,
            snackbar: {
              open: true,
              severity: "error",
              message: rowError,
            },
          });
          return;
        }
      }

      const duplicateEmails = checkForDuplicates(parsedCSV.data, "Email");
      const duplicatePhones = checkForDuplicates(parsedCSV.data, "Phone");
      const duplicateMedicaidIDs = checkForDuplicates(
        parsedCSV.data,
        "Medicaid ID"
      );

      if (
        duplicateEmails.length > 0 ||
        duplicatePhones.length > 0 ||
        duplicateMedicaidIDs.length > 0
      ) {
        setState({
          ...state,
          snackbar: {
            open: true,
            severity: "warning",
            message: `Duplicate value(s) found: ${duplicateEmails.length > 0
              ? `Emails: ${duplicateEmails.join(", ")}`
              : ""
              }${duplicatePhones.length > 0
                ? ` Phones: ${duplicatePhones.join(", ")}`
                : ""
              }${duplicateMedicaidIDs.length > 0
                ? ` Medicaid IDs: ${duplicateMedicaidIDs.join(", ")}`
                : ""
              }. Only the last entry will be saved.`,
          },
        });
      }
    });
  }

  // called every time a file's `status` changes
  function handleChangeStatus(fileWithMeta, status) {
    console.log("drop zone change status:" + status);
    if (status === "removed") {
      setCsvFileWithMeta(null);
    } else if (status === "preparing") {
      setCsvFileWithMeta(fileWithMeta);
    } else if (status === "rejected_file_type") {
      setState({
        ...state,
        snackbar: {
          open: true,
          severity: "error",
          message: "Invalid file type.",
        },
      });
    } else if (status === "done") {
      console.log("done");
      validateCSV(csvFileWithMeta);
      openDataDialog();
    } else {
      console.log("processing");
    }
  }

  let fileInputLayout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }) => {
    const dropzone =
      files.length < maxFiles ? (
        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      ) : (
        <div></div>
      );
    return (
      <div>
        {previews}
        {dropzone}
      </div>
    );
  };

  console.log(props);

  return (
    <Show title={<OrgTitle style={{ verticalAlign: "middle" }} />} {...props}>
      <SimpleShowLayout>
        <DefaultPlaceholderField
          source={orgsFields.orgName}
          wrapField={<TextField />}
          label="Name"
          addLabel
        />
         <DefaultPlaceholderField
          source={orgsFields.clientGroupId}
          wrapField={<TextField />}
          label="Client Group ID"
          addLabel
        />
        <DefaultPlaceholderField
          source={orgsFields.payerId}
          wrapField={<TextField />}
          label="Payer ID"
          addLabel
        />
        <DefaultPlaceholderField
          source={orgsFields.orgCreatedAt}
          wrapField={<DateField />}
          options={DATE_TIME_OPTIONS}
          label="Created at"
          addLabel
        />
        <DefaultPlaceholderField
          source={orgsFields.orgUpdatedAt}
          wrapField={<DateField />}
          options={DATE_TIME_OPTIONS}
          label="Updated at"
          addLabel
        />
        <DefaultPlaceholderField
          source={orgsFields.orgNursePhone}
          wrapField={<TextField />}
          label="Nurse phone"
          addLabel
        />
        <DefaultPlaceholderField
          source={orgsFields.orgSdkApiKey}
          wrapField={<TextField />}
          label="Sdk api key"
          addLabel
        />
        <DefaultPlaceholderField
          source={orgsFields.orgSdkAuthenticationEndpoint}
          wrapField={<TextField />}
          label="Sdk authentication endpoint"
          addLabel
        />
        <YesNoField
          source={orgsFields.contentLibraryEnabled}
          label="Content Library Enabled"
          addLabel
        />
        {/* <ContentLinksList {...props} 
          label="Content Links" 
          addLabel 
          linksFieldName={orgsFields.contentLinks} 
          linksIdsFieldName={orgsFields.contentLinkIds} 
          linksEnabledFieldName={orgsFields.contentLinksEnabled} /> */}
        <YesNoField
          source={orgsFields.medicaidIdRegistrationQuestionEnabled}
          label="Medicaid ID Registration Question Enabled"
          addLabel
        />
        <YesNoField
          source={orgsFields.enrollmentCodeRequired}
          label="Enrollment Code Required"
          addLabel
        />
        <YesNoField
          source={orgsFields.chatEnabled}
          label="Chat Enabled"
          addLabel
        />
         <YesNoField
          source={orgsFields.requiresWicId}
          label=" WIC ID Required"
          addLabel
        />
        <ReferenceArrayField
          label="Supported Provider Roles"
          reference={PROVIDER_ROLES_RESOURCE}
          source={orgsFields.roleIds}
        >
          <SingleFieldList linkType={false}>
            <ChipField source={rolesFields.roleName} />
          </SingleFieldList>
        </ReferenceArrayField>
        {/* add supported provider roles */}
        <SimpleForm
          submitOnEnter={false}
          toolbar={
            <Toolbar {...props}>
              <Button
                variant="contained"
                color="primary"
                onClick={uploadMedicaidCSV}
              >
                Upload
              </Button>
            </Toolbar>
          }
        >
          <Snackbar
            open={state.snackbar.open}
            autoHideDuration={5000}
            onClose={handleClose}
            TransitionComponent={Slide}
          >
            <Alert onClose={handleClose} severity={state.snackbar.severity}>
              {state.snackbar.message}
            </Alert>
          </Snackbar>
          <Dropzone
            onChangeStatus={handleChangeStatus}
            inputContent={
              "Upload Medicaid member list csv file. Drag or click to choose."
            }
            accept=".csv"
            maxFiles={1}
            LayoutComponent={fileInputLayout}
            submitButtonDisabled={true}
          />
          <DataDialog
            open={isDisplayedDataDialog}
            closeDataDialog={closeDataDialog}
            dataMonth={dataMonth}
            handleChangeDataMonth={handleChangeDataMonth}
            dataYear={dataYear}
            handleChangeDataYear={handleChangeDataYear}
          />
        </SimpleForm>
        {/* <Button variant="contained" color="primary" onClick={openDisableOrganizationDialog}>Deactivate all organization codes and associated users</Button> */}
        <DisableOrganizationDialog
          open={isDisplayedDisableOrganizationDialog}
          close={closeDisableOrganizationDialog}
          orgId={props.id}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default OrgShow;
