import React from 'react';
import { TextField } from 'react-admin';
//import { userFields } from '../../../../users/user.model';
import { discountCodesFields } from '../../discountCodes.model';

export default (props) => {
    let { record = {}, ...rest } = props;

    let fullName;
    if (record) {
        let firstName = record[discountCodesFields.userFirstName] || '';
        let lastName = record[discountCodesFields.userLastName] || '';
        fullName = `${firstName} ${lastName}`;
    }
    
    let fullNameKey = 'fullName';
    let recordCopy = {
        ...record,
        [fullNameKey]: fullName,
    };
    return (<TextField source={fullNameKey} record={recordCopy} style={{ color: 'blue' }} {...rest} />);
};
