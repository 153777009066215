import {
    GET_LIST,
    GET_MANY,
    GET_ONE,
    UPDATE,
    CREATE,
    DELETE
} from 'react-admin';
import { rolesFields } from "./roles.model";
import { stringify } from 'query-string';

const API_URL = process.env.REACT_APP_API_URL;

export const RESOURCE_NAME = "roles";

export const providerRequestToHttpRequest = (requestType, requestParams) => {
    return providerRoleRequestToHttpRequest(requestType, requestParams, false);
}

export const providerRoleRequestToHttpRequest = (requestType, requestParams, all) => {
    switch (requestType) {
        case GET_LIST: 
            console.log("Roles ", "Get_List");
            return composeGetRolesListRequest(requestParams, all);
        case GET_ONE:
        console.log("Roles ", "Get_One");
            return { url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}` };
        case GET_MANY: {
            console.log("roles_PROVIDER get many with requestParams => ", requestParams);
            const query = {     
                all: all,                
                filters: JSON.stringify([{ name: "id", comparison: "in", value: requestParams.ids }])
            };
            console.log(`${API_URL}/${RESOURCE_NAME}?${stringify(query)}`)
            return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}` };
        }   
        case UPDATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: { method: 'PUT', body: JSON.stringify(requestParams.data) },
            };
        case CREATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}`,
                options: { method: 'POST', body: JSON.stringify(requestParams.data) },
            };
        case DELETE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: { method: 'DELETE' },
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    let { headers, json } = httpResponse;
    switch (requestType) {
        case GET_LIST:
            console.log(RESOURCE_NAME, "response: ", json.map(roleItem => roleItem))
            return {
                data: json.map(roleItem => roleItem),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        case GET_MANY:
            let roleIdsToGet = requestParams.ids;
            console.log("roles_provider handling ids => ", roleIdsToGet);
            console.log(RESOURCE_NAME, "response: ", json.filter(roleItem => roleIdsToGet.includes(roleItem.id)))
            return {
                data: json.filter(roleItem => roleIdsToGet.includes(roleItem.id))
            };
        default: 
            return { data: json };
    }
};

const composeGetRolesListRequest = (requestParams, all) => {    
    const { page, perPage } = requestParams.pagination;
    const { field, order } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }
        let createContainsFilter = (name, value) => ({ name, comparison: "contains", value });

        let resultFilters = [];
        // append "name" filter
        if (requestFilters.hasOwnProperty(rolesFields.roleName)) {
            resultFilters.push(createContainsFilter('roleName', requestFilters[rolesFields.roleName]));
        }
        if (requestFilters.hasOwnProperty(rolesFields.lineType)) {
            resultFilters.push(createContainsFilter('lineType', requestFilters[rolesFields.lineType]));
        }
       
        return resultFilters;
    };

    console.log(requestParams);
    const queryParams = {
        all: all,        
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage]),
        filters: JSON.stringify(prepareFilters()),
    };
    return { url: `${API_URL}/${RESOURCE_NAME}?${stringify(queryParams)}` };
}

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};
