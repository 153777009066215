import React from 'react';
import { callsFields } from '../../calls.model';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';

const styles = {
  title: {
    paddingLeft: "20px",
  },
  content: {
    paddingLeft: "40px",
    whiteSpace: "pre-wrap"
  }
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const CheckboxCompact = styled(Checkbox)({
  padding: 0,
});

export default (props) => {
    let divStyle = {
        display: 'flex',
        verticalAlign: 'bottom'
    };
    var phi = JSON.parse(props.record.phi);
    if (!phi || !phi.feedbackFormData || (phi.feedbackFormData.recommendations === "" && phi.feedbackFormData.complaint === "" && phi.feedbackFormData.follow_up === "")) {
      if (props.record[callsFields.nonClinical]) {
        return (
          <div style={divStyle}>
            <span>No clinical content</span>
          </div>
        );
      }
      else {
        return (
          <div style={divStyle}>
              <span>No chart</span>
            </div>
            )
          }
      }
    else {
      if (phi.type && (phi.type === 'doula')) {
        return <DoulaChart phi={phi} />
      }
      else if (phi.type && (phi.type === 'postpartum_doula')) {
        return <PostpartumDoulaChart phi={phi} />
      }
      else {
        return <DefaultChart phi={phi} />
      }
    }
};


function DefaultChart(props) {
  const phi = props.phi;
  return (
    <div>
      <div style={styles.title}>
        <span><b>Chief Complaint/History:</b></span>
      </div>
      <div style={styles.content}>
        <div>
          {phi.feedbackFormData.complaint}
        </div>
      </div>
      <br />
      <div style={styles.title}>
        <span><b>Recommendations/Plan:</b></span>
      </div>
      <div style={styles.content}>
        <div>
          {phi.feedbackFormData.recommendations}
        </div>
      </div>
      <br />
      <div style={styles.title} >
        <span><b>Immediate Follow-Up Required:</b></span>
      </div>
      <div style={styles.content}>
        <div>
          {phi.feedbackFormData.follow_up}
        </div>
      </div>
    </div>
  );
}

function DoulaChart(props) {
  const classes = useStyles();
  const phi = props.phi;
  return (
    <div>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Yes</TableCell>
            <TableCell>Some</TableCell>
            <TableCell>No</TableCell>
            <TableCell>N/A</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Birth Wish List</TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.birth_plan === "yes" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.birth_plan === "some" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.birth_plan === "no" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.birth_plan === "n/a" ? true : false} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Birth Partner</TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.birth_partner === "yes" ? true : false} /></TableCell>
            <TableCell></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.birth_partner === "no" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.birth_partner === "n/a" ? true : false} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Prenatal Visits</TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.prenatal_visit === "yes" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.prenatal_visit === "some" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.prenatal_visit === "no" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.prenatal_visit === "n/a" ? true : false} /></TableCell>

          </TableRow>
          <TableRow>
            <TableCell>IN LABOR</TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.in_labor === "yes" ? true : false} /></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Findings</TableCell>
            <TableCell colspan="3">{phi.feedbackFormData.findings}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Recommendations/Plan</TableCell>
            <TableCell colspan="3">{phi.feedbackFormData.recommendations}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Immediate Follow-Up Required</TableCell>
            <TableCell colspan="3"><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.follow_up === "yes" ? true : false} /></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

function PostpartumDoulaChart(props) {
  const classes = useStyles();
  const phi = props.phi;
  return (
    <div>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Yes</TableCell>
            <TableCell>Some</TableCell>
            <TableCell>No</TableCell>
            <TableCell>N/A</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Postpartum Visits</TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.postpartum_visits === "yes" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.postpartum_visits === "some" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.postpartum_visits === "no" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.postpartum_visits === "n/a" ? true : false} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Pediatrician Visits</TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.pediatrician_visits === "yes" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.pediatrician_visits === "some" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.pediatrician_visits === "no" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.pediatrician_visits === "n/a" ? true : false} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>PHQ-2</TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.phq2 === "yes" ? true : false} /></TableCell>
            <TableCell></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.phq2 === "no" ? true : false} /></TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.phq2 === "n/a" ? true : false} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Referred for PHQ-2</TableCell>
            <TableCell><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.phq2_referral === "yes" ? true : false} /></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Findings</TableCell>
            <TableCell colspan="3">{phi.feedbackFormData.findings}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Recommendations/Plan</TableCell>
            <TableCell colspan="3">{phi.feedbackFormData.recommendations}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Immediate Follow-Up Required</TableCell>
            <TableCell colspan="3"><CheckboxCompact disabled size="small" checked={phi.feedbackFormData.follow_up === "yes" ? true : false} /></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}