export let userFields = {
    // id: "id",
    // email: "email",
    // firstName: "first_name",
    // lastName: "last_name",
    // roleId: "role_id",
    // roleName: "role_name",
    // dob: "dob",
    // dueDate: "due_date",
    // lang: "lang",
    // recurlyActive: "recurly_active",
    // isActive: "is_active",
    // phoneNumber: "phone_number",
    // orgId: "org_id",
    // orgName: "org_name",
    // usState: "us_state",
    // createdAt: "created_at",
    // updatedAt: "updated_at",
    // isMfaRequired: "mfa_required",
    // code: "code_name",
    // codes: "codes",
    // recurly_uuid: "recurly_uuid",
    // code_active: "coupon_active",
    // code_id: "coupon_id",
    // coupon_name: "coupon_name",
    // providerRoleIds: "provider_role_ids",
    // orgCodes: "org_codes",
    // gift: "gift"

    //export, list, show, edit, create
    id: "id",
    //export, filter, list, show, edit, create
    email: "email",
    //export, filter, list, show, edit, create
    firstName: "first_name",
    //export, filter, list, show, edit, create
    lastName: "last_name",
    //filter, list, edit, create
    roleId: "role_id",
    //export, filter, list, show, create
    roleName: "role_name",
    //export, list, show, edit, create
    dob: "dob",
    //export, list, show, edit, create
    dueDate: "due_date",
    //export, list, show, edit, create
    lang: "lang",
    //export, filter, list, show
    recurlyActive: "recurly_active",
    //export, filter, list, show, reate
    isActive: "is_active",
    //export, show, edit, create
    phoneNumber: "phone_number",
    //filter, show, edit, create
    orgId: "org_id",
    //export, show
    orgName: "org_name",
    //export, show, edit, create
    usState: "us_state",
    //export, show
    createdAt: "created_at",
    //show
    updatedAt: "updated_at",
    //show, edit, create
    isMfaRequired: "mfa_required",
    //code: "code_name",
    
    //codes: "codes",    
    //recurly_uuid: "recurly_uuid",
    recurlyUuid: "recurly_uuid",
//    code_active: "coupon_active",
//    code_id: "coupon_id",
//    coupon_name: "coupon_name",
    //providerRoleIds: "provider_role_ids",
    //filter, show, edit, create
    providerRoles: "provider_roles",
    providerRoleIds: "provider_role_ids",
    //providerRoleNames: "provider_role_names",
    //export, show
    orgCodes: "org_codes",
    //gift: "gift"    
    //show
    gifts: "gifts",

    //export, show, edit, create
    relatives: "relatives",
    //export
    pushEnabled: "push_enabled",
    //export
    shareFeedback: "share_feedback",
    //sdk
    active_coupon_id: "active_coupon_id",
    active_coupon_name: "active_coupon_name",    

    subscription_period_ends: "subscription_period_ends",
    subscription_plan_name: "subscription_plan_name",
    is_active_sdk_user: "is_active_sdk_user"

    //sdk
    //subscription: "subscription"
};

export let userCombinedFields = {
    isSdkUserActive: "sdk_user_active",
};

export const userLang = {
    EN: "English",
    ES: "Spanish",
    ES_EN: "Spanish+English",
    FRCA: "French Canadian",
    FRCA_EN: "French Canadian+English"
};

export let userOrgCodeFields = {
    id: "id",
    name: "name"  
}

export let userGiftFields = {
    id: "id",
    code: "code"  
}

export let userProviderRoleFields = {
    id: "id",
    name: "name"
}

export let userRelativeFields = {
    id: "id",
    name: "name",
    dob: "dob"
}