import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, useRedirect } from 'react-admin';
import { RESOURCE_NAME as PINPOINT_RESOURCE } from '../pinpoint/pinpoint.dataProvider';
import DefaultIcon from '@material-ui/icons/ViewList';
import Dashboard from '@material-ui/icons/Dashboard';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    subMenuItem: {
        paddingLeft: '32px',
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
    },
    expandIcon: {
        marginLeft: 'auto',
        padding: '12px',
        cursor: 'pointer',
    }
});

const CustomMenu = ({ onMenuClick, logout }) => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const [expandedMenus, setExpandedMenus] = useState({});
    const redirect = useRedirect();

    // Group resources by parent
    const menuStructure = resources
        .filter(resource => (resource.hasEdit || resource.hasList || resource.hasCreate || resource.hasShow))
        .reduce((acc, resource) => {
            if (resource.options && resource.options.parent) {
                const parent = resource.options.parent;
                if (!acc[parent]) {
                    acc[parent] = [];
                }
                acc[parent].push(resource);
            } else {
                if (!acc.root) acc.root = [];
                acc.root.push(resource);
            }
            return acc;
        }, {});

    const handleToggle = (menu) => {
        setExpandedMenus(prev => ({
            ...prev,
            [menu]: !prev[menu]
        }));
    };

    const handleParentClick = (e, resource) => {
        e.preventDefault(); // Prevent default navigation
        // Navigate to the parent resource's page
        redirect('list', `/${resource.name}`);
        if (onMenuClick) {
            onMenuClick();
        }
    };

    const handleExpandClick = (e, label) => {
        e.stopPropagation(); // Prevent navigation when clicking expand icon
        handleToggle(label);
    };

    const renderMenuItem = (resource) => {
        if (resource.name === PINPOINT_RESOURCE) {
            return (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        capitalizeFirstLetter(resource.name)
                    }
                    leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            );
        }

        // Handle submenu items
        if (resource.options && resource.options.subMenu) {
            const subMenuItems = menuStructure[resource.options.label] || [];
            const isExpanded = expandedMenus[resource.options.label];

            return (
                <div key={resource.name}>
                    <div className={classes.menuItem}>
                        <MenuItemLink
                            to={`/${resource.name}`}
                            primaryText={resource.options.label}
                            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                            onClick={(e) => handleParentClick(e, resource)}
                            sidebarIsOpen={open}
                            style={{ flex: 1 }}
                        />
                        <div 
                            className={classes.expandIcon}
                            onClick={(e) => handleExpandClick(e, resource.options.label)}
                        >
                            {isExpanded ? <ExpandLess /> : <ExpandMore />}
                        </div>
                    </div>
                    {isExpanded && subMenuItems.map(subItem => (
                        <MenuItemLink
                            key={subItem.name}
                            to={`/${subItem.name}`}
                            primaryText={subItem.options.label}
                            leftIcon={subItem.icon ? <subItem.icon /> : <DefaultIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            className={classes.subMenuItem}
                        />
                    ))}
                </div>
            );
        }

        // Don't render items that are part of a submenu at root level
        if (resource.options && resource.options.parent) {
            return null;
        }

        // Regular menu items
        return (
            <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={
                    (resource.options && resource.options.label) ||
                    capitalizeFirstLetter(resource.name)
                }
                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
        );
    };

    return (
        <div>
            <MenuItemLink
                key="dashboard"
                to="/"
                primaryText="Dashboard"
                leftIcon={<Dashboard/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                style={{marginTop: '20px'}}
            />
            {menuStructure.root && menuStructure.root.map(renderMenuItem)}
            {isXSmall && logout}
        </div>
    );
};

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export default CustomMenu;
