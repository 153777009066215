import userDataProvider from "./user.dataProvider"
import { USER_ROLES } from "../roles/constants";

export const RESOURCE_NAME = "patients";

const providerRequestToHttpRequest = (...props) => {
  const [method, params] = props
  const updatedParams = { ...params, filter: { ...params?.filter, role_name: USER_ROLES.PATIENT } }

  return userDataProvider.providerInterface.providerRequestToHttpRequest(method, updatedParams)
}

const httpResponseToProviderData = userDataProvider.providerInterface.httpResponseToProviderData

export default {
  resource: RESOURCE_NAME,
  providerInterface: {
    providerRequestToHttpRequest,
    httpResponseToProviderData
  },
};