import React from 'react';
import { TextField } from 'react-admin';

export default (props) => {
    let { record = {}, ...rest } = props;
    let fullName;
    if (record) {
        let firstName = record["user_first_name"] || '';
        let lastName = record["user_last_name"] || '';
        fullName = `${firstName} ${lastName}`;
    }
    let fullNameKey = 'fullName';
    let recordCopy = {
        ...record,
        [fullNameKey]: fullName,
    };
    return (<TextField source={fullNameKey} record={recordCopy} style={{ color: 'blue' }} {...rest} />);
};
