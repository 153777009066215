import React from 'react';
import {
    Edit, 
    SimpleForm,
    Toolbar,
    TextInput,
    SelectInput,
    BooleanInput,
    required
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import VersionTitle from './fields/versionTitle.component';
import { versionsFields } from '../versions.model';
import SaveButtonValidated from "../../material/saveButtonValidated.component"

const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButtonValidated />
    </Toolbar>
);

export const styles = {
    roleName: { display: 'inline-block' },
    lineType: { display: 'inline-block', marginLeft: 32 },
};


const VersionEdit = ({ classes, ...props }) => (
    <Edit title={<VersionTitle style={{verticalAlign: 'middle'}}/>} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<PostEditToolbar/>}>
            <SelectInput 
                    label="Platform" 
                    source={versionsFields.platform}
                    formClassName={classes.platform}  
                    validate={required()}  
                    choices={[
                        { id: 2, name: "Android" },
                        { id: 1, name: "iOS" },                        
                    ]}/>
            <SelectInput 
                    label="App type" 
                    source={versionsFields.appType}
                    validate={required()}
                    choices={[
                        { id:1, name: "Patient" },
                        { id:2, name: "Provider" }
                    ]}/>   
            <TextInput
                    autoFocus
                    multiline
                    label="Version"
                    source={versionsFields.version} 
                    formClassName={classes.version}
                    validate={required()} 
            />

            <BooleanInput  
                    label="Force update" 
                    source={versionsFields.forceUpdate} />

            <TextInput
                    autoFocus
                    multiline
                    label="Build"
                    source={versionsFields.build} 
                    formClassName={classes.build}
                    validate={required()} />  

            <TextInput
                    autoFocus
                    multiline
                    label="Release notes"
                    source={versionsFields.releaseNotes} 
                    formClassName={classes.version} />
        </SimpleForm>
    </Edit>
);

export default withStyles(styles)(VersionEdit);
