import React from 'react';
import {
    Show, 
    SimpleShowLayout,
    TextField,
    DateField,
    ArrayField,
    SingleFieldList
} from 'react-admin';

import UserTitle from '../../../users/component/fields/userTitle.component';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';
import { DATE_OPTIONS } from '../../../sdk-app/app.component';
import { userFields } from '../sdk.user.model';
import UserSubscriptionField from './fields/userSubscription.component';
import UserActionsField from './fields/userActions.component';
import UserIsActiveField from './fields/userIsActiveField.component';
import SdkCodeLinkField from './fields/codeLink.component';
//import SdkCodeLinkField from './fields/codeLinkList.component';
import YesNoField from '../../../material/yesNoField.component';

const UserShow = (props) => {
    return (
        <Show title={<UserTitle />} {...props}>
            <SimpleShowLayout>
                <DefaultPlaceholderField source={userFields.id} wrapField={<TextField/>} label="Id" addLabel  />
                <DefaultPlaceholderField source={userFields.email} wrapField={<TextField/>} label="Email" addLabel  />
                <DefaultPlaceholderField source={userFields.firstName} wrapField={<TextField/>} label="First Name" addLabel  />
                <DefaultPlaceholderField source={userFields.lastName} wrapField={<TextField/>} label="Last Name" addLabel  />
                <DefaultPlaceholderField source={userFields.createdAt} options={DATE_OPTIONS} wrapField={<DateField/>} label="Created At" addLabel />
                {/* <UserIsActiveField  label="Is Active" addLabel /> */}
                <YesNoField source={userFields.is_active_sdk_user} label="Is Active" addLabel />
                <SdkCodeLinkField label="Code" showName="true" addLabel />
                <UserSubscriptionField />
                <UserActionsField />
            </SimpleShowLayout>
        </Show>
    );
};

export default UserShow;
