import React from 'react';
import {CardActions, Button} from '@material-ui/core';
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    EmailField,
    DateField,
    EditButton,
    SingleFieldList,
    ArrayField,
    Datagrid,
    Link
} from 'react-admin';
import UserTitle from './fields/userTitle.component';
import YesNoField from '../../material/yesNoField.component';
import ConditionalYesNoField from './fields/ConditionalYesNoField.component'
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import { DATE_OPTIONS } from '../../app/app.component';
import { DATE_TIME_OPTIONS } from '../../app/app.component';
import { userFields } from '../user.model';
import { userRelativeFields } from '../user.model';
import OrganizationLinkField from './fields/OrganizationLinkField.component';
import OrganizationCodeLinkField from './fields/OrganizationCodeLinkField.component';
import GiftsField from './fields/GiftLinkField.component';
import UserRoleField from './fields/userRoleField.component';

const UserShowActions = (props) => {
    const { basePath, data } = props;
    const actionsStyle = {
        zIndex: 2,
        display: 'inline-block',
        float: 'right',
    };
    return (
        <CardActions style={actionsStyle}>
            <EditButton basePath={basePath} record={data} />
        </CardActions>
    );
};

const ManualPushNotificationButton = (props) => {
    const isDisabled =
        (typeof props.record.device_type !== "undefined" &&
            props.record.device_type === null) ||
        (typeof props.record.is_notification_endpoint_available !== "undefined" &&
            props.record.is_notification_endpoint_available === "false");
    return (
        <Button
            component={Link}
            to={`/users/${props.record.id}/notify`}
            variant="contained"
            color="primary"
            disabled={isDisabled}
        >
            <span style={{ color: '#fff' }}>
                Manual Push Notification
            </span>
        </Button>
    )
};

const ChangePasswordButton = (props) => {
    return (
        <Link to={`/users/${props.record.id}/changePassword`}>
            <Button variant="contained" color="primary" onClick={showChangePasswordScreen}>
                Change Password
            </Button>
        </Link>
    )
};

const showChangePasswordScreen = () => {

};

const UserShow = (props) => {
    window.scrollTo(0, 0);
    return (
        <Show title={<UserTitle style={{verticalAlign: 'middle'}}/>} actions={<UserShowActions />} {...props}>
            <TabbedShowLayout>
                <Tab label="User Details">
                    <DefaultPlaceholderField source={userFields.id} wrapField={<TextField/>} label="Id" addLabel  />
                    <DefaultPlaceholderField source={userFields.email} wrapField={<EmailField/>} label="Email" addLabel  />
                    <DefaultPlaceholderField source={userFields.firstName} wrapField={<TextField/>} label="First Name" addLabel  />
                    <DefaultPlaceholderField source={userFields.lastName} wrapField={<TextField/>} label="Last Name" addLabel  />
                    <DefaultPlaceholderField source={userFields.wicId} wrapField={<TextField/>} label="WIC ID" addLabel  />
                    {/* <ReferenceField source="role_id" reference="roles">
                        <TextField source="id" />
                    </ReferenceField> */}
                    <UserRoleField label="Role" addLabel/>
                    <DefaultPlaceholderField source={userFields.permissionTemplateName} wrapField={<TextField/>} label="Permissions template" addLabel />
                    <DefaultPlaceholderField source={userFields.providerBio} wrapField={<TextField />} label="Provider Bio" addLabel />
                    <DefaultPlaceholderField source={userFields.providerBioEs} wrapField={<TextField/>} label="Provider Bio Es" addLabel />
                    {/* <DefaultPlaceholderField source={userFields.roleName} wrapField={<TextField/>} label="Role" addLabel  />
                    <DefaultPlaceholderField source={userFields.providerRoleIds} options={DATE_OPTIONS} wrapField={
                        <ReferenceArrayField label="Provider Roles" reference={PROVIDER_ROLES_RESOURCE} source={userFields.providerRoleIds}>
                            <SingleFieldList linkType={false}>
                                <ChipField source={rolesFields.roleName} />
                            </SingleFieldList>
                        </ReferenceArrayField>
                      } label="Provider Roles" addLabel /> */}
                    <DefaultPlaceholderField source={userFields.dob} options={DATE_OPTIONS} wrapField={<DateField/>} label="DOB" addLabel />
                    <DefaultPlaceholderField source={userFields.dueDate} options={DATE_OPTIONS} wrapField={<DateField/>} label="Due Date" addLabel />
                    <DefaultPlaceholderField source={userFields.phoneNumber} options={DATE_OPTIONS} wrapField={<TextField/>} label="Phone" addLabel />
                    <DefaultPlaceholderField source={userFields.lang} wrapField={<TextField/>} label="Language" addLabel />
                    <OrganizationLinkField label="Organization" addLabel/>
                    <ConditionalYesNoField source={userFields.shareFeedback} showIfFieldExists={userFields.orgId} label="Share Feedback" addLabel />
                    <YesNoField source={userFields.recurlyActive} label="Recurly Active" addLabel />
                    <YesNoField source={userFields.isActive} label="Is Active" addLabel />
                    <DefaultPlaceholderField source={userFields.usState} wrapField={<TextField/>} label="State" addLabel />
                    <DefaultPlaceholderField source={userFields.zipCode} wrapField={<TextField/>} label="Zip Code" addLabel />
                    <DefaultPlaceholderField source={userFields.createdAt} options={DATE_TIME_OPTIONS} wrapField={<DateField/>} label="Created At" addLabel />
                    <DefaultPlaceholderField source={userFields.updatedAt} options={DATE_TIME_OPTIONS} wrapField={<DateField/>} label="Updated At" addLabel />
                    <YesNoField source={userFields.isMfaRequired} label="Dashboard MFA Required" addLabel />
                    <DefaultPlaceholderField source={userFields.gifts} wrapField={
                        <GiftsField />
                    } label="Gifts Redeemed" addLabel />
                    <DefaultPlaceholderField source={userFields.orgCodes} label="Organization Codes" addLabel wrapField={
                        <ArrayField source={userFields.orgCodes} label="Organization Codes"> 
                            <SingleFieldList linkType={true}>
                                <OrganizationCodeLinkField/>
                            </SingleFieldList>
                        </ArrayField>
                    } />
                    <DefaultPlaceholderField source={userFields.birthWish} wrapField={<TextField/>} label="Birth Wish List" addLabel />
                    <DefaultPlaceholderField source={userFields.birthPartner} wrapField={<TextField/>} label="Birth Partner" addLabel />
                    <DefaultPlaceholderField source={userFields.prenatalVisit} wrapField={<TextField/>} label="Prenatal Visits" addLabel />
                    <DefaultPlaceholderField source={userFields.feedingPlan} wrapField={<TextField/>} label="Infant Feeding Plan" addLabel />

                    <ManualPushNotificationButton label="Actions" addLabel />                    
                    <ChangePasswordButton  {...props}/>
                </Tab>
                <Tab label="Relatives">
                    <ArrayField source={userFields.relatives}  label="">
                        <Datagrid>
                            <TextField
                                label="Name"
                                source={userRelativeFields.name}
                            />
                            <DateField
                                label="Dob"
                                options={DATE_OPTIONS}
                                source={userRelativeFields.dob}
                            />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default UserShow;