// in src/dataProvider
import { fetchUtils } from 'react-admin';

import auth from '../auth/auth0';
import UsersDataProviderRegistration from '../users/user.dataProvider';
import PatientsDataProviderRegistration from '../users/patient.dataProvider';
import ProvidersDataProviderRegistration from '../users/provider.dataProvider';
import AdminsDataProviderRegistration from '../users/admin.dataProvider';
import RolesDataProviderRegistration from '../roles/roles.dataProvider';
import ProviderRolesDataProviderRegistration from '../roles/provider.roles.dataProvider';
import NonProviderRolesDataProviderRegistration from '../roles/non.provider.roles.dataProvider';
import OrgsDataProviderRegistration from '../organizations/organizations.dataProvider';
import LinksDataProviderRegistration from '../contentLinks/contentLinks.dataProvider';
import UsStatesDataProviderRegistration from '../usStates/usStates.dataProvider';
import LanguagesDataProviderRegistration from '../languages/languages.dataProvider';
import VersionsDataProviderRegistration from '../versions/versions.dataProvider';
import GiftsDataProviderRegistration from '../gifts/gifts.dataProvider';
import CodesDataProviderRegistration from '../codes/codes.dataProvider';
import CallsDataProviderRegistration from '../calls/calls.dataProvider';
import PinpointDataProviderRegistration from '../pinpoint/pinpoint.dataProvider';
import HotlineDataProviderRegistration from '../hotline/hotline.dataProvider';
import ChartsDataProviderRegistration from '../charts/charts.dataProvider';
import FeedingPlansDataProviderRegistration from '../prenatalInfo/feedingPlans.dataProvider';
import ChatsDataProviderRegistration from '../chats/chats.dataProvider';
import PermissionTemplatesRegistration from '../permissionTemplates/permission.template.dataProvider';
import { pregnancyMilestoneNotificationsDataProvider, pregnancyMilestoneNotificationDeliveriesDataProvider, pregnancyMilestoneNotificationAnalyticsDataProvider } from '../notifications/pregnancyMilestoneNotifications.dataProvider';

import { allOrganizationsDataProvider as  AllOrgsDataProviderRegistration} from '../organizations/organizations.dataProvider';

/**
 * Each Provider's registration should conform to the interface
 * 
 * {
 *     resource: <REACT_ADMIN_RESOURCE_NAME>,
 *     providerInterface: {
 *         providerRequestToHttpRequest,
 *         httpResponseToProviderData
 *     },
 * }
 */
const DATA_PROVIDER_REGISTRATIONS = [
    PatientsDataProviderRegistration,
    ProvidersDataProviderRegistration,
    AdminsDataProviderRegistration,
    UsersDataProviderRegistration,
    RolesDataProviderRegistration,
    ProviderRolesDataProviderRegistration,
    NonProviderRolesDataProviderRegistration,
    OrgsDataProviderRegistration,    
    AllOrgsDataProviderRegistration,
    LinksDataProviderRegistration,
    UsStatesDataProviderRegistration,
    LanguagesDataProviderRegistration,
    VersionsDataProviderRegistration,
    GiftsDataProviderRegistration,
    CodesDataProviderRegistration,
    CallsDataProviderRegistration,
    PinpointDataProviderRegistration,
    HotlineDataProviderRegistration,
    ChartsDataProviderRegistration,
    FeedingPlansDataProviderRegistration,
    ChatsDataProviderRegistration,
    PermissionTemplatesRegistration,
    pregnancyMilestoneNotificationsDataProvider,
    pregnancyMilestoneNotificationDeliveriesDataProvider,
    pregnancyMilestoneNotificationAnalyticsDataProvider
];

/**
* @param {string} type Request type, e.g GET_LIST
* @param {string} resource Resource name, e.g. "posts"
* @param {Object} payload Request parameters. Depends on the request type
* @returns {Promise} the Promise for response
*/
export default (requestType, resource, requestParams) => {
    console.log(`Requesting "${resource}" resource by "${requestType}" method.`);
    let { fetchJson } = fetchUtils;
    let dataProvider = withAuthHeadersDecoration(resolveDataProvider(resource));
    let { url, options } = dataProvider.providerRequestToHttpRequest(requestType, requestParams);

    if (auth.isAuthenticated()) {
      auth.renewSessionIfExpiringSoon();
    } else {
      auth.logout();
      window.location.reload();
      return;
    }

    return fetchJson(url, options).then(response => {
      return dataProvider.httpResponseToProviderData(response, requestType, requestParams)
    })
    .catch(response => {
      // console.log("fetchJson error response is: " + JSON.stringify(response));
      if (response != null && (response.status === 403 || response.status === 401)) {
        alert("Session expired. Please log in again to continue.");
        window.location='/';
      }
      else if (response != null && response.body != null && response.body.message != null) {  // Standard server error format
        return Promise.reject(response.body);
      }

      return Promise.reject(response); // Pass up whole error if it doesn't match the expected pattern.
    });
};

/**
 * Decorates passed provider with authorization headers.
 */
const withAuthHeadersDecoration = (provider) => ({
    providerRequestToHttpRequest(requestType, requestParams) {
        let { url, options = {} } = provider.providerRequestToHttpRequest(requestType, requestParams);
        options.headers = new Headers({ Authorization: 'Bearer ' + auth.getIdToken() });
        return {url, options};
    },

    httpResponseToProviderData(httpResponse, requestType, requestParams) {
        return provider.httpResponseToProviderData(httpResponse, requestType, requestParams);
    }
});

const resolveDataProvider = (askedResource) => {
    let { providerInterface } = DATA_PROVIDER_REGISTRATIONS.find(registration => {
        let { resource, providerInterface } = registration;
        if (!resource) {
            throw new Error("DataProvider's registration must have resource field");
        }
        if (!providerInterface || typeof providerInterface !== 'object') {
            throw new Error("DataProvider's registration must have providerInterface field of Object type");
        }
        return resource === askedResource;
    });

    if (!providerInterface) {
        console.error(`Requested resource's "${askedResource}" DataProvider not found. Check data provider registrations.`);
    }
    return providerInterface;
};
