import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    SelectField,
    EditButton,
    Filter,
    TextInput,
    SelectInput,
    BooleanInput,
    CreateButton,
    ExportButton,
    TopToolbar,
    useListContext,
    Loading,
} from 'react-admin';

const notificationTypeChoices = [
    { id: 'DOULA', name: 'Doula' },
    { id: 'LC', name: 'LC' },
];

const milestoneTypeChoices = [
    { id: 'prenatal', name: 'Prenatal' },
    { id: 'postnatal', name: 'Postnatal' },
];

const styles = {
    filter: { minWidth: "200px" },
    bodyField: { 
        maxWidth: '400px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
};

// Custom body field component to truncate long text
const TruncatedTextField = ({ record, source }) => {
    if (!record || !record[source]) return null;
    return (
        <div style={styles.bodyField} title={record[source]}>
            {record[source]}
        </div>
    );
};

const PregnancyMilestoneNotificationFilter = props => (
    <Filter {...props}>
        <TextInput 
            label="Search Title" 
            source="title" 
            alwaysOn 
            style={styles.filter}
        />
        <TextInput 
            label="Search Body" 
            source="body" 
            style={styles.filter}
        />
        <SelectInput 
            source="notification_type" 
            choices={notificationTypeChoices}
            style={styles.filter}
        />
        <SelectInput 
            source="milestone_type" 
            choices={milestoneTypeChoices}
            style={styles.filter}
        />
        <BooleanInput 
            source="is_active" 
            style={styles.filter}
        />
        <TextInput 
            label="Deep Link" 
            source="deep_link" 
            style={styles.filter}
        />
    </Filter>
);

const ListActions = ({
    basePath,
    currentSort,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    total
}) => (
    <TopToolbar>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton basePath={basePath} />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
        />
    </TopToolbar>
);

const TemplateListContent = () => {
    const { isLoading } = useListContext();
    if (isLoading) {
        return <Loading loadingPrimary="Loading templates..." loadingSecondary="Please wait while we fetch the data" />;
    }

    return (
        <Datagrid>
            <TextField source="title" />
            <TruncatedTextField source="body" label="Message Body" />
            <TruncatedTextField source="liquid_body" label="Liquid Body" />
            <SelectField 
                source="notification_type" 
                choices={notificationTypeChoices} 
            />
            <SelectField 
                source="milestone_type" 
                choices={milestoneTypeChoices} 
            />
            <TextField source="days_from_due_date" />
            <TextField source="scheduled_time" />
            <DateField source="created_at" showTime />
            <DateField source="updated_at" showTime />
            <EditButton />
        </Datagrid>
    );
};

export const PregnancyMilestoneNotificationList = props => {
    const { options } = props;
    const pageTitle = options?.pageLabel || options?.label || 'Pregnancy Milestone Notifications';
    
    return (
        <List 
            {...props} 
            title={pageTitle}
            filters={<PregnancyMilestoneNotificationFilter />}
            actions={<ListActions />}
            bulkActionButtons={false}
        >
            <TemplateListContent />
        </List>
    );
}; 