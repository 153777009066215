import React from 'react';
import { TextField, Link } from 'react-admin';
import { RESOURCE_NAME as CODES_RESOURCE } from '../../../sdk-codes/codes.dataProvider';

export default (props) => {
    let lastCode = props.record.org_codes ? props.record.org_codes[0] : null;
    let couponDescription = (props.record.active_coupon_id && props.record.active_coupon_name) ?  props.record.active_coupon_name 
    : (lastCode && lastCode.name) ? lastCode.name : "loading ...";
    let couponDescriptionKey = 'couponDescriptionKey';
    let recordCopyWithDescription = {
        ...props.record,
        [couponDescriptionKey]: couponDescription,
    };
    let { record = {}, ...rest } = props;
    if (props.record.active_coupon_id) {
        return  (<Link to={`/${CODES_RESOURCE}/${record.active_coupon_id}/show`}>
        <TextField source={couponDescriptionKey} record={recordCopyWithDescription} style={{ color: 'blue' }} {...rest}/>
    </Link>) ;
    } else if (lastCode && lastCode.id) { 
        return  (<Link to={`/${CODES_RESOURCE}/${lastCode.id}/show`}>
        <TextField source={couponDescriptionKey} record={recordCopyWithDescription} style={{ color: 'blue' }} {...rest}/>
    </Link>) ;
    } else {
        let name = "n/a";
        let fullNameKey = 'fullName';
        let recordCopy = {
        ...record,
        [fullNameKey]: name,
        };
        return (<TextField source={fullNameKey} record={recordCopy} {...rest} />);
    }
};


// import React from 'react';
// import { Link, TextField } from 'react-admin';
// //import { RESOURCE_NAME as CODES_RESOURCE } from '../../../codes/codes.dataProvider';
// import { userOrgCodeFields } from '../../sdk.user.model';
// import { userFields } from '../../sdk.user.model';
// import { RESOURCE_NAME as CODES_RESOURCE } from '../../../sdk-codes/codes.dataProvider';


// const OrganizationCodeLinkField = props => {
//     if (!props.record[userOrgCodeFields.name]) {return 'n/a'}
//     return (
//     <Link to={`/${CODES_RESOURCE}/${props.record[userOrgCodeFields.id]}/show`} >
//         <TextField source={userOrgCodeFields.name} style={{ color: 'blue' }} {...props}/>
//     </Link>)
// };

// OrganizationCodeLinkField.defaultProps = {
//     addLabel: true
// };

// export default OrganizationCodeLinkField;


// import React from 'react';
// import { TextField, Link } from 'react-admin';
// import { RESOURCE_NAME as CODES_RESOURCE } from '../../../sdk-codes/codes.dataProvider';

// export default (props) => {
//     let lastCode = props.record.codes ? props.record.codes[props.record.codes.length -1] : null;
//     let couponDescription = (props.record.coupon_id && props.record.coupon_name) ?  props.record.coupon_name 
//     : (lastCode && lastCode.code) ? lastCode.code : "loading ...";
//     let couponDescriptionKey = 'couponDescriptionKey';
//     let recordCopyWithDescription = {
//         ...props.record,
//         [couponDescriptionKey]: couponDescription,
//     };
//     let { record = {}, ...rest } = props;
//     if (props.record.coupon_id) {
//         return  (<Link to={`/${CODES_RESOURCE}/${record.coupon_id}/show`}>
//         <TextField source={couponDescriptionKey} record={recordCopyWithDescription} style={{ color: 'blue' }} {...rest}/>
//     </Link>) ;
//     } else if (lastCode && lastCode.id) { 
//         return  (<Link to={`/${CODES_RESOURCE}/${lastCode.id}/show`}>
//         <TextField source={couponDescriptionKey} record={recordCopyWithDescription} style={{ color: 'blue' }} {...rest}/>
//     </Link>) ;
//     } else {
//         let name = "n/a";
//         let fullNameKey = 'fullName';
//         let recordCopy = {
//         ...record,
//         [fullNameKey]: name,
//         };
//         return (<TextField source={fullNameKey} record={recordCopy} {...rest} />);
//     }
// };


