import React from 'react';
import { versionsFields } from '../../versions.model';

export default ({ record = {} }) => {
    let divStyle = {
        display: 'flex',
        verticalAlign: 'bottom',
        fontSize: 14,
    };
    let appType;
    switch (record[versionsFields.appType]) {
        case 1:
            appType = "Patient";
            break;
        case 2:
            appType = "Provider";
            break;     
        default:
            appType = "Not Defined";
            break;
      }

    return (<div style={divStyle}>
        <span>{appType}</span>
    </div>);
};
