export const chatsFields = {
  chatInteractionId: "id",
  chatId: "chat_id",
  initiatorId: "initiator_user_id",
  initiatorRole: "initiator_role",
  type: "provider_role",
  lang: "lang",
  status: "status",
  phi: "phi",
  providerChartType: "provider_chart_type",
  patientId: "patient_id",
  patientFirstName: "patient_first_name",
  patientLastName: "patient_last_name",
  patientRating: "patient_rating",
  patientFeedback: "patient_feedback",
  providerId: "provider_id",
  providerFirstName: "provider_first_name",
  providerLastName: "provider_last_name",
  providerRating: "provider_rating",
  providerFeedback: "provider_feedback",
  startedAt: "created_at",
  overnight: "over_night",
  acceptedAt: "provider_accepted_at",
  endedAt: "closed_at",
  timeToAnswer: "time_to_answer",
  nonClinical: "non_clinical",
  shareFeedback: "share_feedback",
  patientDOB: "patient_dob",
  patientPhone: "patient_phone",
  patientZip: "patient_zip",
  patientRace: "patient_race",
  patientCreatedAt: "patient_created_at",
  patientOrgName: "patient_org_name",
  orgCodeNames: "org_code_names",
  daysSinceAccountCreation: "days_since_account_creation",
};