import React from 'react';
import { Field } from 'react-final-form';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

const CustomDatePicker = props => {

  return (
    <Field name={props.source}>
      {fieldProps => {

        const datePickerOnChangeHelper = changeValue => {
          var datePicked = moment(changeValue)
          var returnDate;
          if (props.useStartOfDay) {
            returnDate = datePicked.clone().startOf('day').toDate();
          }
          else if (props.useEndOfDay) {
            returnDate = datePicked.clone().endOf('day').toDate();
          }
          else {
            returnDate = datePicked.toDate();
          }
          fieldProps.input.onChange(returnDate);
        }
        
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="pickers">
              <DatePicker
                name={fieldProps.input.name}
                value={fieldProps.input.value === "" ? null : fieldProps.input.value}
                onChange={datePickerOnChangeHelper}
                format="MM/dd/yyyy"
                label={props.label}
              />
            </div>
          </MuiPickersUtilsProvider>
        );
      }}
    </Field>
  )
}

export default CustomDatePicker;