import React from 'react';
import { TextField } from 'react-admin';
import { userFields } from '../../user.model';

export default (props) => {
    let { record = {}, ...rest } = props;
    
    let firstName = record[userFields.firstName] || '';
    let lastName = record[userFields.lastName] || '';
    let fullName = `${firstName} ${lastName}`;

    let fullNameKey = 'fullName';
    let recordCopy = {
        ...record,
        [fullNameKey]: fullName,
    };
    return (<TextField source={fullNameKey} record={recordCopy} {...rest} />);
};
