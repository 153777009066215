import React from 'react';
import {
    Show, 
    SimpleShowLayout,
    TextField,
    DateField,
    ImageField,
    TopToolbar,
    EditButton,
} from 'react-admin';
import RoleTitle from './fields/roleTitle.component';
import { DATE_TIME_OPTIONS } from '../../app/app.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import { rolesFields } from '../roles.model';

const PostShowActions = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
            <EditButton basePath={basePath} record={data} disabled={data != null ? data.is_protected : true}/>
        </TopToolbar>
    )
}

const RoleShow = (props) => {
    window.scrollTo(0, 0);
    return (
        <Show actions={<PostShowActions {...props}/>} title={<RoleTitle style={{verticalAlign: 'middle'}}/>} {...props}>
            <SimpleShowLayout>
                <DefaultPlaceholderField source={rolesFields.roleId} wrapField={<TextField/>} label="Id" addLabel  />
                <DefaultPlaceholderField source={rolesFields.roleName} wrapField={<TextField/>} label="Name" addLabel  />
                <DefaultPlaceholderField source={rolesFields.roleCreatedAt} wrapField={<DateField/>} options={DATE_TIME_OPTIONS} label="Created at" addLabel  />
                <DefaultPlaceholderField source={rolesFields.roleUpdatedAt} wrapField={<DateField/>} options={DATE_TIME_OPTIONS} label="Updated at" addLabel  />
                <DefaultPlaceholderField source={rolesFields.lineType} wrapField={<TextField/>} label="Line Type" addLabel  />
                <DefaultPlaceholderField source={rolesFields.phoneNumber} wrapField={<TextField/>} label="Phone Number" addLabel  />
                <DefaultPlaceholderField source={rolesFields.buttonBackgroundColor} wrapField={<TextField/>} label="Button Background Color" addLabel  />
                <DefaultPlaceholderField source={rolesFields.buttonImageURL} wrapField={<TextField/>} label="Button Image URL" addLabel  />
                <ImageField source={rolesFields.buttonImageURL}  label=""/>
                <DefaultPlaceholderField source={rolesFields.infoPopupContentEnglish} wrapField={<TextField/>} label="Info Popup Content -- English" addLabel  />                
                <DefaultPlaceholderField source={rolesFields.infoPopupContentSpanish} wrapField={<TextField/>} label="Info Popup Content -- Spanish" addLabel  />                
                <DefaultPlaceholderField source={rolesFields.titleEnglish} wrapField={<TextField/>} label="Title -- English" addLabel  />                
                <DefaultPlaceholderField source={rolesFields.titleSpanish} wrapField={<TextField/>} label="Title -- Spanish" addLabel  />
                <DefaultPlaceholderField source={rolesFields.chartName} wrapField={<TextField/>} label="Chart Type" addLabel/>                
                {/* add supported provider roles */}
            </SimpleShowLayout>
        </Show>
    );
};

export default RoleShow;
