import {useFormState} from "react-final-form";
import React from "react";

export const ConditionalField = ({ condition, children }) => {
  const formState = useFormState();

  if (!formState || !condition(formState)) {
    return null;
  }


  return (
    <>
      {children}
    </>
  );
};