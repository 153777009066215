import React from 'react';
import CallIcon from '@material-ui/icons/Phone';
import { callsFields } from '../../calls.model';

export default ({ record = {} }) => {
    //let divStyle = {
        //display: 'flex',
        //verticalAlign: 'bottom',
    //};
    let callId = record[callsFields.id ] || 'n/a';
    return (<div>
        <CallIcon style={{verticalAlign: 'middle'}}/>
        <span style={{verticalAlign: 'middle'}}>&nbsp;{`Call queue # ${callId}`}</span>
    </div>);
};


///<CallIcon style={{padding: "1px"}}/>

///<CallIcon style={{padding: "1px"}}/>


//<CallIcon style={{paddingLeft: "25"}}/>
        //<span style={{paddingLeft: "15"}} >&nbsp;{`Call queue # ${callId}`}</span>