import React from 'react';
import {
    Show, 
    SimpleShowLayout,
    TextField,
    DateField    
} from 'react-admin';
import { discountCodesFields } from '../discountCodes.model';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';
import DiscountField from './fields/discountField.component';
import NotEmptyYesNoField from './fields/notEmptyYesNoField.component';
import UserLinkField from './fields/userLink.component';
import { DATE_OPTIONS } from '../../../app/app.component';
import CodeTitle from './fields/codeTitle.component';


// const PostShowActions = ({ basePath, data, resource }) => (
//     return null;
// );

const DiscountCodeShow = ({ ...props }) => {
    return (
        <Show title={<CodeTitle />} {...props} actions={null}>
            <SimpleShowLayout>                
                <DefaultPlaceholderField source={discountCodesFields.uniqueCouponCode} wrapField={<TextField/>}  label="Code" addLabel/>
                <DiscountField label="Discount" sortable={true} addLabel/>
                <DefaultPlaceholderField source={discountCodesFields.createdAt} wrapField={<DateField/>} options={DATE_OPTIONS} label="Date Created" addLabel/>
                <DefaultPlaceholderField source={discountCodesFields.appliedAt} wrapField={<DateField/>} options={DATE_OPTIONS} label="Date Redeemed" addLabel/>
                <NotEmptyYesNoField source={discountCodesFields.redeemed} label="Redeemed" addLabel/>
                <NotEmptyYesNoField source={discountCodesFields.deactivated} label="Deactivated" addLabel/>
                <DefaultPlaceholderField source={discountCodesFields.plans} wrapField={<TextField/>} label="Plans" addLabel/>
                <UserLinkField label="User" addLabel source="fullName"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default DiscountCodeShow;
