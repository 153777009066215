export let rolesFields = {
    roleId: "id",
    roleName: "name",
    roleCreatedAt: "created_at",
    roleUpdatedAt: "updated_at",
    isProviderSubtype: "is_provider_subtype",
    isProtected: "is_protected",
    lineType: "line_type",
    phoneNumber: "phone_number",
    buttonBackgroundColor: "button_background_color",
    buttonImageURL: "button_image_url",
    infoPopupContentEnglish: "info_en",
    infoPopupContentSpanish: "info_es",
    titleEnglish: "title_en",
    titleSpanish: "title_es",
    chartId: "chart_id",
    chartName: "chart_name"
};

export const CallLineType = {
    VideoChat: { id:"video-chat", name: "Video Chat" },
    PhoneNumber: { id:"phone-number", name: "Phone Number" },                        
    PhoneToPacifyProvider: { id:"phone-to-pacify-provider", name: "Phone to Pacify Provider" }
}
