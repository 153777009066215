export const DELIVERY_STATUS = {
    SCHEDULED: 'SCHEDULED',      // initial state
    PROCESSED: 'PROCESSED',      // processed in dry run mode
    SENT_TO_SEGMENT: 'SENT_TO_SEGMENT',  // sent to Segment, has segmentMessageId
    SENT_TO_BRAZE: 'SENT_TO_BRAZE',      // sent to Braze, has brazeEventId
    FAILED: 'FAILED'            // error occurred, see error_message
};

export const DELIVERY_STATUS_CHOICES = [
    { id: DELIVERY_STATUS.SCHEDULED, name: 'Scheduled' },
    { id: DELIVERY_STATUS.PROCESSED, name: 'Processed' },
    { id: DELIVERY_STATUS.SENT_TO_SEGMENT, name: 'Sent to Segment' },
    { id: DELIVERY_STATUS.SENT_TO_BRAZE, name: 'Sent to Braze' },
    { id: DELIVERY_STATUS.FAILED, name: 'Failed' }
]; 