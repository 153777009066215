export let codesFields = {
    id: "id",
    orgId: "organization_id",
    codeName: "code_name",
    codeType: "code_type",
    orgName: "org_name",
    invalidated: "invalidated",
    createdAt: "created_at",
    roleIds: "role_ids",
    roleOrders: "role_orders",
    redeemed: "redeemed",
    planId: "plan_id",
    planName: "plan_name",
    userId: "user_id",
    userFirstName: "user_first_name",
    userLastName: "user_last_name",
    usersCount: "users_count"
};

export const CodeType = {
    InfoOnly: 1,
    FreeForever: 2,
    RecurlyDiscount: 3,
    Sdk: 4,
    SdkMultiUse: 5,
    SdkRecurlyDiscount: 6
};

export const CodeTypeNames = {
    [CodeType.InfoOnly]: 'Info Only',
    [CodeType.FreeForever]: 'Free Forever',
    [CodeType.RecurlyDiscount]: 'Apply Recurly Discount',
    [CodeType.Sdk]: 'SDK - single use',
    [CodeType.SdkMultiUse]: 'SDK - multi use',
    [CodeType.SdkRecurlyDiscount]: 'SDK - recurly discount'
}

export const CodeTypeChoices = Object.entries(CodeTypeNames).map(property => {
    let [key, value] = property;
    return {id: parseInt(key), name: value};
});

