import React from 'react';
import {
    TextField
} from 'react-admin';


const BlankPlaceholderField = (props) => {
    let { source, record, wrapField, placeholder = '', ...rest } = props;
    if (!record || !record[source]) {
        let recordCopy = {
            ...record, 
            [source]: placeholder
        };
        return <TextField source={source} record={recordCopy} {...rest} />
    }
    return React.cloneElement(wrapField, {
            source: source, 
            record: record,
            ...rest
    });
};

export default BlankPlaceholderField;