import React from 'react';
import {
    Create,
    FormTab,
    TabbedForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    ArrayInput,
    SelectArrayInput,
    SimpleFormIterator,
    ReferenceArrayInput,
    BooleanInput,
    required,
    email,
    Toolbar,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../roles/provider.roles.dataProvider';
import { RESOURCE_NAME as NON_PROVIDER_ROLES_RESOURCE} from '../../roles/non.provider.roles.dataProvider';
import { ALL_RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import { RESOURCE_NAME as US_STATES_RESOURCE } from '../../usStates/usStates.dataProvider';
import { RESOURCE_NAME as LANGUAGES_RESOURCE } from '../../languages/languages.dataProvider'
import { RESOURCE_NAME as USERS_RESOURCE } from '../user.dataProvider';
import { RESOURCE_NAME as PERMISSION_TEMPLATE_RESOURCE } from "../../permissionTemplates/permission.template.dataProvider";
import {USER_ROLE_RESOURCE_MAPPER, userFields} from '../user.model';
import { rolesFields } from '../../roles/roles.model';
import { userRelativeFields } from '../userRelative.model';
import TableCell from '@material-ui/core/TableCell';
import { useCreate, useNotify } from 'react-admin';
import { useFormState } from 'react-final-form';
import { validatePhone } from '../../utils/validation.js'
import SaveButtonValidated from "../../material/saveButtonValidated.component"
import CustomDatePicker from "../../app/customDatePicker.js"
import { ConditionalField } from "../../utils/conditionalField";
import { optionalField} from "../../utils/constants";
import {useRole} from "./hooks/useRole";

const styles = {
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    datepicker: {

    },
};

const PostCreateToolbar = (props) => {
    const notify = useNotify();
    const formState = useFormState();
    const [createUse, {data, error }] = useCreate(
      'users', // TODO use resource name import
      { ...formState.values }
    );

    var errorMessage;
    // Handle errors
    if (error) {
      if (error.message != null && error.reason != null) {
        errorMessage = 'Error creating user: ' + error.message + " :: " + error.reason;
      }
      else {
        errorMessage = 'Error creating user: ' + JSON.stringify(error);
      }
    }
    else if (data) {
      notify('User created', 'info', {}, false);
      window.location.pathname = USERS_RESOURCE + "/" + data.id + "/show";
    }
        return (
      <Toolbar {...props} >
        <SaveButtonValidated handleSubmitWithRedirect={createUse} />
        <br />
        <div style={{color: 'red', margin: '20px'}}>
          {errorMessage}
        </div>
      </Toolbar>
    ) }

const UserCreate = ({ classes, ...props }) => {
    const { isProvider, isPatient, isAdmin, handlers: { setRoleId }, isLoadingRoles} = useRole({});

    const handleConditionalFields = (formState) => {
        const { values } = formState;
        const resetFields = [
            userFields.permissionTemplateId,
            userFields.providerRoleIds,
            userFields.providerBio,
            userFields.providerBioEs
        ];
        if (!isLoadingRoles) {
            if (!isProvider) {
                resetFields.forEach(field => {
                    if (field in values) {
                        delete values[field];
                    }
                })
            }
            return isProvider;
        }
    }

    return (
        <Create { ...props}>
            <TabbedForm redirect = "show" toolbar={<PostCreateToolbar/>}>
                <FormTab label="User Details">
                    <ReferenceInput
                      source={userFields.roleId}
                      reference={NON_PROVIDER_ROLES_RESOURCE}
                      validate={required()}
                      label="Role"
                      filter={{ name: USER_ROLE_RESOURCE_MAPPER[props.resource] }}
                      onChange={(event) => {
                          setRoleId(Number(event.target.value));
                      }}
                    >
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                    <TextInput
                      autoFocus
                      label="First name"
                      source={userFields.firstName}
                      formClassName={styles.first_name}
                      validate={required()}
                    />
                    <TextInput
                      label="Last name"
                      source={userFields.lastName}
                      formClassName={styles.last_name}
                      validate={required()}
                    />
                    <TextInput
                      type="email"
                      label="Email"
                      source={userFields.email}
                      validation={{email: true}}
                      fullWidth={true}
                      formClassName={styles.email}
                      validate={[required(), email()]}
                    />
                    <TextInput
                      type="phone"
                      label="Phone"
                      source={userFields.phoneNumber}
                      validate={[isProvider ? required() : optionalField, validatePhone()]}
                      fullWidth={true}
                      formClassName={styles.phone}
                    />

                    {!(isProvider || isAdmin) && (
                      <>
                          <div>
                              <CustomDatePicker
                                source={userFields.dob}
                                label="DOB"
                              />
                          </div>
                          <div>
                              <CustomDatePicker
                                source={userFields.dueDate}
                                label="Due date"
                              />
                          </div>
                      </>
                    )}
                    <ReferenceInput
                      label="Lang"
                      source={userFields.lang}
                      reference={LANGUAGES_RESOURCE}
                      validate={required()}>
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                    <ConditionalField condition={handleConditionalFields}>
                        <ReferenceInput
                          source={userFields.permissionTemplateId}
                          reference={PERMISSION_TEMPLATE_RESOURCE}
                          validate={required()}
                          label="Permissions template"
                          fullWidth
                        >
                            <SelectInput fullWidth/>
                        </ReferenceInput>
                        <ReferenceArrayInput
                          source={userFields.providerRoleIds}
                          reference={PROVIDER_ROLES_RESOURCE}
                          label="Provider Role"
                          allowEmpty
                          fullWidth
                        >
                            <SelectArrayInput optionText={rolesFields.roleName}/>
                        </ReferenceArrayInput>
                        <TextInput
                          rows={5}
                          multiline
                          resettable
                          label="Provider Bio"
                          source={userFields.providerBio}
                          fullWidth
                        />
                        <TextInput
                          rows={5}
                          multiline
                          resettable
                          label="Provider Bio (Spanish)"
                          source={userFields.providerBioEs}
                          fullWidth
                        />
                    </ConditionalField>
                    {!(isProvider || isAdmin) && (
                        <>
                            <ReferenceInput
                                source={userFields.orgId}
                                reference={ORGANIZATIONS_RESOURCE}
                                label="Organization"
                                allowEmpty
                                fullWidth
                            >
                                <SelectInput optionText="name"/>
                            </ReferenceInput>
                            <TextInput
                                type="text"
                                label="WIC ID"
                                source={userFields.wicId}
                                fullWidth={true}
                                formClassName={styles.phone}
                            />
                        </>
                    )}

                    {!isAdmin && (
                      <ReferenceInput
                        source={userFields.usState}
                        reference={US_STATES_RESOURCE}
                        validate={[isPatient ? optionalField : required()]}
                        label="State"
                        fullWidth
                      >
                          <SelectInput optionText="name"/>
                      </ReferenceInput>
                    )}
                    {isProvider && (
                      <TextInput
                        type="text"
                        label="ZIP code"
                        source={userFields.zipCode}
                        fullWidth={true}
                      />
                    )}
                    <TableCell padding="none">
                        <BooleanInput
                          label="Is active"
                          defaultValue={true}
                          source={userFields.isActive}
                        />
                        {!isPatient && (
                          <BooleanInput
                            label="Dashboard MFA required"
                            source={userFields.isMfaRequired}
                          />
                        )}
                    </TableCell>
                </FormTab>
                <FormTab label="User Relative">
                    <ArrayInput source={userFields.relatives}>
                        <SimpleFormIterator>
                            <TextInput
                              label="Name"
                              validate={required()}
                              source={userRelativeFields.name}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
}

export default withStyles(styles) (UserCreate);