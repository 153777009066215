import React from 'react';
import { codesFields, CodeTypeNames} from '../../codes.model';
import { TextField } from 'react-admin';

export default (props) => {
    let { record = {}, source, ...rest } = props;

    let codeTypeName = CodeTypeNames[record[codesFields.codeType]];
    if (!codeTypeName) {
        codeTypeName = "n/a";
    }

    let codeTypeNameKey = 'codeTypeName';
    let recordCopy = {
        ...record,
        [codeTypeNameKey]: codeTypeName,
    };

    return (<TextField source={codeTypeNameKey} record={recordCopy} {...rest} />);
};
