import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class AuthCallbackComponent extends Component {
  render() {
    const styles = {
      position: 'fixed',
      top: '50%',   
      left: '50%'
    };
    console.log("did show authcallback component.")
    return <CircularProgress 
          size={70}
          style={styles}
        />
  }
}

export default AuthCallbackComponent;