import React from 'react';
import { callsFields, CallStatusNames } from '../../calls.model';

export default ({ record = {} }) => {
    let divStyle = {
        display: 'flex',
        verticalAlign: 'bottom'
    };

    let statusName = CallStatusNames[record[callsFields.status]];
    if (!statusName) {return 'n/a'}
    return (<div style={divStyle}>
        <span>{statusName}</span>
    </div>);
};