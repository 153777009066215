import {
  GET_LIST,
  GET_MANY,
  GET_ONE,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
} from "react-admin";
import { orgsFields } from "./orgs.model";
import { stringify } from "query-string";

const API_URL = process.env.REACT_APP_API_URL;

export const RESOURCE_NAME = "organizations";
//used to fetch all records without paging
export const ALL_RESOURCE_NAME = "all.organizations";

export const PUT_MEDICAID = "PUT_MEDICAID";
export const DEACTIVATE_ORGANIZATION = "DEACTIVATE_ORGANIZATION";

const pagedProviderRequestToHttpRequest = (requestType, requestParams) => {
  return providerRequestToHttpRequest(requestType, requestParams, false);
};

const allProviderRequestToHttpRequest = (requestType, requestParams) => {
  console.log("1");
  let result = providerRequestToHttpRequest(requestType, requestParams, true);
  console.log("2");
  return result;
};

const providerRequestToHttpRequest = (requestType, requestParams, all) => {
  switch (requestType) {
    case GET_LIST:
      return composeGetOrgsListRequest(requestParams, all);
    case GET_ONE:
      return {
        url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
      };
    case GET_MANY: {
      console.log(
        "organizations_PROVIDER get many with requestParams => ",
        requestParams
      );
      const query = {
        filter: JSON.stringify({
          id: requestParams.ids,
        }),
      };
      return {
        url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}`,
      };
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = requestParams.pagination;
      const { field, order } = requestParams.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...requestParams.filter,
          [requestParams.target]: requestParams.id,
        }),
      };
      return {
        url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}`,
      };
    }
    case UPDATE:
      return {
        url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
        options: {
          method: "PUT",
          body: JSON.stringify(requestParams.data),
        },
      };
    case CREATE:
      return {
        url: `${API_URL}/${RESOURCE_NAME}`,
        options: {
          method: "POST",
          body: JSON.stringify(requestParams.data),
        },
      };
    case DELETE:
      return {
        url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
        options: {
          method: "DELETE",
        },
      };
    case PUT_MEDICAID:
      console.log("Organizations ", "PUT_MEDICAID");
      console.log("Organizations id: ", requestParams.id);
      console.log(JSON.stringify(requestParams));

      const body =
        requestParams.action === "processUploadedCsv"
          ? {
              action: "processUploadedCsv",
              s3FileKey: requestParams.s3FileKey,
              orgId: requestParams.id,
              dateSent: requestParams.dateSent,
            }
          : requestParams;
      console.log("medicaid body: ", JSON.stringify(body));

      return {
        url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}/marketing`,
        options: {
          method: "PUT",
          body: JSON.stringify(body),
        },
      };
    case DEACTIVATE_ORGANIZATION:
      return {
        url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}/deactivate`,
        options: {
          method: "PUT"
        },
      }

    default:
      throw new Error(`Unsupported fetch action type ${requestType}`);
  }
};

const httpResponseToProviderData = (
  httpResponse,
  requestType,
  requestParams
) => {
  let { headers, json } = httpResponse;
  switch (requestType) {
    case GET_LIST:
      return {
        data: json.map((organizationItem) => organizationItem),
        total: parseInt(headers.get("content-range").split("/").pop()),
      };
    case GET_MANY:
      let organizationIdsToGet = requestParams.ids;
      console.log(
        "organizations_provider handling ids => ",
        organizationIdsToGet
      );
      return {
        data: json.filter((organizationItem) =>
          organizationIdsToGet.includes(organizationItem.id)
        ),
      };
    case CREATE:
      return {
        data: {
          ...requestParams.data,
          id: json.id,
        },
      };
    default:
      return {
        data: json,
      };
  }
};

const composeGetOrgsListRequest = (requestParams, all) => {
  const { page, perPage } = requestParams.pagination;
  const { field, order } = requestParams.sort;
  let prepareFilters = () => {
    let requestFilters = requestParams.filter;
    if (requestFilters === undefined) {
      return {};
    }
    let createContainsFilter = (name, value) => ({
      name,
      comparison: "contains",
      value,
    });

    let createDateBetweenFromFilter = (name, value) => ({
      name,
      comparison: "betweenFrom",
      value,
    });
    let createDateBetweenToFilter = (name, value) => ({
      name,
      comparison: "betweenTo",
      value,
    });

    let resultFilters = [];

    // append "name" filter
    if (requestFilters.hasOwnProperty(orgsFields.orgName)) {
      resultFilters.push(
        createContainsFilter("orgName", requestFilters[orgsFields.orgName])
      );
    }
    // append "created at" filter
    if (requestFilters.hasOwnProperty("createdBetween")) {
      let between = requestFilters["createdBetween"];
      console.log("created at from between: ", between);
      if (between.gte != null) {
        console.log("created at from between.gte: ", between.gte);
        resultFilters.push(
          createDateBetweenFromFilter("createdAtFrom", between.gte)
        );
      }
      if (between.lte != null) {
        console.log("created at to between.lte: ", between.lte);
        resultFilters.push(
          createDateBetweenToFilter("createdAtTo", between.lte)
        );
      }
    }
    // append "updated at" filter
    if (requestFilters.hasOwnProperty("updatedBetween")) {
      let between = requestFilters["updatedBetween"];
      console.log("updated at from endedBetween: ", between);
      if (between.gte != null) {
        console.log("updated at from between.gte: ", between.gte);
        resultFilters.push(
          createDateBetweenFromFilter("updatedAtFrom", between.gte)
        );
      }
      if (between.lte != null) {
        console.log("updated at to between.lte: ", between.lte);
        resultFilters.push(
          createDateBetweenToFilter("updatedAtTo", between.lte)
        );
      }
    }
    return resultFilters;
  };

  const queryParams = {
    all: all,
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([(page - 1) * perPage, page * perPage]),
    filters: JSON.stringify(prepareFilters()),
  };
  return {
    url: `${API_URL}/${RESOURCE_NAME}?${stringify(queryParams)}`,
  };
};

export default {
  resource: RESOURCE_NAME,
  providerInterface: {
    providerRequestToHttpRequest: pagedProviderRequestToHttpRequest,
    httpResponseToProviderData,
  },
};

export const allOrganizationsDataProvider = {
  resource: ALL_RESOURCE_NAME,
  providerInterface: {
    providerRequestToHttpRequest: allProviderRequestToHttpRequest,
    httpResponseToProviderData,
  },
};
