import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@material-ui/core";
import { useDataProvider, useNotify } from 'react-admin';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE, DEACTIVATE_ORGANIZATION } from "../organizations.dataProvider";

const DisableOrganizationDialog = ({ open, close, orgId }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const onDeactivate = () => {
    dataProvider(DEACTIVATE_ORGANIZATION, ORGANIZATIONS_RESOURCE, { id: orgId })
      .then(() => {
        close();
        notify("All organization codes and users deactivated", { type: "success" });
      })
      .catch(error => {
        if (error.message != null) {
          notify(JSON.stringify(error.message), { type: "error" });
        }
        else {
          notify("An error occurred: " + JSON.stringify(error), { type: "error" });
        }
      });
  }

  return <Dialog open={open} onClose={close}>
    <DialogTitle>Deactivate all organization codes and associated users</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Irreversible action! All users associated with organization codes will be unable to initiate calls from the application.
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ justifyContent: "center" }}>
      <Button onClick={onDeactivate} variant="outlined" color="primary">
        Deactivate all organization codes and associated users
      </Button>
    </DialogActions>
  </Dialog>;
};

export default DisableOrganizationDialog;