import React from 'react';
import CodeIcon from '@material-ui/icons/Code';
import { discountCodesFields } from '../../discountCodes.model';

export default ({ record = {} }) => {
    let divStyle = {
        display: 'flex',
        verticalAlign: 'bottom',
    };
    let codeId = record[discountCodesFields.id ] || '';
    return (<div style={divStyle}>
        <CodeIcon />
        <span>&nbsp;{`Discount code # ${codeId}`}</span>
    </div>);
};
