import React from 'react';
import {
    Show, 
    SimpleShowLayout,
    TextField,
    DateField
} from 'react-admin';
import CodeTitle from './fields/codeTitle.component';
import CodeType from './fields/codeType.component';
import { DATE_TIME_OPTIONS } from '../../app/app.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import { codesFields } from '../codes.model';
import OrganizationLinkField from './fields/codeOrganizationLink.component';
import YesNoField from '../../material/yesNoField.component';
import CodeRedeemed from './fields/codeRedeemed.component';
import UserInfoField from './fields/codeUserInfo.component';
import SupportedRoles from './fields/codeSupportedRoles.component';

const CodeShow = ({ ...props }) => {
    window.scrollTo(0, 0);
    return (
        <Show title={<CodeTitle />} {...props}>                
            <SimpleShowLayout>
                <DefaultPlaceholderField source={codesFields.id} wrapField={<TextField/>} label="Id" addLabel  />
                <OrganizationLinkField label="Organization" addLabel/>
                <DefaultPlaceholderField source={codesFields.codeName} wrapField={<TextField/>} label="Code Name" addLabel  />
                <CodeType source={codesFields.codeType} label="Code Type" addLabel  />
                <YesNoField source={codesFields.invalidated} label="Invalidated" addLabel />
                <CodeRedeemed source={codesFields.redeemed} label="Redeemed" addLabel />
                <DefaultPlaceholderField source={codesFields.planName} wrapField={<TextField/>} label="Subscription Plan" addLabel  />
                <DefaultPlaceholderField source={codesFields.createdAt} options={DATE_TIME_OPTIONS} wrapField={<DateField/>} label="Created At" addLabel />
                <SupportedRoles label ="Supported Roles" addLabel />
                <UserInfoField source={codesFields.userId} label="User" addLabel/>
            </SimpleShowLayout>                    
        </Show>
    );
};

export default CodeShow;
