import React from 'react';
import { TextField } from 'react-admin';
import { callsFields } from '../../calls.model';

export default (props) => {
    let { record = {}, ...rest } = props;

    var fullName = 'n/a';
    let firstName = record[callsFields.patientFirstName];
    let lastName = record[callsFields.patientLastName];
    if (firstName || lastName) {
        fullName = `${firstName} ${lastName}`;
    }

    // let firstName = record[callsFields.patientFirstName] || 'n/a';
    // let lastName = record[callsFields.patientLastName] || 'n/a';
    // let fullName = `${firstName} ${lastName}`;

    let fullNameKey = 'fullName';
    let recordCopy = {
        ...record,
        [fullNameKey]: fullName,
    };
    return (<TextField source={fullNameKey} record={recordCopy} style={{ color: 'blue' }} {...rest} />);
};
