import React from 'react';
import {
    List,
    Datagrid,
    EmailField,
    TextField,
    DateField,
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput,
    SelectArrayInput,
    ReferenceArrayInput,
    EditButton,
    ShowButton,
    downloadCSV,
    CreateButton,
    ExportButton,
    RefreshButton
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';

import {
  userFields,
  userOrgCodeFields,
  userGiftFields,
  userRelativeFields,
  USER_ROLE_RESOURCE_MAPPER
} from '../user.model';
import YesNoField from '../../material/yesNoField.component';
import UserFullName from './fields/userFullName.component';
import UserRoleField from './fields/userRoleField.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import { DATE_OPTIONS } from '../../app/app.component';
import { RESOURCE_NAME as NON_PROVIDER_ROLES_RESOURCE } from '../../roles/non.provider.roles.dataProvider';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../roles/provider.roles.dataProvider';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import { rolesFields } from '../../roles/roles.model';
import UserLinkField from './fields/userLink.component';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';
import dateUtils from '../../utils/dateUtils';
import PeriodInput from "../../app/periodInput.component";

const exporter = async users => {

  if (users[0]['url'] != null) { // Large Export Hack 
    console.log('The URL to download from is, ', users[0]['url'])
    await fetch(users[0]['url'])
      .then(response => response.json())
      .then(data => {
        users = data;
      })
      .catch(error => {
        console.error(JSON.stringify(error));
        alert('An error occurred downloading a large export.');
      });
  }

  const usersForExport = users.map(user => {

    let sortedRelatives = user[userFields.relatives].sort(function (a, b) { return dateUtils.momentFromDbDateString(a[userRelativeFields.dob]) - dateUtils.momentFromDbDateString(b[userRelativeFields.dob]) });
    let youngestChildDOB = sortedRelatives && sortedRelatives[sortedRelatives.length - 1] && sortedRelatives[sortedRelatives.length - 1][userRelativeFields.dob];
    console.log("youngest: " + youngestChildDOB);
    let familyMembers = "";
    sortedRelatives.forEach(userRelative => {
      if (userRelative && userRelative[userRelativeFields.dob]) {

        familyMembers = familyMembers + userRelative[userRelativeFields.name] + ", " + dateUtils.dateStringFromDbDateString(userRelative[userRelativeFields.dob]) + ". ";
      }
    });
    console.log("exporter user ::: ", user);
    user['Id'] = user[userFields.id];
    user['Email'] = user[userFields.email];
    user['First name'] = user[userFields.firstName];
    user['Last name'] = user[userFields.lastName];
    user['Phone'] = user[userFields.phoneNumber];
    user['Role'] = user[userFields.roleName];
    user['Dob'] = dateUtils.dateStringFromDbDateString(user[userFields.dob]);
    // If due date is before patient_created_at then it is really baby-DOB, so put no entry for 'Due date', instead putting value in 'Youngest child dob'.
    user['Due date'] = user[userFields.dueDate] != null && moment(user[userFields.dueDate]).isBefore(moment.unix(user[userFields.createdAt]/1000)) ? '' : dateUtils.dateStringFromDbDateString(user[userFields.dueDate]);
    user['Youngest child dob'] = user[userFields.dueDate] != null && moment(user[userFields.dueDate]).isBefore(moment.unix(user[userFields.createdAt]/1000)) ? dateUtils.dateStringFromDbDateString(user[userFields.dueDate]) : dateUtils.dateStringFromDbDateString(youngestChildDOB);
    user['Lang'] = user[userFields.lang];
    user['Recurly active'] = user[userFields.recurlyActive];
    user['Is active'] = user[userFields.isActive];
    user['Organization'] = user[userFields.orgName];
    user['Organization code'] = user[userFields.orgCodes].map((code) => code[userOrgCodeFields.name]).join();
    user['Gift'] = user[userFields.gifts].map((code) => code[userGiftFields.code]).join();
    let userCreatedAt = user[userFields.createdAt];
    if (userCreatedAt) {
      user['Created at date'] = dateUtils.dateStringFromTimestamp(userCreatedAt);
      user['Created at time'] = dateUtils.timeStringFromTimestamp(userCreatedAt);
      user['Days since account creation'] = moment(new Date()).diff(moment(userCreatedAt), 'days');
    }
    user['State'] = user[userFields.usState];
    user['Zip'] = user[userFields.zip];
    user['Race'] = user[userFields.race];
    user['Birth Wish List'] = user[userFields.birthWish];
    user['Birth Partner'] = user[userFields.birthPartner];
    user['Prenatal Visits'] = user[userFields.prenatalVisit];
    user['Infant Feeding Plan'] = user[userFields.feedingPlan];
    user['Push enabled'] = user[userFields.pushEnabled];
    user['Share feedback'] = user[userFields.shareFeedback];
    user['Family members'] = familyMembers;
    user['Is deleted'] = user[userFields.isDeleted];

    return user;
  });

  const csv = convertToCSV({
    data: usersForExport,
    fields: ['Id', 'Email', 'First name', 'Last name', 'Phone', 'Role', 'Dob', 'Due date', 'Youngest child dob',
      'Lang','Recurly active', 'Is active', 'Organization', 'Organization code', 'Gift', 'Created at date', 'Created at time',
      'Days since account creation', 'State', 'Zip', 'Race', 'Birth Wish List', 'Birth Partner', 'Prenatal Visits', 'Infant Feeding Plan', 'Push enabled', 'Share feedback', 'Family members', 'Is deleted']
  });
  
  downloadCSV(csv, 'users-' + moment(new Date()).format('YYYY-MM-DD').toString());
};

const UsersFilter = props => (
    <Filter {...props}>
        <TextInput label="Email" source={userFields.email} allowEmpty style={styles.filter} />
        <TextInput label="First Name" source={userFields.firstName} allowEmpty style={styles.filter} />
        <TextInput label="Last Name" source={userFields.lastName} allowEmpty style={styles.filter} />
        <TextInput label="WIC ID" source={userFields.wicId} allowEmpty style={styles.filter} />
        <ReferenceArrayInput
          label="Role"
          filter={{ name: USER_ROLE_RESOURCE_MAPPER[props.resource] }}
          source={userFields.roleId}
          perPage={10000}
          sort={{ field: "name", order: 'ASC' }}
          reference={NON_PROVIDER_ROLES_RESOURCE}
          style={styles.filter}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source={userFields.providerRoleIds} perPage={10000} sort={{ field: "name", order: 'ASC' }}
            reference={PROVIDER_ROLES_RESOURCE} label="Provider Roles" allowEmpty style={styles.filter}>
                <SelectArrayInput optionText={rolesFields.roleName} />
        </ReferenceArrayInput>
        <ReferenceInput label="Patient Organization" 
            source={userFields.orgId} perPage={10000} sort={{ field: "name", order: 'ASC' }} reference={ORGANIZATIONS_RESOURCE} style={styles.filter}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput label="Recurly Active"
            source={userFields.recurlyActive}
            choices={[
                { id: 1, name: 'Yes' },
                { id: 0, name: 'No' }
            ]}
            style={styles.filter}
        />
        <SelectInput label="Is Active"
            source={userFields.isActive}
            choices={[
                { id: 1, name: 'Yes' },
                { id: 0, name: 'No' }
            ]}
            style={styles.filter}
        />
        <SelectInput label="Is Deleted"
            source={userFields.isDeleted}
            choices={[
                { id: 1, name: 'Yes' },
                { id: 0, name: 'No' }
            ]}
            style={styles.filter}
        />
        <PeriodInput
          source={userFields.createdAt}
          fromLabel="User Created At From"
          toLabel="User Created At To"
          label="User Created At"
        />
    </Filter>
);


const Actions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <Toolbar>
          {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton basePath={basePath} />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
            maxResults={999999999} 
        /> {/* This 999999999 magic number tells us it's an export */}
    </Toolbar>
);

const UserList = props => {
    return (
        <List {...props} title="All users" sort={{ field: 'id', order: 'DESC' }} filters={<UsersFilter/>} exporter={exporter} bulkActionButtons={false} actions={<Actions />} perPage={25}>
            <Datagrid>
                <UserLinkField source={userFields.id} label="Id" addLabel/>
                <DefaultPlaceholderField source={userFields.email} wrapField={<EmailField/>} label="Email" />
                <UserFullName label="Full Name"/>
                <UserRoleField label="Role" />
                <DefaultPlaceholderField source={userFields.orgName} wrapField={<TextField/>} label="Patient Organization" sortable={false} />
                <DefaultPlaceholderField source={userFields.wicId} wrapField={<TextField/>} label="WIC ID" sortable={false} />
                <DefaultPlaceholderField source={userFields.dob} wrapField={<DateField/>} options={DATE_OPTIONS} label="DOB" sortable={false} />
                <DefaultPlaceholderField source={userFields.dueDate} wrapField={<DateField/>} options={DATE_OPTIONS} label="Due Date" sortable={false} />
                <DefaultPlaceholderField source={userFields.lang} wrapField={<TextField/>} label="Lang" />
                <YesNoField source={userFields.recurlyActive} label="Recurly Active" />
                <YesNoField source={userFields.isActive} label="Is Active" />
                <YesNoField source={userFields.isDeleted} label="Is Deleted" />

                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    )
};

const styles = {
  filter: { minWidth:"200px" },
};

export default UserList;
