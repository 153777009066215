import {
  downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment-timezone';
import { callsFields, CallStatusNames, EndCallReasonNames } from '../calls.model';
import dateUtils from '../../utils/dateUtils'

const exporter = async calls => {

  if (calls[0]['url'] != null) { // Large Export Hack 
    console.log('The URL to download from is, ', calls[0]['url'])
    await fetch(calls[0]['url'])
      .then(response => response.json())
      .then(data => {
        calls = data;
      })
      .catch(error => {
        console.error(JSON.stringify(error));
        alert('An error occurred downloading a large export.');
      });
  }

  const callsForExport = calls.map(call => {
    let patientCreatedAt = call[callsFields.patientCreatedAt];
    let patientCreatedAtTime = dateUtils.timeStringFromTimestamp(patientCreatedAt);
    let patientCreatedAtDate = dateUtils.dateStringFromTimestamp(patientCreatedAt);

    let callStartedAt = call[callsFields.callStartedAt];
    let callStartedAtTime = dateUtils.timeStringFromTimestamp(callStartedAt);
    let callStartedAtDate = dateUtils.dateStringFromTimestamp(callStartedAt);

    let callAcceptedAt = call[callsFields.acceptedAt];
    let callAcceptedAtTime = dateUtils.timeStringFromTimestamp(callAcceptedAt);
    let callAcceptedAtDate = dateUtils.dateStringFromTimestamp(callAcceptedAt);

    let callEndedAt = call[callsFields.endedAt];
    let callEndedAtTime = dateUtils.timeStringFromTimestamp(callEndedAt);
    let callEndedAtDate = dateUtils.dateStringFromTimestamp(callEndedAt);

    let callStatusName = CallStatusNames[call[callsFields.status]];

    let patientDobString = call[callsFields.patientDob];
    let patientDob = patientDobString ? dateUtils.dateStringFromDbDateString(patientDobString) : "";
    let patientZip = call[callsFields.patientZip]
    let patientRace = call[callsFields.patientRace]

    let billableTimeString = dateUtils.stringFromTimeDuration(call[callsFields.billableTime], "00:00:00");
    let callLengthString = dateUtils.stringFromTimeDuration(call[callsFields.callLength], "00:00:00");
    let timeToAnswerString = dateUtils.stringFromTimeDuration(call[callsFields.timeToAnswer]);
    let timeToCancelString = dateUtils.stringFromTimeDuration(call[callsFields.timeToCancel]);


    let providerEndReason = EndCallReasonNames[call[callsFields.providerEndReason]];
    let patientEndReason = EndCallReasonNames[call[callsFields.patientEndReason]];


    let providerChartFollowUp = "";
    let providerChartRecommendations = "No chart";
    let providerChartComplaint = "No chart";

    let providerChartFindings = "No chart";
    let providerChartBirthPlan = "No chart";
    let providerChartBirthPartner = "No chart";
    let providerChartPrenatalVisits = "No chart";
    let providerChartInLabor = "No chart";
    let providerChartPostpartumVisits = "No chart";
    let providerChartPediatricianVisits = "No chart";
    let providerChartPhq2 = "No chart";
    let providerChartPhq2Referral = "No chart";

    let phi = call[callsFields.phi];
    if (call[callsFields.nonClinical]) {
      providerChartRecommendations = "No clinical content";
      providerChartComplaint = "No clinical content";
    } else if (phi != null) {
      try {
        let json = JSON.parse(phi);
        let chartType = json.type;
        let formData = json.feedbackFormData;
        if (formData) {
          if (chartType != null && chartType === "doula") {
            providerChartFollowUp = formData.follow_up;
            providerChartRecommendations = formData.recommendations;
            providerChartComplaint = "-";

            providerChartFindings = formData.findings;
            providerChartBirthPlan = formData.birth_plan;
            providerChartBirthPartner = formData.birth_partner;
            providerChartPrenatalVisits = formData.prenatal_visit;
            providerChartInLabor = formData.in_labor === "yes" ? true : false;
          } if (chartType != null && chartType === "postpartum_doula") {
            providerChartFollowUp = formData.follow_up;
            providerChartRecommendations = formData.recommendations;
            providerChartComplaint = "-";

            providerChartFindings = formData.findings;
            providerChartPostpartumVisits = formData.postpartum_visits;
            providerChartPediatricianVisits = formData.pediatrician_visits;
            providerChartPhq2 = formData.phq2;
            providerChartPhq2Referral = formData.phq2_referral === "yes" ? true : false;
          } else {
            providerChartFollowUp = formData.follow_up;
            providerChartRecommendations = formData.recommendations;
            providerChartComplaint = formData.complaint;

            providerChartFindings = "-";
            providerChartBirthPlan = "-";
            providerChartBirthPartner = "-";
            providerChartPrenatalVisits = "-";
            providerChartInLabor = "-";
          }
        }
      } catch (e) {
      }
    }

    let isAnon = call[callsFields.shareFeedback] == false;
    let anon = "anon";

    call['Call id'] = call[callsFields.id];
    call['Patient Id'] = call[callsFields.patientId];
    call['Patient first name'] = isAnon ? anon : call[callsFields.patientFirstName];
    call['Patient last name'] = isAnon ? anon : call[callsFields.patientLastName];
    call['Patient dob'] = isAnon ? anon : patientDob;
    call['Patient phone'] = isAnon ? anon : call[callsFields.patientPhone];
    call['Patient zip'] = isAnon ? anon : patientZip;
    call['Patient race'] = isAnon ? anon : patientRace;
    call['Patient created at date'] = patientCreatedAtDate;
    call['Patient created at time'] = patientCreatedAtTime;
    call['Days since account creation'] = call[callsFields.daysSinceAccountCreation];
    call['Organization'] = call[callsFields.patientOrgName];
    call['Organization code'] = call[callsFields.orgCodeNames] != undefined ? call[callsFields.orgCodeNames].join() : null;
    call['Gift code'] = call[callsFields.giftCodes] != undefined ? call[callsFields.giftCodes].join() : null;
    call["Call type"] = call[callsFields.groupName];
    call['Call started at date'] = callStartedAtDate;
    call['Call started at time'] = callStartedAtTime;
    call['Accepted at date'] = callAcceptedAtDate;
    call['Accepted at time'] = callAcceptedAtTime;
    call['Ended at date'] = callEndedAtDate;
    call['Ended at time'] = callEndedAtTime;
    call['Patient rating'] = call[callsFields.patientRating];
    call['Patient feedback'] = call[callsFields.patientFeedback]; //from patient feedback
    call['Patient call stats'] = call[callsFields.patientCallStats]; //from patient feedback
    call['Provider first name'] = call[callsFields.providerFirstName];
    call['Provider last name'] = call[callsFields.providerLastName];
    call['Provider rating'] = call[callsFields.providerRating];
    call['Provider call stats'] = call[callsFields.providerCallStats]; // from provider feedback
    call['Provider chart: follow up'] = providerChartFollowUp; // from phi
    call['Provider chart: recommendations'] = providerChartRecommendations; //from phi
    call['Provider chart: complaint'] = providerChartComplaint; //from phi
    call['Provider chart: findings'] = providerChartFindings; //from phi, doula
    call['Provider chart: birth wish list'] = providerChartBirthPlan; //from phi, doula
    call['Provider chart: birth partner'] = providerChartBirthPartner; //from phi, doula
    call['Provider chart: prenatal visits'] = providerChartPrenatalVisits; //from phi, doula
    call['Provider chart: IN LABOR'] = providerChartInLabor; //from phi, doula
    call['Provider chart: postpartum visits'] = providerChartPostpartumVisits; //from phi, postpartum doula
    call['Provider chart: pediatrician visits'] = providerChartPediatricianVisits; //from phi, postpartum doula
    call['Provider chart: phq2'] = providerChartPhq2; //from phi, postpartum doula
    call['Provider chart: phq2 referral'] = providerChartPhq2Referral; //from phi, postpartum doula
    call['Share feedback'] = call[callsFields.shareFeedback];
    call['Push enabled'] = call[callsFields.pushEnabled];
    call['Overnight call (23:00 - 7:00 est)'] = call[callsFields.overnightCall]; //pushEnabled
    call['Status'] = callStatusName;
    call['Provider end reason'] = providerEndReason;
    call['Patient end reason'] = patientEndReason;
    call['Call completed'] = call[callsFields.callCompleted];
    call['Call length'] = callLengthString;
    call['Billable time'] = billableTimeString;
    call['Time to answer'] = timeToAnswerString;
    call['Time to cancel'] = timeToCancelString;
    call['Non-clinical'] = call[callsFields.nonClinical];
    call['Demo call'] = call[callsFields.demoCall];
    call['Language'] = call[callsFields.lang];
    call['Service'] = call[callsFields.service];
    call['Intro call'] = call[callsFields.introCall];
    return call;
  });

  const csv = convertToCSV({
    data: callsForExport,
    fields: ['Call id', 'Patient Id', 'Patient first name', 'Patient last name', 'Patient dob', 'Patient phone', 'Patient zip', 'Patient race', 'Patient created at date',
      'Patient created at time', 'Days since account creation', 'Organization', 'Organization code', 'Gift code', 'Call type',
      'Call started at date', 'Call started at time', 'Accepted at date', 'Accepted at time', 'Ended at date', 'Ended at time', 'Patient rating',
      'Patient feedback', 'Patient call stats', 'Provider first name', 'Provider last name', 'Provider rating', 'Provider call stats',
      'Provider chart: follow up', 'Provider chart: recommendations', 'Provider chart: complaint', 'Provider chart: findings',
      'Provider chart: birth wish list', 'Provider chart: birth partner', 'Provider chart: prenatal visits', 'Provider chart: IN LABOR',
      'Share feedback', 'Push enabled', 'Overnight call (23:00 - 7:00 est)', 'Status', 'Provider end reason', 'Patient end reason', 'Call completed',
      'Call length', 'Billable time', 'Time to answer', 'Time to cancel', 'Non-clinical', 'Demo call', 'Language', 'Service', 'Intro call']
  });
  console.log(csv);
  downloadCSV(csv, 'calls-' + moment(new Date()).format('YYYY-MM-DD').toString());
};

export default exporter;