import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    Filter,
    TextInput,
    SelectInput,
    TopToolbar,
    ExportButton,
    SelectField,
    DateInput,
    useListContext,
    Loading,
} from 'react-admin';
import { startOfDay, endOfDay, subDays } from 'date-fns';
import { NOTIFICATION_TEMPLATES_RESOURCE } from '../pregnancyMilestoneNotifications.dataProvider';
import { DELIVERY_STATUS_CHOICES } from '../constants';
import UserLinkField from './fields/userLink.component';

// Get default date range (past 24 hours)
const getDefaultDateRange = () => {
    const end = new Date();
    const start = subDays(end, 1);

    return {
        scheduled_for_gte: startOfDay(start).toISOString(),
        scheduled_for_lte: endOfDay(end).toISOString()
    };
};

const PregnancyMilestoneNotificationDeliveryFilter = props => (
    <Filter {...props}>
        <TextInput 
            label="User Email" 
            source="user_email" 
            alwaysOn 
        />
        <TextInput 
            label="User ID" 
            source="user_id" 
        />
        <TextInput 
            label="Template ID" 
            source="template_id" 
        />
        <SelectInput 
            source="status" 
            choices={DELIVERY_STATUS_CHOICES}
            alwaysOn
        />
        <DateInput 
            source="scheduled_for_gte" 
            label="Scheduled From"
            alwaysOn
        />
        <DateInput 
            source="scheduled_for_lte" 
            label="Scheduled To"
            alwaysOn
        />
    </Filter>
);

const ListActions = ({
    basePath,
    currentSort,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    total
}) => (
    <TopToolbar>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
        />
    </TopToolbar>
);

const DeliveryListContent = () => {
    const { isLoading } = useListContext();
    
    if (isLoading) {
        return <Loading loadingPrimary="Loading deliveries..." loadingSecondary="Please wait while we fetch the data" />;
    }

    return (
        <Datagrid>
            <TextField source="id" />
            <DateField source="scheduled_for" showTime />
            <UserLinkField label="User" addLabel/>
            <SelectField 
                source="status" 
                choices={DELIVERY_STATUS_CHOICES}
            />
            <TextField source="template_id" label="Template ID" />
            <ReferenceField 
                source="template_id" 
                reference="notification-templates"
                link={false}
                label="Template"
            >
                <TextField source="title" emptyText="-" />
            </ReferenceField>
            <DateField source="sent_to_braze_at" showTime />
            <TextField source="error_message" />
            <TextField source="braze_event_id" />
            <TextField source="segment_message_id" />
            <DateField source="created_at" showTime />
            <DateField source="updated_at" showTime />
        </Datagrid>
    );
};

export const PregnancyMilestoneNotificationDeliveryList = props => {
    const { options } = props;
    const pageTitle = options?.pageLabel || options?.label || 'Notification Deliveries';
    
    return (
        <List 
            {...props} 
            title={pageTitle}
            filters={<PregnancyMilestoneNotificationDeliveryFilter />}
            filterDefaultValues={getDefaultDateRange()}
            actions={<ListActions />}
            bulkActionButtons={false}
            sort={{ field: 'scheduled_for', order: 'DESC' }}
        >
            <DeliveryListContent />
        </List>
    );
}; 