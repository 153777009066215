import React from 'react';
import { callsFields } from '../../calls.model';
import { Link } from 'react-admin';
import { RESOURCE_NAME as CALLS_RESOURCE } from '../../calls.dataProvider';

export default (props) => {
  let divStyle = {
      display: 'flex',
      verticalAlign: 'bottom'
  };
  var phi = JSON.parse(props.record.phi);
  if (props.record[callsFields.nonClinical]) {
    return (
      <div style={divStyle}>
        <span>No clinical content</span>
      </div>
    );
  }
  else if (!phi || !phi.feedbackFormData || (phi.feedbackFormData.recommendations === "" && phi.feedbackFormData.complaint === "" && phi.feedbackFormData.follow_up === "")) {
    return (
      <div style={divStyle}>
        <span>No chart</span>
      </div>
    )
  }
  else {
    //var phi = JSON.parse(props.record.phi);
    return (
      <Link to={`${CALLS_RESOURCE}/${props.record.id}/show`}>
        <span>Show chart</span>
      </Link>
    );
  }
};