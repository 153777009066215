import React from 'react';
import { versionsFields } from '../../versions.model';

export default ({ record = {} }) => {
    let divStyle = {
        display: 'flex',
        verticalAlign: 'bottom',
        fontSize: 14,
    };
    let platform;
    switch (record[versionsFields.platform]) {
        case 1:
            platform = "iOS";
            break;
        case 2:
            platform = "Android";
            break;     
        default:
            platform = "Not Defined";
            break;
      }

    return (<div style={divStyle}>
        <span>{platform}</span>
    </div>);
};
