import React from 'react';
import {Chip, withStyles} from '@material-ui/core';
import classNames from 'classnames';

const yesChipStyles = {
    root: {
        // backgroundColor: '#388E3C',
        height: '25px',
    },
    label: {
        textDecoration: 'line-through',
    },
};
const noChipStyles = {
    root: {
        // backgroundColor: 'gray',
        height: '25px',
    },
    label: {
        textDecoration: 'line-through',
    },
};
const YesNoChip = (props) => {
    const { isActive, classes, className, ...other } = props;
    if (isActive) {
        return <Chip label="Yes" color="primary" 
            className={classNames(classes.root, className)} {...other} />;
    } else {
        return <Chip label="No" color="default" 
            className={classNames(classes.root, className)} {...other} />;
    }
};

export default (props) => {
    // If isActive==true then "Yes Chip" will be selected; "No Chip" otherwise
    const { isActive } = props;
    let ChipWithStyles = withStyles(isActive ? yesChipStyles : noChipStyles)(YesNoChip);
    // const MergedPropsComponent = passThroughPropsHoc({ isActive })(ChipWithStyles);
    // return <MergedPropsComponent />;
    return <ChipWithStyles isActive={isActive} />;
}
