import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    DateField,
    Filter,
    TextInput
} from 'react-admin';
import { orgsFields } from '../orgs.model';
import { DATE_TIME_OPTIONS } from '../../app/app.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import OrganizationLinkField from './fields/organizationLink.component';
import PeriodInput from "../../app/periodInput.component";
import { makeStyles } from '@material-ui/core/styles';
import YesNoField from '../../material/yesNoField.component';

const styles = makeStyles({
    singleLineWithEllipsis: { 
        maxWidth: '5em', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap' 
    }
});


const OrgsFilter = props => (
    <Filter {...props}>
        <TextInput label="Name" source={orgsFields.orgName} perPage={10000} sort={{ field: "name", order: 'ASC' }} allowEmpty />
        <PeriodInput
            source="createdBetween"
            fromLabel="Created At From"
            toLabel="Created At To"
            label="Created At"
        />
        <PeriodInput
            source="updatedBetween"
            fromLabel="Updated At From"
            toLabel="Updated At To"
            label="Updated At"
        />
    </Filter>
);


const OrgsList = props => {
    const classes = styles();
    return (
        <List {...props} 
        title="Organizations" 
        sort={{ field: 'id', order: 'DESC' }} 
        filters={<OrgsFilter />} 
        bulkActionButtons={false}
        exporter={false}	>
            <Datagrid>
                <OrganizationLinkField label="Id" source={orgsFields.id} addLabel/>
                <DefaultPlaceholderField source={orgsFields.clientGroupId} wrapField={<TextField/>} label="Healthie Client Group ID" />
                <DefaultPlaceholderField source={orgsFields.orgName} wrapField={<TextField/>}  label="Name" />
                <DefaultPlaceholderField source={orgsFields.orgCreatedAt} wrapField={<DateField/>} options={DATE_TIME_OPTIONS}  label="Created At" />
                <DefaultPlaceholderField source={orgsFields.orgUpdatedAt} wrapField={<DateField/>} options={DATE_TIME_OPTIONS}  label="Updated At" />
                <DefaultPlaceholderField source={orgsFields.orgNursePhone} wrapField={<TextField/>}  label="Nurse Phone" />
                <DefaultPlaceholderField source={orgsFields.payerId} wrapField={<TextField/>}  label="Payer ID" />
                <DefaultPlaceholderField source={orgsFields.orgSdkApiKey} wrapField={<TextField/>}  label="Sdk Api Key" cellClassName={classes.singleLineWithEllipsis} />
                <DefaultPlaceholderField source={orgsFields.orgSdkAuthenticationEndpoint} wrapField={<TextField/>}  label="Sdk Authentication Endpoint" cellClassName={classes.singleLineWithEllipsis} />
                <DefaultPlaceholderField source={orgsFields.orgSecretKey} wrapField={<TextField/>}  label="Secret Key" cellClassName={classes.singleLineWithEllipsis} />
                <YesNoField source={orgsFields.contentLibraryEnabled} label="Content Library Enabled" />
                <YesNoField source={orgsFields.enrollmentCodeRequired} label="Enrollment Code Required" />
                <YesNoField source={orgsFields.requiresWicId} label=" WIC ID Required" />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    )
};

export default OrgsList;
