import React from 'react';
import { Link, TextField, ArrayField, SingleFieldList } from 'react-admin';
import { RESOURCE_NAME as CODES_RESOURCE } from '../../../codes/codes.dataProvider';
import { userOrgCodeFields } from '../../user.model';
import { TableRow, TableCell } from '@material-ui/core';


const OrganizationCodeLinkField = props => {
    if (!props.record[userOrgCodeFields.name]) {return 'n/a'}
    return (
    <Link to={`/${CODES_RESOURCE}/${props.record[userOrgCodeFields.id]}/show`} >
        <TextField source={userOrgCodeFields.name} style={{ color: 'blue' }} {...props}/>
    </Link>)
};

OrganizationCodeLinkField.defaultProps = {
    addLabel: true
};

export const OrganizationCodesTableRow = ({ source, label, record = {} }) => {
    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            <TableCell>
                <ArrayField source={source} record={record}>                        
                    <SingleFieldList linkType={true}>
                        <OrganizationCodeLinkField/>                                
                    </SingleFieldList>
                </ArrayField>
            </TableCell>
        </TableRow>
    );
};

export default OrganizationCodeLinkField;