import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
} from 'react-admin';
import {
    stringify
} from 'query-string';
import {
    userFields
} from "./user.model";

const API_URL = process.env.REACT_APP_API_URL;

const POST_NOTIF = "POST_NOTIF"
const POST_CHANGE_PASSWORD = "POST_CHANGE_PASSWORD"

export const RESOURCE_NAME = "users";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    switch (requestType) {
        case POST_NOTIF:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/notify`,
                options: {
                    method: 'POST',
                    body: JSON.stringify(requestParams.data)
                },
            };
        case POST_CHANGE_PASSWORD:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/changePassword`,
                options: {
                    method: 'POST',
                    body: JSON.stringify({
                        userId: requestParams.data.userId,
                        password: requestParams.data.password                        
                    })
                },
            };            
        case GET_LIST:
            return composeGetUsersListRequest(requestParams);
        case GET_ONE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`
            };
        case GET_MANY:
            {
                const query = {
                    filter: JSON.stringify({
                        id: requestParams.ids
                    }),
                };
                return {
                    url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}`
                };
            }
        case GET_MANY_REFERENCE:
            {
                const {
                    page,
                    perPage
                } = requestParams.pagination;
                const {
                    field,
                    order
                } = requestParams.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                    filter: JSON.stringify({
                        ...requestParams.filter,
                        [requestParams.target]: requestParams.id
                    }),
                };
                return {
                    url: `${API_URL}/${RESOURCE_NAME}?${stringify(query)}`
                };
            }
        case UPDATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: {
                    method: 'PUT',
                    body: JSON.stringify(requestParams.data)
                },
            };
        case CREATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}`,
                options: {
                    method: 'POST',
                    body: JSON.stringify(requestParams.data)
                },
            };
        case DELETE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: {
                    method: 'DELETE',
                    // body: JSON.stringify(requestParams.previousData)
                },
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const composeGetUsersListRequest = (requestParams) => {
    const {
        page,
        perPage
    } = requestParams.pagination;
    const {
        field,
        order
    } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }
        let createEqFilter = (name, value) => ({
            name,
            comparison: "eq",
            value
        });
        let createInFilter = (name, value) => ({
            name,
            comparison: "in",
            value
        });
        let createContainsFilter = (name, value) => ({
            name,
            comparison: "contains",
            value
        });
        let createDateBetweenFromFilter = (name, value) => ({ name, comparison: "betweenFrom", value });
        let createDateBetweenToFilter = (name, value) => ({ name, comparison: "betweenTo", value });

        let resultFilters = [];
        // append "email" filter
        if (requestFilters.hasOwnProperty(userFields.email)) {
            resultFilters.push(createContainsFilter('email', requestFilters[userFields.email]));
        }
        // append "firstName" filter
        if (requestFilters.hasOwnProperty(userFields.firstName)) {
            resultFilters.push(createContainsFilter('firstName', requestFilters[userFields.firstName]));
        }
        // append "lastName" filter
        if (requestFilters.hasOwnProperty(userFields.lastName)) {
            resultFilters.push(createContainsFilter('lastName', requestFilters[userFields.lastName]));
        }
        // append "roleName" filter
        if (requestFilters.hasOwnProperty(userFields.roleName)) {
            resultFilters.push(createContainsFilter('roleName', requestFilters[userFields.roleName]));
        }
        // append "roleId" filter
        if (requestFilters.hasOwnProperty(userFields.roleId)) {
            resultFilters.push(createInFilter('roleId', requestFilters[userFields.roleId]));
        }
        // append "orgId" filter
        if (requestFilters.hasOwnProperty(userFields.orgId)) {
            resultFilters.push(createEqFilter('orgId', requestFilters[userFields.orgId]));
        }
        // append "isActive" filter
        if (requestFilters.hasOwnProperty(userFields.isActive)) {
            resultFilters.push(createEqFilter('isActive', requestFilters[userFields.isActive]));
        }

        // append "isDeleted" filter
        if (requestFilters.hasOwnProperty(userFields.isDeleted)) {
            resultFilters.push(createEqFilter('isDeleted', requestFilters[userFields.isDeleted]));
        }
        // append "recurlyActive" filter
        if (requestFilters.hasOwnProperty(userFields.recurlyActive)) {
            resultFilters.push(createEqFilter('recurlyActive', requestFilters[userFields.recurlyActive]));
        }
        // append "providerRoleIds" filter
        if (requestFilters.hasOwnProperty(userFields.providerRoleIds)) {
            resultFilters.push(createInFilter('providerRoleIds', requestFilters[userFields.providerRoleIds]));
        }
        // append "created at" filter
        if (requestFilters.hasOwnProperty(userFields.createdAt)) {
          let between = requestFilters[userFields.createdAt];
          if (between.gte != null) {
              resultFilters.push(createDateBetweenFromFilter('createdAtFrom', between.gte));
          }
          if (between.lte != null) {
              resultFilters.push(createDateBetweenToFilter('createdAtTo', between.lte));
          }
      }
        
        return resultFilters;
    };

    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage]),
        filters: JSON.stringify(prepareFilters()),
        export: perPage === 999999999 ? true : false
    };
    return { 
        url: `${API_URL}/${RESOURCE_NAME}-get`,
        options: { method: 'POST', body: JSON.stringify(queryParams) }
    };
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    var {
        headers,
        json
    } = httpResponse;
    switch (requestType) {
        case GET_LIST:
            if (json['url'] != null) { // Large Export Hack -- a url is returned to a presigned s3 file for download, handled downstream.
              return { data: [{ id: 9999999, url: json['url']}], total: 9999999 }; // Have to use this format as part of the hack to slip through react-admin validation
            }
            return {
                data: json.map(x => x),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        case CREATE:
            return {
                data: {
                    ...requestParams.data,
                    id: json.id
                }
            };
        default:
            return {
                data: json
            };
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};