import React from 'react';
import {
    Show, 
    SimpleShowLayout,
    TextField
} from 'react-admin';
import VersionTitle from './fields/versionTitle.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import YesNoField from '../../material/yesNoField.component';
import { versionsFields } from '../versions.model';
import VersionPlatform from './fields/versionPlatform.component';
import VersionAppType from './fields/versionAppType.component';


const VersionShow = (props) => {
    window.scrollTo(0, 0);
    return (
        <Show title={<VersionTitle style={{verticalAlign: 'middle'}}/>} {...props}>
            <SimpleShowLayout>
                <DefaultPlaceholderField source={versionsFields.id} wrapField={<TextField/>} label="Id" addLabel  />
                <VersionPlatform wrapField={<TextField/>} label="Platform" addLabel  />
                <VersionAppType wrapField={<TextField/>} label="App type" addLabel  />
                <DefaultPlaceholderField source={versionsFields.version} wrapField={<TextField/>} label="Version" addLabel  />
                <YesNoField source={versionsFields.forceUpdate} wrapField={<TextField/>} label="Force Update" addLabel  />
                <DefaultPlaceholderField source={versionsFields.build} wrapField={<TextField/>} label="Build" addLabel />
                <DefaultPlaceholderField source={versionsFields.releaseNotes} wrapField={<TextField/>} label="Release Notes" addLabel />
            </SimpleShowLayout>
        </Show>
    );
};

export default VersionShow;
