import React from 'react';
import { Route } from 'react-router-dom';
import UserNotify from '../users/component/userNotify.component';
import ChangePassword from '../users/component/changePassword.component';
import PinpointEdit from '../pinpoint/component/pinpointEdit.component';
import EditCallChart from '../calls/component/editChart.component';
import EditChatChart from '../chats/component/editChart.component';

export default [
  <Route exact path="/users/:id/notify" component={UserNotify} />,
  <Route exact path="/users/:id/changePassword" component={ChangePassword} />,
  <Route exact path="/pinpoint" component={PinpointEdit} />,
  <Route exact path="/calls/:id/editchart" component={EditCallChart} />,
  <Route exact path="/chats/:id/editchart" component={EditChatChart} />
];